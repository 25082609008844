import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import SvgIcon from "@mui/material/SvgIcon";
import { logout } from "services/auth";
import PaidIcon from "@mui/icons-material/Paid";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { ReactComponent as Overview } from "assets/Images/Overview.svg";
import { ReactComponent as People } from "assets/Images/people.svg";
import { ReactComponent as HoldMoney } from "assets/Images/HoldMoney.svg";
import { ReactComponent as Accounts } from "assets/Images/financing.svg";
import { ReactComponent as Transactions } from "assets/Images/notebook.svg";
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import Groups2Icon from "@mui/icons-material/Groups2";
import { ReactComponent as SettingsIcon } from "assets/Images/Setting.svg";
// import { ReactComponent as Payments } from 'assets/Images/Payments.svg'
// import { ReactComponent as Transaction } from 'assets/Images/Transaction.svg'
import { ReactComponent as Money } from "assets/Images/money.svg";
import { ReactComponent as CompareArrows } from "assets/Images/compareArrows.svg";
import { AccountBalance } from "@mui/icons-material";
import { type NavigationItem } from "interfaces/Route";
import { FlunaModules } from "API";

export const SettingsNav = [
  {
    name: "Log Out",
    action: logout,
  },
];

export const SideNavigation: NavigationItem[] = [
  {
    name: "Overview",
    navigation: "/overview",
    element: <SvgIcon component={Overview} inheritViewBox color="inherit" />,
    module: FlunaModules.overview,
  },
  {
    name: "Risk",
    navigation: "/",
    subNavigation: ["/company"],
    element: <SvgIcon component={Groups2Icon} inheritViewBox color="inherit" />,
    module: FlunaModules.kyc,
  },
  {
    name: "Payments",
    navigation: "/mc-accounts",
    element: <SvgIcon component={AccountBalance} inheritViewBox color="inherit" />,
    module: FlunaModules.mca,
    children: [
      {
        name: "Accounts",
        navigation: "/mc-accounts",
        element: <SvgIcon component={Accounts} inheritViewBox color="inherit" />,
      },
      {
        name: "Transactions",
        navigation: "/transactions",
        element: <SvgIcon component={Transactions} inheritViewBox color="inherit" />,
      },
    ],
  },

  {
    name: "Credit",
    navigation: "/loan-management",
    element: <SvgIcon component={HoldMoney} inheritViewBox color="inherit" />,
    children: [
      {
        name: "Application Management",
        navigation: "/loan-management",
        element: <SvgIcon component={Overview} inheritViewBox color="inherit" />,
      },
      {
        name: "Payment Tracker",
        navigation: "/loan-payments",
        element: <SvgIcon component={PaidIcon} inheritViewBox color="inherit" />,
      },
    ],
    module: FlunaModules.lending,
  },
  {
    name: "Capital",
    navigation: "/investors",
    element: <SvgIcon component={AccountBalance} inheritViewBox color="inherit" />,
    children: [
      {
        name: "Investors",
        navigation: "/investors",
        element: <SvgIcon component={People} inheritViewBox color="inherit" />,
      },
      {
        name: "Investments",
        navigation: "/investments",
        element: <SvgIcon component={Money} inheritViewBox color="inherit" />,
      },
      {
        name: "Payments",
        navigation: "/ip-payments",
        element: <SvgIcon component={CompareArrows} inheritViewBox color="inherit" />,
      },
    ],
    module: FlunaModules.investorPortal,
  },
  {
    name: "Atlas",
    navigation: "/atlas",
    element: <SvgIcon component={AccountBalance} inheritViewBox color="inherit" />,
    children: [
      {
        name: "Atlas",
        navigation: "/atlas",
        element: <SvgIcon component={AccountBalance} inheritViewBox color="inherit" />,
      },
    ],
    module: FlunaModules.atlas,
  },
  {
    name: "Trade",
    navigation: "/campaign-builder",
    element: <SvgIcon component={AccountBalance} inheritViewBox color="inherit" />,
    children: [
      {
        name: "Campaign Builder",
        navigation: "/campaign-builder",
        element: <SvgIcon component={PaidIcon} inheritViewBox color="inherit" />,
      },
    ],
    module: FlunaModules.trade,
  },
  {
    name: "Utils",
    navigation: "/statements",
    element: <SvgIcon component={ReceiptLongIcon} inheritViewBox color="inherit" />,
    children: [
      {
        name: "Statement Parser",
        navigation: "/statements",
        element: <SvgIcon component={Overview} inheritViewBox color="inherit" />,
      },
      {
        name: "Entities",
        navigation: "/entity",
        element: <SvgIcon component={People} inheritViewBox color="inherit" />,
      },
    ],
    module: FlunaModules.statementParser,
  },
];

export const SettingsSidebarNav = [
  {
    name: "Personal",
    navigation: "/settings/",
    element: <SvgIcon component={PersonIcon} inheritViewBox color="inherit" />,
  },
];

export const BottomNav: NavigationItem[] = [
  {
    name: "Permissions",
    navigation: "/permissions",
    element: <SvgIcon component={SettingsIcon} inheritViewBox color="inherit" />,
    secondarySidebar: true,
    module: FlunaModules.permissions,
  },
];
