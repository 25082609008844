import React from "react";
import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { MTableCell } from "davetech-material-table";
import { type Column } from "interfaces/Table";
import { DocCertification, EntityTypes, type IEntity } from "interfaces/atlas";
import { ChevronRight, ContentCopyOutlined } from "@mui/icons-material";
import countryDailCode from "data/countryDailCode";
import flunaIcon from "assets/stats-1.svg";
import pastInterIcon from "assets/stats-2.svg";
import exportedIcon from "assets/stats-3.svg";
import Input, { inputProps } from "components/Input";
import SelectInput, { SingleSelectProps } from "components/SelectInput";

export const tableStyles = {
  fontWeight: 400,
  fontSize: "0.875rem",
  border: "1px solid #E9EAEF",
  padding: "18px 0 0 0px",
  borderRadius: "8px",
  color: "#8083A3",
};

export const columns: Array<Column<IEntity>> = [
  {
    title: "COMPANY NAME",
    field: "name",
    width: "23%",
  },
  // {
  //   title: "Created At",
  //   field: "createdOn",
  //   width: "25%",
  // },
  {
    title: "COUNTRY OF OPERATION",
    field: "country",
    width: "25%",
  },
  {
    title: "ENTITY TYPE",
    field: "entityType",
    width: "25%",
  },
  {
    title: "PRODUCT(s) EXPORTED",
    field: "supplierProductExportExperience",
    width: "25%",
  },
  {
    title: "LABELS",
    field: "labels",
    width: "25%",
  },
  {
    title: "CERTIFICATIONS",
    field: "certifications",
    width: "25%",
  },
  // {
  //   title: "Company Rating",
  //   field: "",
  //   width: "25%",
  // },
];

export const CountryPill = ({ id, country }: { id: string; country: any }) => {
  return (
    <PillBox
      key={id}
      text={
        <>
          {country?.isoAlpha2 ? (
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.7rem",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: ".9rem",
                  height: ".9rem",
                }}
                src={`/static/countrysvgs/${country?.isoAlpha2 as string}.svg`}
              />
              <Typography>{country?.isoAlpha2}</Typography>
            </Box>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export const DocumentPill = <
  Document extends {
    name: string;
    value: string;
  },
>({
  document,
  handleClick,
  isCurrent = false,
}: {
  document: Document;
  isCurrent: boolean;
  handleClick: (document: Document) => void;
}) => {
  const { name, value } = document;

  return (
    <Box
      key={value}
      onClick={() => {
        handleClick(document);
      }}
      sx={{
        padding: "1rem",
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E5E5E5",
        backgroundColor: isCurrent ? "#FEF7F1" : "transparent",
      }}
    >
      <Typography
        sx={{
          color: "#8083A3",
          fontWeight: 500,
          fontFamily: "SF Pro Display Medium",
        }}
      >
        {name}
      </Typography>
      <ChevronRight
        sx={{
          color: "#8083A3",
        }}
      />
    </Box>
  );
};

export const AtlasInput = ({ textAlign = "right", ...rest }: inputProps & { textAlign?: string }) => {
  return (
    <Input
      {...rest}
      sx={{
        width: "100%",

        "& .MuiFilledInput-root": {
          border: "1px solid rgba(230, 122, 14, 0.5)",
        },

        "& input": {
          paddingTop: "8px",
          display: "flex",
          textAlign: textAlign,
        },
        ...rest.sx,
      }}
    />
  );
};

export const AtlasSelect = ({ ...props }: SingleSelectProps & { textAlign?: string }) => {
  const textAlign = props.textAlign || "left";

  return (
    <SelectInput
      {...props}
      sx={{
        "&.MuiFormControl-root": {
          marginBottom: "0rem",
        },
        "& .MuiSelect-select, & .MuiInputBase-input, & .MuiInputBase-input:focus": {
          padding: "8px 8px !important",
        },
        "& .MuiSelect-select .MuiListItemText-primary": {
          paddingRight: textAlign === "right" ? "30px" : "0px",
          textAlign,
        },
        "& .MuiSelect-select": {
          border: "1px solid rgba(230, 122, 14, 0.5) !important",
        },
      }}
    />
  );
};

export const Row = ({
  showBorder,
  sx,
  sxLeft,
  sxRight,
  LeftContent,
  RightContent,
  disabled = false,
  alignStart = false,
}: {
  disabled?: boolean;
  showBorder: boolean;
  alignStart?: boolean;
  sx?: SxProps;
  sxRight?: SxProps;
  sxLeft?: SxProps;
  RightContent: string | React.ReactNode | JSX.Element;
  LeftContent: string | React.ReactNode | JSX.Element;
}) => {
  return (
    <Box
      sx={{
        gap: 2,
        display: "flex",
        alignItems: alignStart ? "start" : "center",
        justifyContent: "space-between",
        padding: "0.59375rem 0rem",
        fontSize: "1rem",
        lineHeight: "1.193125rem",
        fontFamily: "SF Pro Display Medium",
        color: "rgba(135, 138, 168, 1)",
        fontWeight: 600,
        borderBottom: showBorder ? "1px solid rgba(135, 138, 168, 0.1)" : "none",
        ...sx,
      }}
    >
      {typeof LeftContent === "string" ? (
        <Typography
          sx={{
            fontWeight: 500,
            textWrap: "nowrap",
            color: disabled ? "rgba(135, 138, 168, 1)" : "inherit",
            opacity: disabled ? 0.5 : 1,
            ...sxLeft,
          }}
        >
          {LeftContent}
        </Typography>
      ) : (
        LeftContent
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifySelf: "end",
          justifyContent: "flex-end",
          ...sxRight,
        }}
      >
        {typeof RightContent === "string" ? (
          <Typography
            sx={{
              textAlign: "right",
              fontWeight: "inherit",
              color: disabled ? "rgba(135, 138, 168, 1)" : "inherit",
              opacity: disabled ? 0.5 : 1,
            }}
          >
            {RightContent}
          </Typography>
        ) : (
          RightContent
        )}
      </Box>
    </Box>
  );
};

export const PillBox = ({ text, type, sx }: { sx?: SxProps; text: string | React.ReactNode; type?: "uppercase" | "lowercase" | "capitalize" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        borderRadius: "2.5625rem",
        padding: ".5rem 1rem",
        color: "rgba(230, 122, 14, 1)",
        backgroundColor: "rgba(230, 122, 14, 0.15)",
        fontFamily: "SF Pro Display Medium",
        textTransform: type === "uppercase" ? "uppercase" : type === "lowercase" ? "lowercase" : type === "capitalize" ? "capitalize" : "none",
        ...sx,
      }}
    >
      {text}
    </Box>
  );
};

const Pill = ({ text, type }: { text: string | React.ReactNode; type?: "uppercase" | "lowercase" | "capitalize" }) => {
  return (
    <Box
      sx={{
        borderRadius: "2.5625rem",
        padding: ".1rem .7rem",
        color: "rgba(230, 122, 14, 1)",
        backgroundColor: "rgba(230, 122, 14, 0.15)",
        fontFamily: "SF Pro Display Medium",
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "1.555625rem",
        textTransform: type === "uppercase" ? "uppercase" : type === "lowercase" ? "lowercase" : type === "capitalize" ? "capitalize" : "none",
      }}
    >
      {text}
    </Box>
  );
};

export const cases = (copyToClipboard: (id: string) => void) => ({
  name: (props: any, field: any, rowData: IEntity) => {
    const { name } = rowData;

    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.9rem",
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <ContentCopyOutlined
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(name);
              }}
              sx={{
                marginLeft: "6px",
                cursor: "pointer",
                fontSize: "medium",
                color: "rgba(128, 131, 163, 0.7)",
              }}
            />
            <Tooltip title={name}>
              <Typography sx={{ fontSize: "0.875rem" }}>{name ?? ""}</Typography>
            </Tooltip>
          </Box>
        }
      />
    );
  },
  supplierProductExportExperience: (props: any, field: any, rowData: IEntity) => {
    const toSlice = [...(rowData?.supplierProductExportExperience || [])];

    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.7rem",
            }}
          >
            {toSlice.slice(0, 5).map(({ name }) => (
              <Pill key={name} text={name} type="capitalize" />
            ))}
            {toSlice.length > 5 && <Box component="span">and {toSlice.length - 5} more</Box>}
          </Box>
        }
      />
    );
  },
  entityType: (props: any, field: any, rowData: IEntity) => {
    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.7rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.875rem",
              }}
            >
              {EntityTypes[rowData.entityType]}
            </Typography>
          </Box>
        }
      />
    );
  },
  labels: (props: any, field: any, rowData: IEntity) => {
    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.9rem",
            }}
          >
            {rowData?.isFlunaUser && (
              <Pill
                text={
                  <Box
                    sx={{
                      gap: ".5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box component="img" src={flunaIcon} alt="flunaIcon" />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "0.875rem",
                        fontFamily: "SF Pro Display Medium",
                      }}
                    >
                      Fluna Client
                    </Box>
                  </Box>
                }
              />
            )}
            {rowData?.pastInteraction && (
              <Pill
                text={
                  <Box
                    sx={{
                      gap: ".5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box component="img" src={pastInterIcon} alt="pastInterIcon" />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "0.875rem",
                        fontFamily: "SF Pro Display Medium",
                      }}
                    >
                      Past Interaction
                    </Box>
                  </Box>
                }
              />
            )}
            {((rowData?.supplierProductExportExperience || []).length > 0 || rowData.exportedBefore) && rowData.entityType === "Supplier" && (
              <Pill
                text={
                  <Box
                    sx={{
                      gap: ".5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box component="img" src={exportedIcon} alt="exportedIcon" />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "0.875rem",
                        fontFamily: "SF Pro Display Medium",
                      }}
                    >
                      Prior Exports
                    </Box>
                  </Box>
                }
              />
            )}
            {((rowData?.supplierProductExportExperience || []).length > 0 || rowData.exportedBefore) && rowData.entityType === "Buyer" && (
              <Pill
                text={
                  <Box
                    sx={{
                      gap: ".5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box component="img" src={exportedIcon} alt="exportedIcon" />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "0.875rem",
                        fontFamily: "SF Pro Display Medium",
                      }}
                    >
                      Prior Imports
                    </Box>
                  </Box>
                }
              />
            )}
          </Box>
        }
      />
    );
  },
  certifications: (props: any, field: any, rowData: IEntity) => {
    const permittedDocs = Object.values(DocCertification);

    const docsToShow: string[] = [];

    Object.values(rowData?.documents || []).forEach((doc) => {
      const docInConsideration = doc.documentTemplate?.name || "";
      !docsToShow.includes(docInConsideration) && permittedDocs.includes(docInConsideration) && docsToShow.push(docInConsideration);
    });

    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.7rem",
            }}
          >
            {docsToShow.map((certification) => (
              <Pill key={certification} text={certification} type="uppercase" />
            ))}
          </Box>
        }
      />
    );
  },
  country: (props: any, field: any, rowData: IEntity) => {
    const countrySplit = (rowData?.country || "").split(",");
    const countryInfos = countrySplit.map((country) => countryDailCode[country]?.isoAlpha2);

    return (
      <MTableCell
        {...props}
        value={
          <Box
            sx={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
            }}
          >
            {countryInfos.map((country) => {
              return (
                <>
                  {country ? (
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.7rem",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          width: "1rem",
                          height: "1rem",
                        }}
                        src={`/static/countrysvgs/${country as string}.svg`}
                      />
                      <Typography>{country}</Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </Box>
        }
      />
    );
  },
  createdOn: (props: any, field: any, rowData: IEntity) => {
    const { createdOn } = rowData;
    const formattedDate = createdOn ? format(new Date(createdOn), "MMM. d, yyyy") : "";
    // | h:mm a

    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.9rem",
            }}
          >
            <Typography>{formattedDate}</Typography>
          </Box>
        }
      />
    );
  },
});

export const currencies = [
  {
    name: "USD",
    value: "USD",
  },
  {
    name: "NGN",
    value: "NGN",
  },
];

export const units = [
  {
    name: "KG",
    value: "KG",
  },
];
