/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
import React, { type ReactElement } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { InputBase, type SxProps, Typography } from "@mui/material";

interface Props {
  onChange: (field: string, value: string) => void;
  setFieldTouched?: (field: string, value: boolean) => void;
  value: string;
  options: Array<{
    value: string;
    name: string;
    display?: string | ReactElement;
  }>;
  placeholder?: string;
  title: string | React.ReactElement;
  dataTestid?: string;
  name: string;
  className?: string;
  error: Record<string, string>;
  touched?: Record<string, boolean>;
  labelId: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  variant?: "outlined" | "standard" | "filled";
  sx?: SxProps;
}

export interface SingleSelectProps {
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: SelectChangeEvent<string>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldTouched?: (field: string, value: boolean) => void;
  value: string | undefined;
  options: Array<{ value: string; name: string; display?: string | ReactElement; disabled?: boolean }>;
  placeholder?: string;
  title?: string | React.ReactElement;
  IconDropdown?: React.FC;
  dataTestid?: string;
  name: string;
  icon?: React.ElementType<any>;
  className?: string;
  error?: {
    [key: string]: any;
  };

  labelId: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  touched?: {
    [key: string]: any;
  };
  variant?: "outlined" | "standard" | "filled";
  sx?: SxProps;
  hiddenLabel?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

const SelectWrapper = styled(FormControl)(() => ({
  width: "100%",
  textAlign: "left",
  // marginBottom: "0.5rem",
  // marginTop: "1rem",
}));

const CustomLabel = styled(InputLabel)(() => ({
  position: "absolute",
  marginTop: "0.1rem",
  color: "#8083A3",
  fontWeight: "600",
  fontSize: "0.75rem",
  paddingBottom: "4px",
  "&.Mui-focused, input:focus": {
    marginTop: "1.2rem",
  },
  "&.MuiFormLabel-filled": {
    marginTop: "1.2rem",
    fontFamily: "SF Pro Display Medium",
  },
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: "1rem",
    width: "100%",
    padding: "1.3rem 0 0.2625rem 0.7rem",
    fontWeight: "700",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    textAlign: "left",
    border: "1px solid #E4E6E8",
    borderRadius: "0.5rem",
    "&:focus": {
      borderRadius: "0.5rem",
      border: `2px solid  ${theme.palette.primary.main}`,
      padding: "1.3rem 0 0.2625rem 0.7rem",
    },
    "&.Mui-disabled": {
      WebkitTextFillColor: "#8083A3",
      color: " #8083A3",
      backgroundColor: "#F5F5FA",
    },
    "&.Mui-error": {
      border: "2px solid #A73636",
      color: "rgba(0, 0, 0, 0.6) !important",
    },
  },
  "&.MuiFormLabel-root MuiInputLabel-root.Mui-error": {
    color: "rgba(0, 0, 0, 0.6) !important",
  },
  label: {
    color: "#8083A3",
    fontFamily: "SF Pro Display Medium",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    marginTop: "2px",
    border: "none !important",
  },
  placeholder: {
    color: "#8083A3 !important",
    fontFamily: "SF Pro Display Medium",
    lineHeight: "18px",
  },
}));
const NoLabelCustomInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: "1rem",
    width: "100%",
    padding: "0.5rem",
    fontWeight: "700",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    textAlign: "left",
    border: "1px solid #E4E6E8",
    borderRadius: "0.5rem",
    "&:focus": {
      borderRadius: "0.5rem",
      borderColor: theme.palette.primary.main,
      padding: "0.5rem",
    },
  },
}));
const findAlabel = (options: Array<{ value: string; name: string }>, value: string) => {
  const result = options.find((option) => option.value === value);
  return result?.name;
};

export const SelectMultipleInput = ({
  onChange,
  options,
  title,
  value,
  required,
  disabled,
  readonly,
  name,
  error,
  placeholder,
  labelId,
  id,
  className,
  variant,
  sx,
  touched,
  setFieldTouched,
}: Props) => {
  let isError = false;
  let isTouched = false;
  if (error[name]) {
    isError = true;
  }
  const multipleValue = value.length > 0 ? value.split(",") : [];

  if (touched?.[name]) {
    isTouched = true;
  }

  const handOnClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setFieldTouched
      ? setTimeout(() => {
          setFieldTouched(name, true);
        })
      : null;
  };

  return (
    <SelectWrapper className={className} variant={variant || "outlined"} sx={sx}>
      <CustomLabel required={required} id={labelId}>
        {title}
      </CustomLabel>
      <Select
        labelId={labelId}
        multiple
        name={name}
        value={multipleValue}
        id={id}
        disabled={disabled}
        onChange={(event: SelectChangeEvent<string[]>) => {
          const {
            target: { value },
          } = event;
          const newValues = typeof value === "string" ? value.split(",") : value;
          const setValues = newValues.length > 0 ? newValues.join(",") : "";
          onChange(name, setValues);
        }}
        placeholder={placeholder}
        required={required}
        readOnly={readonly}
        onClose={handOnClose}
        input={<CustomInput required={required} id={id} />}
        sx={{
          width: "100%",
          border: error[name] && touched && touched[name] ? "2px solid #A73636" : "",
          borderRadius: "0.5rem",
        }}
        label={title}
        error={isError && isTouched}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {selected.map((value) => (
              <Chip key={value} label={findAlabel(options, value)} variant="outlined" />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            // style={getStyles(option.value, multipleValue, theme)}
          >
            <Checkbox checked={multipleValue.includes(option.value)} />
            <ListItemText primary={option.name} />
            {/* {option.display ? option.display : option.name} */}
          </MenuItem>
        ))}
      </Select>
      {error[name] && touched && touched[name] && <Typography sx={{ color: "#A73636", mt: 1 }}>{error[name]}</Typography>}
    </SelectWrapper>
  );
};

const SelectInput = ({
  onChange,
  onBlur,
  onFocus,
  options,
  title,
  value,
  name,
  required,
  disabled,
  readonly,
  error,
  placeholder,
  labelId,
  id,
  className,
  variant,
  sx,
  hiddenLabel,
  icon: Icon,
  touched,
  setFieldTouched,
}: SingleSelectProps) => {
  let isError = false;
  let isTouched = false;

  if (error?.[name]) {
    isError = true;
  }

  if (touched?.[name]) {
    isTouched = true;
  }
  const handOnClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setFieldTouched
      ? setTimeout(() => {
          setFieldTouched(name, true);
        })
      : null;
  };

  return (
    <SelectWrapper className={className} variant={variant ? "standard" : "outlined"} sx={sx}>
      {!hiddenLabel && (
        <CustomLabel required={required} id={labelId}>
          {title}
        </CustomLabel>
      )}
      <Select
        labelId={labelId}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        onClose={handOnClose}
        onBlur={onBlur}
        IconComponent={
          Icon
            ? (props) => (
                <Icon
                  {...props}
                  sx={{
                    color: "#8083A3 !important",
                    margin: "0 5px 0 0",
                  }}
                />
              )
            : undefined
        }
        input={hiddenLabel ? <NoLabelCustomInput required={required} id={id} /> : <CustomInput required={required} id={id} />}
        sx={{
          width: "100%",
          // border: !value && isError && isTouched ? "2px solid #A73636" : "",
          borderRadius: "0.5rem",
        }}
        label={!hiddenLabel && title}
        error={isError && isTouched}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option?.disabled}>
            {/* <ListItemText primary={option.name} /> */}
            {option.display ? option.display : <ListItemText primary={option.name} />}
          </MenuItem>
        ))}
      </Select>
      {!value && error && error[name] && touched && touched[name] && <Typography sx={{ color: "#A73636", mt: 1, fontSize: "14px" }}>{error[name]}</Typography>}
    </SelectWrapper>
  );
};

export default SelectInput;
