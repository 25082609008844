import { Box, MenuItem, type SxProps, Typography } from "@mui/material";
import Input from "components/Input";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { useStyles } from 'helper/loanHelpers'
import AutoCompleteWithSearchBar from "components/AutoCompleteWithSearchBar";

interface CountryDropdownProps {
  id?: string;
  label: string | React.ReactNode;
  name: string;
  placeholder?: string;
  options: any;
  value: string;
  sx?: SxProps;
  errors?: any;
  touched?: any;
  noImage?: boolean;
  disabled?: boolean;
  hasMargin?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  handleChange?: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T,
    ): T extends React.ChangeEvent<any>
      ? // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const CountryDropdown = ({
  id,
  label,
  name,
  placeholder,
  options,
  value,
  errors,
  sx,
  onBlur,
  touched,
  noImage,
  disabled,
  hasMargin,
  setFieldValue,
  setFieldTouched,
  handleChange,
}: CountryDropdownProps) => {
  // const classes = useStyles()
  const [countryOptions, setCountryOptions] = React.useState<any>(options);
  const [listOpen, setListOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(value);
  const [anchorElSender, setAnchorElSender] = React.useState<null | HTMLElement>(null);

  const handleSelect = (event: React.BaseSyntheticEvent) => {
    setAnchorElSender(event.currentTarget);
    setListOpen(true);
    setFieldTouched?.(name, true, true);
  };

  const handleClick = (event: React.BaseSyntheticEvent<HTMLElement> | any, value: string) => {
    const newValue = { target: { name, value } };
    handleChange?.(newValue);

    setFieldValue?.(name, value, true);
    setListOpen(false);
  };

  const handleClose = () => {
    setListOpen(false);
  };

  React.useEffect(() => {
    setCountryOptions(options);
  }, [options]);

  React.useEffect(() => {
    setSelectedCountry(value);
  }, [value]);

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
        onClick={(e) => {
          handleSelect(e);
        }}
      >
        <Input
          type="text"
          id={id || "countryInput"}
          error={errors}
          margin="normal"
          name={name}
          disabled={disabled}
          onBlur={onBlur}
          required={false}
          endadornment={<KeyboardArrowDownIcon />}
          stopInput={false}
          fullWidth={false}
          touched={touched}
          value={selectedCountry}
          placeholder={placeholder}
          sx={{
            margin: hasMargin ? 0 : "",
            "& .MuiInputBase-input": {
              border: "none",
              borderColor: "transparent",
            },
            ...sx,
          }}
          label={
            <Box>
              <Typography style={{ fontWeight: "600" }}>{label}</Typography>
            </Box>
          }
        />
      </div>
      <AutoCompleteWithSearchBar
        open={listOpen}
        id={"sender-label"}
        optionLabel="fullText"
        anchorEl={anchorElSender}
        searchPlaceHolder="Search"
        noOptionsText="Country not found"
        options={countryOptions}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        getOptionLabel={(option) => `${option.name}`}
        handleClose={() => {
          handleClose();
        }}
        renderOption={(_, option) => {
          return (
            <MenuItem
              key={option.value}
              sx={{
                "&:not(:last-child)": {
                  borderBottom: "1px solid #E6E8F0",
                },
              }}
              onClick={(e) => {
                handleClick(e, option.name);
              }}
            >
              <Box
                sx={{
                  gap: "0.3rem",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  padding: "3px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <img width="16" height="12" src={option.image} style={{ display: noImage ? "none" : "block" }} />
                <Typography
                  sx={{
                    fontFamily: "SF Pro Display Medium",
                    color: "#171721",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                  {`${option.name}`}
                </Typography>
              </Box>
            </MenuItem>
          );
        }}
      />
    </>
  );
};

export default CountryDropdown;
