import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useNavigate } from "react-router-dom";

const OnboardingNavbar = () => {
  const navigate = useNavigate();
  return (
    <AppBar elevation={0} position="static" sx={{ backgroundColor: "#fff", borderBottom: "1px solid #E4E6E8" }}>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            component={"div"}
            onClick={() => {
              navigate("/auth");
            }}
            sx={{ maxWidth: "7.8rem", maxHeight: "2.7rem", cursor: "pointer" }}
          >
            <img alt="Fluna" src="/static/logo/fluna-logo.png" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default OnboardingNavbar;
