/* eslint-disable */
import React, { useState, Suspense, useEffect } from "react";
import SplashScreen from "components/SplashScreen";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import DashboardNavbar from "./DashboardNavbar";
import SecondarySidebar from "./SecondarySidebar";
import { IconButton, Box, Grid } from "@mui/material";
import { ReactComponent as ArrowRightSvg } from "assets/Images/arrowRight.svg";
import { ReactComponent as ArrowLeftSvg } from "assets/Images/arrowLeft.svg";
import SubMenuLink from "components/SubMenuLink";
import MenuLink from "components/MenuLink";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { fetchLoggedInUserModules } from "redux/permissionSlice";
import { checkAccessibleRoutes, getNoAccessModules } from "services/navigation";
import { AccessLevels } from "API";
import { BottomNav, SideNavigation } from "constants/Navigation";

const DashboardLayout = () => {
  const currentLocation = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loggedInUserModules: modules, userPermissionsLoaded } = useAppSelector((state) => state.permissions);
  const auth = useAppSelector((state) => state.auth);
  const [opened, setOpened] = useState<boolean>(true);
  const [secondaryRoute, setSecondaryRoute] = useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [showing, setShowing] = useState<string>("");
  const secondaryMenus = ["settings"];
  const pathname = currentLocation?.pathname;

  const noAccessModules = getNoAccessModules(modules);
  const { noModuleAccess, noAccessToRoute } = checkAccessibleRoutes(modules, pathname);
  const userModulesAccess = modules?.map((moduleOption) => (moduleOption?.access !== AccessLevels.noAccess ? moduleOption.name : null)).filter(Boolean);

  if (pathname !== "/request-access" && userPermissionsLoaded && (noModuleAccess || noAccessToRoute || !userModulesAccess.length)) {
    navigate("/request-access");
  }

  useEffect(() => {
    const simplePath = pathname?.split("/")[1];
    if (secondaryMenus.includes(simplePath)) {
      setSecondaryRoute(simplePath);
    } else {
      setSecondaryRoute("");
    }
  }, [pathname]);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        await dispatch(fetchLoggedInUserModules(auth.userData.email));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user modules:", error);
      }
    };

    auth.userData.email && fetchModules();
  }, [auth.userData.email]);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <Grid container>
          <Grid
            item
            md={opened ? 3 : 1}
            lg={opened ? 2 : 1}
            sx={{
              backgroundColor: "#212437",
              color: "#D5D7E3",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#212437",
                height: "100vh",
                overflowX: "hidden",
                position: "fixed",
                zIndex: "100",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                webkitOverflowScrolling: "touch",
                display: "flex",
                flexDirection: "column",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                width: {
                  md: opened ? "25%" : "8.3%",
                  lg: opened ? "16.6%" : "8.3%",
                },
              }}
            >
              <Grid
                mt={4}
                mb={opened ? 4 : 2}
                mx={opened ? 2 : 0}
                sx={{ paddingRight: "16px" }}
                container
                direction={opened ? "row" : "column"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ maxWidth: "8rem", maxHeight: "2.7rem" }}>
                  <img
                    alt="Fluna"
                    src={opened ? "/static/logo/flunaCoreLogo.png" : "/static/logo/flunaLogo.png"}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>

                <IconButton
                  size="large"
                  edge="start"
                  aria-label="collapse"
                  onClick={() => {
                    setOpened(!opened);
                  }}
                  sx={{ color: "#171721" }}
                >
                  {opened ? <ArrowLeftSvg /> : <ArrowRightSvg />}
                </IconButton>
              </Grid>

              <Box
                sx={{
                  height: "100%",
                  padding: "16px 0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    color: "#171721",
                    flexDirection: "column",
                    overflowY: "scroll",
                    maxHeight: "55vh",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    flex: 1,
                  }}
                >
                  {SideNavigation.filter((nav) => userModulesAccess?.includes(nav.module)).map((module) =>
                    module?.children?.length ? (
                      <SubMenuLink
                        key={module.name}
                        module={module}
                        opened={opened}
                        setShowing={setShowing}
                        showing={showing}
                        noAccessModules={noAccessModules}
                      />
                    ) : (
                      <MenuLink key={module.name} module={module} opened={opened} setShowing={setShowing} noAccessModules={noAccessModules} />
                    ),
                  )}
                </Box>

                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    color: "#171721",
                    flexDirection: "column",
                  }}
                >
                  {BottomNav.filter((nav) => userModulesAccess?.includes(nav.module)).map((module) => (
                    <MenuLink noAccessModules={noAccessModules} key={module.name} module={module} opened={opened} setShowing={setShowing} />
                  ))}
                </Box>
              </Box>
            </Grid>
            {secondaryRoute ? <SecondarySidebar opened={opened} path={secondaryRoute} /> : ""}
          </Grid>

          <Suspense fallback={<SplashScreen />}>
            <Grid
              sx={{
                width: "100vw",
                display: "block",
                paddingLeft: {
                  md: opened && secondaryRoute ? "16%" : "0",
                  lg: opened && secondaryRoute ? "16%" : "0",
                },
              }}
              item
              md={opened ? 9 : 11}
              lg={opened ? 10 : 11}
            >
              <DashboardNavbar />
              <Box
                sx={{
                  width: "100%",
                  display: "block",
                  margin: "0 auto",
                  zIndex: "0",
                  paddingLeft: {
                    md: !opened && secondaryRoute ? "16%" : "0",
                    lg: !opened && secondaryRoute ? "16%" : "0",
                  },
                }}
              >
                <Outlet />
              </Box>
            </Grid>
          </Suspense>
        </Grid>
      )}
    </>
  );
};

export default DashboardLayout;
