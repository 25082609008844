import {
  countEntities,
  countPeople,
  countProducts,
  createDocument,
  createEntity,
  createPerson,
  createProduct,
  createTrade,
  deleteDocument,
  deletePerson,
  deleteTrade,
  getDocumentTemplate,
  getEntity,
  listDocumentTemplates,
  listDocuments2,
  listEntitiesAll,
  listProducts,
  updateDocument,
  updateEntity,
  updateManyDocument,
  updatePerson,
  updateProducts,
  updateTrade,
} from "atlasGraphql/atlas-queries";
import { ErrorHandler } from "helper/Handlers";
import { makeAPI } from "hooks/useAtlasAxios";
import { IDocument, IEntity, IPerson, ITrade } from "interfaces/atlas";
import { setEntitiesCount, setPeopleCount, setProductsCount } from "redux/atlasSlice";
import store from "redux/store";

interface ICountEntities {
  countEntities: number;
}

interface ICountPeople {
  countPeople: number;
}

interface ICountProducts {
  countProducts: number;
}

export const retreiveEntities = async () => {
  try {
    const entities = await makeAPI<
      any,
      {
        listEntities: IEntity[];
      }
    >({
      query: listEntitiesAll,
      variables: {
        orderBy: {
          createdOn: "DESC",
        },
        skip: 0,
        take: 100000,
      },
    });
    return entities.data.listEntities;
  } catch (err) {
    console.error("Count not get entities:", err);
    ErrorHandler({
      message: "Could not get entities",
    });
  }
};

export const retreiveDocument = async (where: any) => {
  try {
    const templates = await makeAPI<
      any,
      {
        listDocuments: any[];
      }
    >({
      query: listDocuments2,
      variables: {
        where,
        orderBy: {
          createdOn: "DESC",
        },
        skip: 0,
        take: 100000,
      },
    });
    return templates.data.listDocuments;
  } catch (err) {
    console.error("Count not get document:", err);
    ErrorHandler({
      message: "Could not get document",
    });
  }
};

export const retreiveDocumentTemplates = async (where?: any) => {
  try {
    const templates = await makeAPI<
      any,
      {
        listDocumentTemplates: any[];
      }
    >({
      query: listDocumentTemplates,
      variables: {
        orderBy: {
          name: "ASC",
        },
        skip: 0,
        take: 100000,
        where,
      },
    });
    return templates.data.listDocumentTemplates;
  } catch (err) {
    console.error("Count not get document templates:", err);
    ErrorHandler({
      message: "Could not get document templates",
    });
  }
};

export const retreiveProducts = async (where?: any) => {
  try {
    const products = await makeAPI<
      any,
      {
        listProducts: any[];
      }
    >({
      query: listProducts,
      variables: {
        orderBy: {
          name: "ASC",
        },
        where,
        skip: 0,
        take: 100000,
      },
    });
    return products.data.listProducts;
  } catch (err) {
    console.error("Count not get products:", err);
    ErrorHandler({
      message: "Could not get products",
    });
  }
};

export const retreiveEntity = async (where: any) => {
  try {
    const entity = await makeAPI<
      any,
      {
        getEntity: IEntity;
      }
    >({
      query: getEntity,
      variables: {
        where,
      },
    });

    return entity.data.getEntity;
  } catch (err) {
    console.error("Count not get entity:", err);
    ErrorHandler({
      message: "Could not get entity",
    });
  }
};

export const retreiveDocumentTemplate = async (where: any) => {
  try {
    const template = await makeAPI<
      any,
      {
        getDocumentTemplate: any;
      }
    >({
      query: getDocumentTemplate,
      variables: {
        where,
      },
    });

    return template.data.getDocumentTemplate;
  } catch (err) {
    console.error("Count not get template:", err);
    ErrorHandler({
      message: "Could not get template",
    });
  }
};

export const fetchCountEntities = async (filters?: any) => {
  try {
    const count = await makeAPI<any, ICountEntities>({
      query: countEntities,
      variables: {
        orderBy: { modifiedOn: "DESC" },
        where: filters?.where,
      },
    });

    store.dispatch(setEntitiesCount(count.data.countEntities));
  } catch (err) {
    console.error("Count not get entities count:", err);
    ErrorHandler({
      message: "Could not get entities count",
    });
  }
};

export const fetchCountPeople = async (filters?: any) => {
  try {
    const count = await makeAPI<any, ICountPeople>({
      query: countPeople,
      variables: {
        orderBy: { modifiedOn: "DESC" },
        where: filters?.where,
      },
    });

    store.dispatch(setPeopleCount(count.data.countPeople));
  } catch (err) {
    console.error("Count not get people count:", err);
    ErrorHandler({
      message: "Could not get people count",
    });
  }
};

export const saveEntity = async (id: string, data: any) => {
  try {
    const response = await makeAPI<any, { updateEntity: IEntity }>({
      query: updateEntity,
      variables: {
        data,
        where: {
          id,
        },
      },
    });

    return response;
  } catch (err) {
    console.error("Count not update entity:", err);
    ErrorHandler({
      message: "Could not update entity",
    });
  }
};

export const savePerson = async (id: string, data: any) => {
  try {
    const response = await makeAPI<any, { updatePerson: IPerson }>({
      query: updatePerson,
      variables: {
        data,
        where: {
          id,
        },
      },
    });

    return response;
  } catch (err) {
    console.error("Count not update person:", err);
    ErrorHandler({
      message: "Could not update person",
    });
  }
};

export const addTrade = async (data: any) => {
  try {
    const response = await makeAPI<
      any,
      {
        createTrade: ITrade;
      }
    >({
      query: createTrade,
      variables: {
        data,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not add trade:", err);
    ErrorHandler({
      message: "Could not add trade",
    });
  }
};

export const addDocument = async (data: any) => {
  try {
    const response = await makeAPI<any, { createDocument: IDocument[] }>({
      query: createDocument,
      variables: {
        data,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not create document:", err);
    ErrorHandler({
      message: "Could not create document",
    });
  }
};

export const addEntity = async (data: any) => {
  try {
    const response = await makeAPI<any, { createEntity: IEntity }>({
      query: createEntity,
      variables: {
        data,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not create entity:", err);
    ErrorHandler({
      message: "Could not create entity",
    });
  }
};

export const addPerson = async (data: any) => {
  try {
    const response = await makeAPI<any, { createPerson: IPerson }>({
      query: createPerson,
      variables: {
        data,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not create person:", err);
    ErrorHandler({
      message: "Could not create person",
    });
  }
};

export const addProduct = async (data: any) => {
  try {
    const response = await makeAPI<any, { createProduct: any }>({
      query: createProduct,
      variables: {
        data,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not create product:", err);
    ErrorHandler({
      message: "Could not create product",
    });
  }
};

export const saveTrade = async (id: string, data: any) => {
  try {
    const response = await makeAPI<
      any,
      {
        updateTrade: ITrade;
      }
    >({
      query: updateTrade,
      variables: {
        data,
        where: {
          id,
        },
      },
    });

    return response;
  } catch (err) {
    console.error("Count not update trade:", err);
    ErrorHandler({
      message: "Could not update trade",
    });
  }
};

export const saveDocument = async (data: any, where?: any) => {
  try {
    const response = await makeAPI<any, IDocument>({
      query: updateDocument,
      variables: {
        data,
        where,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not update document:", err);
    ErrorHandler({
      message: "Could not update document",
    });
  }
};

export const saveProduct = async (data: any, where?: any) => {
  try {
    const response = await makeAPI<any, IDocument>({
      query: updateProducts,
      variables: {
        data,
        where,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not update product:", err);
    ErrorHandler({
      message: "Could not update product",
    });
  }
};

export const saveManyDocument = async (data: any, where?: any) => {
  try {
    const response = await makeAPI<any, { updateManyDocuments: { count: number } }>({
      query: updateManyDocument,
      variables: {
        data,
        where,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not update documents:", err);
    ErrorHandler({
      message: "Could not update documents",
    });
  }
};

export const removeDocument = async (where: any) => {
  try {
    const response = await makeAPI<any, { deleteDocument: IDocument }>({
      query: deleteDocument,
      variables: {
        where,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not delete document:", err);
    ErrorHandler({
      message: "Could not delete document",
    });
  }
};

export const removePerson = async (where: any) => {
  try {
    const response = await makeAPI<any, { deletePerson: IDocument }>({
      query: deletePerson,
      variables: {
        where,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not delete person:", err);
    ErrorHandler({
      message: "Could not delete person",
    });
  }
};

export const removeTrade = async (where: any) => {
  try {
    const response = await makeAPI<any, { deleteTrade: ITrade }>({
      query: deleteTrade,
      variables: {
        where,
      },
    });

    return response;
  } catch (err) {
    console.error("Count not delete trade:", err);
    ErrorHandler({
      message: "Could not delete trade",
    });
  }
};

export const fetchCountProducts = async (filters?: any) => {
  try {
    const count = await makeAPI<any, ICountProducts>({
      query: countProducts,
      variables: {
        orderBy: { name: "ASC" },
        where: filters?.where,
      },
    });

    store.dispatch(setProductsCount(count.data.countProducts));
  } catch (err) {
    console.error("Count not get products count:", err);
    ErrorHandler({
      message: "Could not get products count",
    });
  }
};
