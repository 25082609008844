export const CapitalizeText = (text: string) => {
  if (text) {
    const result = text.charAt(0).toUpperCase() + text.slice(1) || text;

    return result;
  }
  return text || "";
};

export const UppercaseTransform = (value: string | number) => {
  if (value && typeof value === "string") {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return value;
  }
};

export const CapitalizeSentenceFirstLetters = (sentence: string) => {
  if (!sentence) {
    return sentence;
  }
  const words = sentence.split(" ");

  const capitalizedSentenceFirstLetters = words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ");

  return capitalizedSentenceFirstLetters;
};

export const camelCaseToNormal = (text: string) => {
  if (!text) {
    return text;
  }
  const replacedLoanType = text?.replace(/([A-Z]+)/g, " $1") ?? "";
  const formattedLoanType = replacedLoanType.charAt(0).toUpperCase() + replacedLoanType.slice(1);

  return formattedLoanType;
};

export const stripSpaces = (item: string) => {
  return item.replace(/\s+/g, "");
};

export const truncateText = (str, number = 12) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${str.slice(0, number)}...`;
};

export const truncateID = (str) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${str.slice(0, 5)}..${str.slice(str.length - 6, str.length - 1)}`;
};
