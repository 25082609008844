/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUsers = /* GraphQL */ `mutation CreateUsers(
  $input: CreateUsersInput!
  $condition: ModelUsersConditionInput
) {
  createUsers(input: $input, condition: $condition) {
    id
    name
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUsersMutationVariables,
  APITypes.CreateUsersMutation
>;
export const updateUsers = /* GraphQL */ `mutation UpdateUsers(
  $input: UpdateUsersInput!
  $condition: ModelUsersConditionInput
) {
  updateUsers(input: $input, condition: $condition) {
    id
    name
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUsersMutationVariables,
  APITypes.UpdateUsersMutation
>;
export const deleteUsers = /* GraphQL */ `mutation DeleteUsers(
  $input: DeleteUsersInput!
  $condition: ModelUsersConditionInput
) {
  deleteUsers(input: $input, condition: $condition) {
    id
    name
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUsersMutationVariables,
  APITypes.DeleteUsersMutation
>;
export const createTextractRecords = /* GraphQL */ `mutation CreateTextractRecords(
  $input: CreateTextractRecordsInput!
  $condition: ModelTextractRecordsConditionInput
) {
  createTextractRecords(input: $input, condition: $condition) {
    id
    bankName
    companyName
    country
    currency
    fileName
    processingStatus
    transactionFileName
    uploaderImage
    uploaderName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTextractRecordsMutationVariables,
  APITypes.CreateTextractRecordsMutation
>;
export const updateTextractRecords = /* GraphQL */ `mutation UpdateTextractRecords(
  $input: UpdateTextractRecordsInput!
  $condition: ModelTextractRecordsConditionInput
) {
  updateTextractRecords(input: $input, condition: $condition) {
    id
    bankName
    companyName
    country
    currency
    fileName
    processingStatus
    transactionFileName
    uploaderImage
    uploaderName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTextractRecordsMutationVariables,
  APITypes.UpdateTextractRecordsMutation
>;
export const deleteTextractRecords = /* GraphQL */ `mutation DeleteTextractRecords(
  $input: DeleteTextractRecordsInput!
  $condition: ModelTextractRecordsConditionInput
) {
  deleteTextractRecords(input: $input, condition: $condition) {
    id
    bankName
    companyName
    country
    currency
    fileName
    processingStatus
    transactionFileName
    uploaderImage
    uploaderName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTextractRecordsMutationVariables,
  APITypes.DeleteTextractRecordsMutation
>;
export const createTextractEntities = /* GraphQL */ `mutation CreateTextractEntities(
  $input: CreateTextractEntitiesInput!
  $condition: ModelTextractEntitiesConditionInput
) {
  createTextractEntities(input: $input, condition: $condition) {
    id
    name
    type
    count
    inflows
    outflows
    average
    country
    currency
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTextractEntitiesMutationVariables,
  APITypes.CreateTextractEntitiesMutation
>;
export const updateTextractEntities = /* GraphQL */ `mutation UpdateTextractEntities(
  $input: UpdateTextractEntitiesInput!
  $condition: ModelTextractEntitiesConditionInput
) {
  updateTextractEntities(input: $input, condition: $condition) {
    id
    name
    type
    count
    inflows
    outflows
    average
    country
    currency
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTextractEntitiesMutationVariables,
  APITypes.UpdateTextractEntitiesMutation
>;
export const deleteTextractEntities = /* GraphQL */ `mutation DeleteTextractEntities(
  $input: DeleteTextractEntitiesInput!
  $condition: ModelTextractEntitiesConditionInput
) {
  deleteTextractEntities(input: $input, condition: $condition) {
    id
    name
    type
    count
    inflows
    outflows
    average
    country
    currency
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTextractEntitiesMutationVariables,
  APITypes.DeleteTextractEntitiesMutation
>;
export const createTextractTransactions = /* GraphQL */ `mutation CreateTextractTransactions(
  $input: CreateTextractTransactionsInput!
  $condition: ModelTextractTransactionsConditionInput
) {
  createTextractTransactions(input: $input, condition: $condition) {
    id
    amount
    bank
    isCredit
    currency
    date
    modifiedDate
    formattedDate
    description
    entityId
    entityName
    entityType
    fileId
    fileName
    companyName
    processingBank
    additionalText
    channel
    chequeNo
    customerReference
    instrumentCode
    reference
    transactionType
    valueDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTextractTransactionsMutationVariables,
  APITypes.CreateTextractTransactionsMutation
>;
export const updateTextractTransactions = /* GraphQL */ `mutation UpdateTextractTransactions(
  $input: UpdateTextractTransactionsInput!
  $condition: ModelTextractTransactionsConditionInput
) {
  updateTextractTransactions(input: $input, condition: $condition) {
    id
    amount
    bank
    isCredit
    currency
    date
    modifiedDate
    formattedDate
    description
    entityId
    entityName
    entityType
    fileId
    fileName
    companyName
    processingBank
    additionalText
    channel
    chequeNo
    customerReference
    instrumentCode
    reference
    transactionType
    valueDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTextractTransactionsMutationVariables,
  APITypes.UpdateTextractTransactionsMutation
>;
export const deleteTextractTransactions = /* GraphQL */ `mutation DeleteTextractTransactions(
  $input: DeleteTextractTransactionsInput!
  $condition: ModelTextractTransactionsConditionInput
) {
  deleteTextractTransactions(input: $input, condition: $condition) {
    id
    amount
    bank
    isCredit
    currency
    date
    modifiedDate
    formattedDate
    description
    entityId
    entityName
    entityType
    fileId
    fileName
    companyName
    processingBank
    additionalText
    channel
    chequeNo
    customerReference
    instrumentCode
    reference
    transactionType
    valueDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTextractTransactionsMutationVariables,
  APITypes.DeleteTextractTransactionsMutation
>;
export const createFlunaMembers = /* GraphQL */ `mutation CreateFlunaMembers(
  $input: CreateFlunaMembersInput!
  $condition: ModelFlunaMembersConditionInput
) {
  createFlunaMembers(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    primaryTeam
    lastActive
    picture
    modules {
      name
      description
      access
      __typename
    }
    status
    googleUserCognitoId
    emailUserCognitoId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlunaMembersMutationVariables,
  APITypes.CreateFlunaMembersMutation
>;
export const updateFlunaMembers = /* GraphQL */ `mutation UpdateFlunaMembers(
  $input: UpdateFlunaMembersInput!
  $condition: ModelFlunaMembersConditionInput
) {
  updateFlunaMembers(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    primaryTeam
    lastActive
    picture
    modules {
      name
      description
      access
      __typename
    }
    status
    googleUserCognitoId
    emailUserCognitoId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlunaMembersMutationVariables,
  APITypes.UpdateFlunaMembersMutation
>;
export const deleteFlunaMembers = /* GraphQL */ `mutation DeleteFlunaMembers(
  $input: DeleteFlunaMembersInput!
  $condition: ModelFlunaMembersConditionInput
) {
  deleteFlunaMembers(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    primaryTeam
    lastActive
    picture
    modules {
      name
      description
      access
      __typename
    }
    status
    googleUserCognitoId
    emailUserCognitoId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlunaMembersMutationVariables,
  APITypes.DeleteFlunaMembersMutation
>;
export const createWaiverRequests = /* GraphQL */ `mutation CreateWaiverRequests(
  $input: CreateWaiverRequestsInput!
  $condition: ModelWaiverRequestsConditionInput
) {
  createWaiverRequests(input: $input, condition: $condition) {
    id
    loanId
    companyId
    paymentId
    currency
    lateFee
    requester
    requestedBy
    requesterImage
    requestNotes
    waiverRequested
    approver
    approvedAt
    approvedBy
    approverImage
    approvalNotes
    waiverApproved
    status
    supportingDocuments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWaiverRequestsMutationVariables,
  APITypes.CreateWaiverRequestsMutation
>;
export const updateWaiverRequests = /* GraphQL */ `mutation UpdateWaiverRequests(
  $input: UpdateWaiverRequestsInput!
  $condition: ModelWaiverRequestsConditionInput
) {
  updateWaiverRequests(input: $input, condition: $condition) {
    id
    loanId
    companyId
    paymentId
    currency
    lateFee
    requester
    requestedBy
    requesterImage
    requestNotes
    waiverRequested
    approver
    approvedAt
    approvedBy
    approverImage
    approvalNotes
    waiverApproved
    status
    supportingDocuments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWaiverRequestsMutationVariables,
  APITypes.UpdateWaiverRequestsMutation
>;
export const deleteWaiverRequests = /* GraphQL */ `mutation DeleteWaiverRequests(
  $input: DeleteWaiverRequestsInput!
  $condition: ModelWaiverRequestsConditionInput
) {
  deleteWaiverRequests(input: $input, condition: $condition) {
    id
    loanId
    companyId
    paymentId
    currency
    lateFee
    requester
    requestedBy
    requesterImage
    requestNotes
    waiverRequested
    approver
    approvedAt
    approvedBy
    approverImage
    approvalNotes
    waiverApproved
    status
    supportingDocuments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWaiverRequestsMutationVariables,
  APITypes.DeleteWaiverRequestsMutation
>;
export const createDocuments = /* GraphQL */ `mutation CreateDocuments(
  $input: CreateDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  createDocuments(input: $input, condition: $condition) {
    id
    companyId
    key
    bucketName
    fileName
    name
    category
    complianceStatus
    expiresAt
    entityType
    entityId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentsMutationVariables,
  APITypes.CreateDocumentsMutation
>;
export const updateDocuments = /* GraphQL */ `mutation UpdateDocuments(
  $input: UpdateDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  updateDocuments(input: $input, condition: $condition) {
    id
    companyId
    key
    bucketName
    fileName
    name
    category
    complianceStatus
    expiresAt
    entityType
    entityId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentsMutationVariables,
  APITypes.UpdateDocumentsMutation
>;
export const deleteDocuments = /* GraphQL */ `mutation DeleteDocuments(
  $input: DeleteDocumentsInput!
  $condition: ModelDocumentsConditionInput
) {
  deleteDocuments(input: $input, condition: $condition) {
    id
    companyId
    key
    bucketName
    fileName
    name
    category
    complianceStatus
    expiresAt
    entityType
    entityId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentsMutationVariables,
  APITypes.DeleteDocumentsMutation
>;
export const createKPIMetrics = /* GraphQL */ `mutation CreateKPIMetrics(
  $input: CreateKPIMetricsInput!
  $condition: ModelKPIMetricsConditionInput
) {
  createKPIMetrics(input: $input, condition: $condition) {
    id
    day
    investmentData {
      totalInvestments
      totalActiveInvestments
      totalWalletBalance
      debtInvestmentAdded
      debtInvestmentWithdrawn
      totalInvestors
      activeInvestors
      investmentSize
      averageInvestmentSize
      weightedAverageCostOfCapital
      __typename
    }
    complianceData {
      numberOfUsersOnPlatform
      numberOfCompaniesOnPlatform
      numberOfOnboardedCompany
      numberOfKycApprovedCompany
      numberOfCompaniesWithBankAccountsConnected
      __typename
    }
    lendingData {
      totalActiveUSDLoansCount
      totalActiveNGNLoansCount
      totalActiveUSDLoansAmount
      totalActiveNGNLoansAmount
      totalActiveLoansAmount
      totalActiveNonNigerianLoansCount
      totalActiveNigerianLoansCount
      totalActiveNonNigerianLoansAmount
      totalActiveNigerianLoansAmount
      totalCummulativeLoansAmount
      monthlyInterestIncomeAllLoans
      monthlyInterestIncomeLt90Loans
      totalOngoingLoansAmount
      totalLateLoansAmount
      totalPaidLoansAmount
      loansDistributionByCountry {
        country
        loanAmount
        __typename
      }
      loansDistributionByCompany {
        company
        companyId
        loanAmount
        __typename
      }
      loansDistributionByLoan {
        loanName
        loanId
        applicationId
        loanAmount
        __typename
      }
      __typename
    }
    tradeData {
      numberOfSuppliers
      numberOfBuyers
      brokerageRevenue
      numberOfTransactions
      numberOfBuyersServed
      grossMerchandisedValue
      __typename
    }
    MCAData {
      totalMCAOpened
      totalMCAInflowVolume
      totalMCAOutflowVolume
      MCAInflowVolume
      MCAOutflowVolume
      newFxVolume
      USDMCAAcounts
      EURMCAAcounts
      GBPMCAAcounts
      __typename
    }
    USDRateUsed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKPIMetricsMutationVariables,
  APITypes.CreateKPIMetricsMutation
>;
export const updateKPIMetrics = /* GraphQL */ `mutation UpdateKPIMetrics(
  $input: UpdateKPIMetricsInput!
  $condition: ModelKPIMetricsConditionInput
) {
  updateKPIMetrics(input: $input, condition: $condition) {
    id
    day
    investmentData {
      totalInvestments
      totalActiveInvestments
      totalWalletBalance
      debtInvestmentAdded
      debtInvestmentWithdrawn
      totalInvestors
      activeInvestors
      investmentSize
      averageInvestmentSize
      weightedAverageCostOfCapital
      __typename
    }
    complianceData {
      numberOfUsersOnPlatform
      numberOfCompaniesOnPlatform
      numberOfOnboardedCompany
      numberOfKycApprovedCompany
      numberOfCompaniesWithBankAccountsConnected
      __typename
    }
    lendingData {
      totalActiveUSDLoansCount
      totalActiveNGNLoansCount
      totalActiveUSDLoansAmount
      totalActiveNGNLoansAmount
      totalActiveLoansAmount
      totalActiveNonNigerianLoansCount
      totalActiveNigerianLoansCount
      totalActiveNonNigerianLoansAmount
      totalActiveNigerianLoansAmount
      totalCummulativeLoansAmount
      monthlyInterestIncomeAllLoans
      monthlyInterestIncomeLt90Loans
      totalOngoingLoansAmount
      totalLateLoansAmount
      totalPaidLoansAmount
      loansDistributionByCountry {
        country
        loanAmount
        __typename
      }
      loansDistributionByCompany {
        company
        companyId
        loanAmount
        __typename
      }
      loansDistributionByLoan {
        loanName
        loanId
        applicationId
        loanAmount
        __typename
      }
      __typename
    }
    tradeData {
      numberOfSuppliers
      numberOfBuyers
      brokerageRevenue
      numberOfTransactions
      numberOfBuyersServed
      grossMerchandisedValue
      __typename
    }
    MCAData {
      totalMCAOpened
      totalMCAInflowVolume
      totalMCAOutflowVolume
      MCAInflowVolume
      MCAOutflowVolume
      newFxVolume
      USDMCAAcounts
      EURMCAAcounts
      GBPMCAAcounts
      __typename
    }
    USDRateUsed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKPIMetricsMutationVariables,
  APITypes.UpdateKPIMetricsMutation
>;
export const deleteKPIMetrics = /* GraphQL */ `mutation DeleteKPIMetrics(
  $input: DeleteKPIMetricsInput!
  $condition: ModelKPIMetricsConditionInput
) {
  deleteKPIMetrics(input: $input, condition: $condition) {
    id
    day
    investmentData {
      totalInvestments
      totalActiveInvestments
      totalWalletBalance
      debtInvestmentAdded
      debtInvestmentWithdrawn
      totalInvestors
      activeInvestors
      investmentSize
      averageInvestmentSize
      weightedAverageCostOfCapital
      __typename
    }
    complianceData {
      numberOfUsersOnPlatform
      numberOfCompaniesOnPlatform
      numberOfOnboardedCompany
      numberOfKycApprovedCompany
      numberOfCompaniesWithBankAccountsConnected
      __typename
    }
    lendingData {
      totalActiveUSDLoansCount
      totalActiveNGNLoansCount
      totalActiveUSDLoansAmount
      totalActiveNGNLoansAmount
      totalActiveLoansAmount
      totalActiveNonNigerianLoansCount
      totalActiveNigerianLoansCount
      totalActiveNonNigerianLoansAmount
      totalActiveNigerianLoansAmount
      totalCummulativeLoansAmount
      monthlyInterestIncomeAllLoans
      monthlyInterestIncomeLt90Loans
      totalOngoingLoansAmount
      totalLateLoansAmount
      totalPaidLoansAmount
      loansDistributionByCountry {
        country
        loanAmount
        __typename
      }
      loansDistributionByCompany {
        company
        companyId
        loanAmount
        __typename
      }
      loansDistributionByLoan {
        loanName
        loanId
        applicationId
        loanAmount
        __typename
      }
      __typename
    }
    tradeData {
      numberOfSuppliers
      numberOfBuyers
      brokerageRevenue
      numberOfTransactions
      numberOfBuyersServed
      grossMerchandisedValue
      __typename
    }
    MCAData {
      totalMCAOpened
      totalMCAInflowVolume
      totalMCAOutflowVolume
      MCAInflowVolume
      MCAOutflowVolume
      newFxVolume
      USDMCAAcounts
      EURMCAAcounts
      GBPMCAAcounts
      __typename
    }
    USDRateUsed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKPIMetricsMutationVariables,
  APITypes.DeleteKPIMetricsMutation
>;
