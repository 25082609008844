// import { LoanData } from './../interfaces/loanbook';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ErrorHandler } from "helper/Handlers";
import { fetchFromTable } from "helper/dynamodb";
import { type IDocumentsData } from "interfaces/documents";
import { type LoanPayment, type ILoansTableData, type InvoiceDiscountingPayment } from "interfaces/loans";

const loanTable = process.env.REACT_APP_COMPANY_LOANS_TABLE as string;
const invoiceDiscTable = process.env.REACT_APP_INVOICE_DISC_TABLE as string;

interface LoanState {
  loan: ILoansTableData;
  invoiceDisc: any;
  loans: ILoansTableData[];
  invoiceDiscs: ILoansTableData[];
  fetchingLoans: boolean;
  invoiceDiscPayments: InvoiceDiscountingPayment[];
  loanPayments: LoanPayment[];
  loanRepayments: LoanPayment[];
  loanDocuments: IDocumentsData[];
  preDisbursementChecklist: any;
  postDisbursementChecklist: any;
  waiverRequests: any;
  waiverRequestsLoading: boolean;
}

const initialState: LoanState = {
  loan: {},
  invoiceDisc: {},
  loans: [],
  invoiceDiscs: [],
  fetchingLoans: false,
  invoiceDiscPayments: [],
  loanPayments: [],
  loanRepayments: [],
  loanDocuments: [],
  preDisbursementChecklist: {},
  postDisbursementChecklist: {},
  waiverRequests: [],
  waiverRequestsLoading: false,
};

export const fetchAllLoansTableData = createAsyncThunk("loans/fetchAllLoans", async () => {
  try {
    const params = {
      ProjectionExpression:
        "id, companyID, applicationId, createdAt, loanType, creditStructureApproved, allowRenewal, renewalId, #Status, submittedAt, rolledOver",
      ExpressionAttributeNames: { "#Status": "status" },
      TableName: loanTable,
    };

    const loans = (await fetchFromTable(params)) as ILoansTableData[];
    return loans;
  } catch (err: any) {
    console.log("loanFetchErr", err);
    ErrorHandler({ message: "Unable to fetch loans data" });
    return [] as ILoansTableData[];
  }
});

export const fetchAllInvoicesTableData = createAsyncThunk("loans/fetchAllInvoiceDiscs", async () => {
  try {
    const params = {
      ProjectionExpression: "id, companyID, applicationId, createdAt, overviewApproved, #Status",
      ExpressionAttributeNames: { "#Status": "status" },
      TableName: invoiceDiscTable,
    };

    const invoiceDisc = (await fetchFromTable(params)) as ILoansTableData[];
    return invoiceDisc;
  } catch (err: any) {
    console.log("loanFetchErr", err);
    ErrorHandler({ message: "Unable to fetch loans data" });
    return [] as ILoansTableData[];
  }
});

// Then, handle actions in your reducers:
const loanSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    setLoanTableData: (
      state,
      action: PayloadAction<{
        data: ILoansTableData[];
      }>,
    ) => {
      state.loans = action.payload.data;
    },
    setLoanData: (
      state,
      action: PayloadAction<{
        data: ILoansTableData;
      }>,
    ) => {
      state.loan = action.payload.data;
    },
    setInvDiscData: (
      state,
      action: PayloadAction<{
        data: ILoansTableData;
      }>,
    ) => {
      state.invoiceDisc = action.payload.data;
    },
    setLoanDocumentsData: (
      state,
      action: PayloadAction<{
        data: IDocumentsData[];
      }>,
    ) => {
      state.loanDocuments = action.payload.data;
    },
    setLoanPaymentsData: (
      state,
      action: PayloadAction<{
        data: LoanPayment[];
      }>,
    ) => {
      state.loanPayments = action.payload.data;
    },
    setLoanRepaymentData: (
      state,
      action: PayloadAction<{
        data: LoanPayment[];
      }>,
    ) => {
      state.loanRepayments = action.payload.data;
    },
    setPreDisbursementChecklistData: (
      state,
      action: PayloadAction<{
        data: LoanPayment[];
      }>,
    ) => {
      state.preDisbursementChecklist = action.payload.data;
    },
    setPostDisbursementChecklistData: (
      state,
      action: PayloadAction<{
        data: LoanPayment[];
      }>,
    ) => {
      state.postDisbursementChecklist = action.payload.data;
    },
    setWaiverRequestsData: (
      state,
      action: PayloadAction<{
        data: any[];
      }>,
    ) => {
      state.waiverRequests = action.payload.data;
    },
    setWaiverRequestsLoader: (
      state,
      action: PayloadAction<{
        data: boolean;
      }>,
    ) => {
      state.waiverRequestsLoading = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllLoansTableData.pending, (state) => {
      state.fetchingLoans = true;
    });
    builder.addCase(fetchAllLoansTableData.fulfilled, (state, action) => {
      state.loans = action.payload;
      state.fetchingLoans = false;
    });
    builder.addCase(fetchAllLoansTableData.rejected, (state, action) => {
      state.loans = [...state.loans, ...(action.payload as ILoansTableData[])];
      state.fetchingLoans = false;
    });
    builder.addCase(fetchAllInvoicesTableData.fulfilled, (state, action) => {
      state.invoiceDiscs = action.payload;
    });
    builder.addCase(fetchAllInvoicesTableData.rejected, (state, action) => {
      state.invoiceDiscs = [...state.loans, ...(action.payload as ILoansTableData[])];
    });
  },
});

export const {
  setLoanTableData,
  setLoanDocumentsData,
  setLoanData,
  setInvDiscData,
  setLoanPaymentsData,
  setLoanRepaymentData,
  setPreDisbursementChecklistData,
  setPostDisbursementChecklistData,
  setWaiverRequestsData,
} = loanSlice.actions;

export default loanSlice.reducer;
