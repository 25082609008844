/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ErrorHandler } from "helper/Handlers";
import {  queryTable } from "helper/dynamodb";
import { type CompanyData } from "interfaces/company";
import { type IDocumentsData, type IDocumentRequestData } from "interfaces/documents";
import { type IOwnershipDetails, type IDirectorDetails } from "interfaces/owners";

const documentRequestTable = process.env.REACT_APP_DOCUMENT_REQUEST_TABLE as string;

interface HomeState {
  companyId: string;
  companyData: CompanyData;
  documentRequests: IDocumentRequestData[];
  fetchingDocumentRequests: boolean;
  companyDocuments: IDocumentsData[];
  companyOwners: IOwnershipDetails[];
  companyDirectors: IDirectorDetails[];
  companyOwnersDocuments: IDocumentsData[];
  companyDirectorsDocuments: IDocumentsData[];
}

const initialState: HomeState = {
  companyId: "",
  companyData: {},
  documentRequests: [],
  fetchingDocumentRequests: false,
  companyDocuments: [],
  companyOwners: [],
  companyDirectors: [],
  companyOwnersDocuments: [],
  companyDirectorsDocuments: [],
};

export const fetchDocumentRequestTableData = createAsyncThunk("users/fetchDocumentRequestTableData", async (companyId: string) => {
  try {

    const companyDocumentsParams = {
      TableName: documentRequestTable,
      KeyConditionExpression: "#companyId = :companyId",
      IndexName: "byCompany",
      ExpressionAttributeNames: { "#companyId": "companyId" },
      ExpressionAttributeValues: {
        ":companyId": companyId,
      },
    };

    const companyDocumentsRequests = await queryTable(companyDocumentsParams);

    
    return companyDocumentsRequests;
  } catch (err: any) {
    console.log("userFetchErr", err);
    ErrorHandler({ message: "Unable to fetch users data" });
    return [] as IDocumentRequestData[];
  }
});

// Then, handle actions in your reducers:
const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    clearKYCData: (
      state,
      // action: PayloadAction<>,
    ) => {
      state.companyId = "";
      state.companyData = {};
      state.documentRequests = [];
      state.fetchingDocumentRequests = false;
      state.companyDocuments = [];
      state.companyOwners = [];
      state.companyDirectors = [];
      state.companyOwnersDocuments = [];
      state.companyDirectorsDocuments = [];
    },
    setCompanyID: (
      state,
      action: PayloadAction<{
        data: string;
      }>,
    ) => {
      state.companyId = action.payload.data;
    },
    setCompanyData: (
      state,
      action: PayloadAction<{
        data: CompanyData;
      }>,
    ) => {
      state.companyData = action.payload.data;
    },
    setDocumentTableData: (
      state,
      action: PayloadAction<{
        data: IDocumentRequestData[];
      }>,
    ) => {
      state.documentRequests = action.payload.data;
    },
    setCompanyDocumentsData: (
      state,
      action: PayloadAction<{
        data: IDocumentsData[];
      }>,
    ) => {
      state.companyDocuments = action.payload.data;
    },
    setCompanyOwnersData: (
      state,
      action: PayloadAction<{
        data: IOwnershipDetails[];
      }>,
    ) => {
      state.companyOwners = action.payload.data;
    },
    setCompanyDirectorsData: (
      state,
      action: PayloadAction<{
        data: IDirectorDetails[];
      }>,
    ) => {
      state.companyDirectors = action.payload.data;
    },
    setCompanyOwnersDocumentsData: (
      state,
      action: PayloadAction<{
        data: any[];
      }>,
    ) => {
      state.companyOwnersDocuments = action.payload.data;
    },
    setCompanyDirectorsDocumentsData: (
      state,
      action: PayloadAction<{
        data: any[];
      }>,
    ) => {
      state.companyDirectorsDocuments = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentRequestTableData.pending, (state) => {
      state.fetchingDocumentRequests = true;
    });
    builder.addCase(fetchDocumentRequestTableData.fulfilled, (state, action) => {
      state.documentRequests = action.payload;
      state.fetchingDocumentRequests = false;
    });
    builder.addCase(fetchDocumentRequestTableData.rejected, (state, action) => {
      state.documentRequests = action.payload as IDocumentRequestData[];
      state.fetchingDocumentRequests = false;
    });
  },
});

export const {
  clearKYCData,
  setCompanyID,
  setCompanyData,
  setDocumentTableData,
  setCompanyDocumentsData,
  setCompanyOwnersData,
  setCompanyDirectorsData,
  setCompanyOwnersDocumentsData,
  setCompanyDirectorsDocumentsData,
} = documentsSlice.actions;

export default documentsSlice.reducer;
