/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const CampaignBuilder = lazy(() => import("pages/trade/campaignBuilder"));
const CreateCampaign = lazy(() => import("pages/trade/campaignBuilder/createCampaign"));
const TradeTracker = lazy(() => import("pages/trade/tradeTracker"));

export const TradeChildren = [
  {
    path: "campaign-builder",
    element: <CampaignBuilder />,
  },
  {
    path: "campaign-builder/new-campaign",
    element: <CreateCampaign />,
  },
  {
    path: "trade-tracker",
    element: <TradeTracker />,
  },
  {
    path: "trade-tracker/orders",
    element: <TradeTracker />,
  },
];
