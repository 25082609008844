/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { StepperNav } from "interfaces/atlas";
import { useFormik } from "formik";
import { saveEntity } from "services/atlas";
import { NewEntityContext } from "./newEntityContext";
import BottomNav from "./bottomNav";
import Input from "components/Input";
import { useNavigate } from "react-router-dom";

interface FormValues {
  note: string;
}

const NewEntityNote = ({ handleBack }: StepperNav) => {
  const navigate = useNavigate();
  const { entity } = useContext(NewEntityContext);
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: FormValues = {
    note: Array.isArray(entity?.notes) ? entity?.notes.join(", ") : "",
  };

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await saveEntity(entity?.id || "", {
          notes: values.note.split(","),
        });
        navigate("/atlas");
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
  });

  const { handleBlur, handleChange, handleSubmit, errors, touched } = formik;

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Additional Notes
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Input
              name="note"
              multiline
              rows={10}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Additional Notes"
              value={formik.values.note}
              error={errors}
              touched={touched}
            />
          </Grid>
        </Grid>
        <BottomNav handleBack={handleBack} loading={loading} />
      </Box>
    </Box>
  );
};

export default NewEntityNote;
