export const updateEntity = /* GraphQL */ `
  mutation updateEntity($where: EntityExtendedWhereUniqueInput!, $data: EntityUpdateInput) {
    updateEntity(where: $where, data: $data) {
      id
      name
      metaData
      keyContactId
      hubspotId
      isFlunaUser
      tinNumber
      state
      pastInteraction
      flunaRegistrationDate
      entityType
      email
      entityType
      phoneNumber
      postCode
      exportedBefore
      supplierProductExportExperience {
        id
        name
      }
      street
      dateIncorporated
      createdOn
      modifiedOn
      country
      companyTaxId
      city
      website
      documents {
        id
        entityId
        personId
        tradeId
        key
        name
        bucketName
        documentTemplateId
        documentTemplate {
          id
          name
          category
          documentFields
        }
        records
        createdOn
        modifiedOn
      }
      persons {
        id
        entityId
        firstName
        lastName
        gender
        role
        country
        city
        street
        postCode
        dateOfBirth
        email
        phoneNumber
        source
        isFlunaUser
        isVerified
        flunaRegistrationDate
        isDirector
        isShareholder
        createdOn
        modifiedOn
        documents {
          id
          entityId
          personId
          tradeId
          key
          name
          issueDate
          expiryDate
          bucketName
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
      }
    }
  }
`;

export const updatePerson = /* GraphQL */ `
  mutation updatePerson($where: PersonExtendedWhereUniqueInput!, $data: PersonUpdateInput) {
    updatePerson(where: $where, data: $data) {
      id
      entityId
      createdOn
      modifiedOn
    }
  }
`;

export const getDocument = /* GraphQL */ `
  query getDocument($where: DocumentExtendedWhereUniqueInput!) {
    getDocument(where: $where) {
      id
    }
  }
`;

export const getDocumentTemplate = /* GraphQL */ `
  query getDocumentTemplate($where: DocumentTemplateExtendedWhereUniqueInput!) {
    getDocumentTemplate(where: $where) {
      id
      name
      category
    }
  }
`;

export const getEntity = /* GraphQL */ `
  query getEntity($where: EntityExtendedWhereUniqueInput!) {
    getEntity(where: $where) {
      id
      name
      metaData
      keyContactId
      notes
      hubspotId
      isFlunaUser
      description
      productDescription
      pastInteraction
      flunaRegistrationDate
      entityType
      email
      source
      entityType
      phoneNumber
      postCode
      exportedBefore
      supplierProductExportExperience {
        id
        name
      }
      street
      dateIncorporated
      createdOn
      supplierSourcingCountries
      modifiedOn
      country
      companyTaxId
      city
      website
      documents {
        id
        entityId
        personId
        tradeId
        key
        gcn
        name
        bucketName
        issueDate
        expiryDate
        softDelete
        documentTemplateId
        documentTemplate {
          id
          name
          category
          documentFields
        }
        records
        createdOn
        modifiedOn
      }
      persons {
        id
        entityId
        firstName
        lastName
        gender
        role
        country
        city
        street
        postCode
        dateOfBirth
        email
        phoneNumber
        source
        isFlunaUser
        isVerified
        flunaRegistrationDate
        isDirector
        isShareholder
        createdOn
        modifiedOn
        documents {
          id
          entityId
          personId
          tradeId
          key
          name
          issueDate
          expiryDate
          bucketName
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
      }
    }
  }
`;

export const listDocumentTemplates = /* GraphQL */ `
  query listDocumentTemplates($where: DocumentTemplateWhereInput, $orderBy: [DocumentTemplateOrderByInput!], $skip: Int, $take: Int) {
    listDocumentTemplates(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      name
      category
      documentFields
    }
  }
`;

export const listEntities = /* GraphQL */ `
  query listEntities($where: EntityWhereInput, $orderBy: [EntityOrderByInput!], $skip: Int, $take: Int) {
    listEntities(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      name
      metaData
      keyContactId
      hubspotId
      isFlunaUser
      pastInteraction
      flunaRegistrationDate
      entityType
      email
      entityType
      phoneNumber
      postCode
      exportedBefore
      supplierProductExportExperience {
        id
        name
      }
      street
      dateIncorporated
      createdOn
      modifiedOn
      country
      companyTaxId
      city
      website
      documents {
        id
        entityId
        personId
        tradeId
        key
        name
        bucketName
        issueDate
        expiryDate
        documentTemplateId
        documentTemplate {
          id
          name
          category
          documentFields
        }
        records
        createdOn
        modifiedOn
      }
      persons {
        id
        entityId
        firstName
        lastName
        gender
        role
        country
        city
        street
        postCode
        dateOfBirth
        email
        phoneNumber
        source
        isFlunaUser
        isVerified
        flunaRegistrationDate
        isDirector
        isShareholder
        createdOn
        modifiedOn
        documents {
          id
          entityId
          personId
          tradeId
          key
          name
          issueDate
          expiryDate
          bucketName
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
      }
    }
  }
`;

export const listEntitiesAll = /* GraphQL */ `
  query listEntities($where: EntityWhereInput, $orderBy: [EntityOrderByInput!]) {
    listEntities(where: $where, orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const countEntities = /* GraphQL */ `
  query countEntities($where: EntityWhereInput, $orderBy: [EntityOrderByInput!], $skip: Int, $take: Int) {
    countEntities(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
  }
`;

export const countPeople = /* GraphQL */ `
  query countPeople($where: PersonWhereInput, $orderBy: [PersonOrderByInput!], $skip: Int, $take: Int) {
    countPeople(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
  }
`;

export const listProducts = /* GraphQL */ `
  query listProducts($where: ProductsWhereInput, $orderBy: [ProductsOrderByInput!], $skip: Int, $take: Int) {
    listProducts(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      name
      category
      entity {
        id
        country
        name
        entityType
      }
    }
  }
`;

export const countProducts = /* GraphQL */ `
  query countProducts($where: ProductsWhereInput, $orderBy: [ProductsOrderByInput!], $skip: Int, $take: Int) {
    countProducts(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
  }
`;

export const updateProducts = /* GraphQL */ `
  mutation updateProducts($where: ProductsExtendedWhereUniqueInput!, $data: ProductsUpdateInput) {
    updateProducts(where: $where, data: $data) {
      id
    }
  }
`;

export const updateDocument = /* GraphQL */ `
  mutation updateDocument($where: DocumentExtendedWhereUniqueInput!, $data: DocumentUpdateInput) {
    updateDocument(where: $where, data: $data) {
      id
      entityId
      personId
      tradeId
      key
      name
      issueDate
      expiryDate
      bucketName
      documentTemplateId
      documentTemplate {
        id
        name
        category
        documentFields
      }
      records
      createdOn
      modifiedOn
      softDelete
    }
  }
`;
export const updateManyDocument = /* GraphQL */ `
  mutation updateManyDocuments($where: DocumentWhereInput!, $data: DocumentUpdateInput) {
    updateManyDocuments(where: $where, data: $data) {
      count
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query listDocuments($where: DocumentWhereInput, $orderBy: [DocumentOrderByInput!], $skip: Int, $take: Int) {
    listDocuments(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      entityId
      personId
      tradeId
      key
      name
      bucketName
      expiryDate
      issueDate
      documentTemplateId
      documentTemplate {
        id
        name
        category
        documentFields
      }
      records
      createdOn
      modifiedOn
      softDelete
    }
  }
`;
export const listDocuments2 = /* GraphQL */ `
  query listDocuments($where: DocumentWhereInput, $orderBy: [DocumentOrderByInput!], $skip: Int, $take: Int) {
    listDocuments(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
    }
  }
`;

export const deleteDocument = /* GraphQL */ `
  mutation deleteDocument($where: DocumentExtendedWhereUniqueInput!) {
    deleteDocument(where: $where) {
      id
      entityId
      personId
      tradeId
      key
      name
      bucketName
      documentTemplateId
      documentTemplate {
        id
        name
        category
        documentFields
      }
      records
      createdOn
      modifiedOn
      softDelete
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation deletePerson($where: PersonExtendedWhereUniqueInput!) {
    deletePerson(where: $where) {
      id
      entityId
      createdOn
      modifiedOn
    }
  }
`;

export const createDocument = /* GraphQL */ `
  mutation createDocument($data: DocumentCreateInput!) {
    createDocument(data: $data) {
      id
      entityId
      personId
      tradeId
      key
      name
      bucketName
      gcn
      issueDate
      expiryDate
    }
  }
`;

export const createTrade = /* GraphQL */ `
  mutation createTrade($data: TradeCreateInput!) {
    createTrade(data: $data) {
      id
    }
  }
`;

export const createEntity = /* GraphQL */ `
  mutation createEntity($data: EntityCreateInput!) {
    createEntity(data: $data) {
      id
      name
      metaData
      keyContactId
      hubspotId
      isFlunaUser
      pastInteraction
      flunaRegistrationDate
      entityType
      email
      entityType
      phoneNumber
      postCode
      exportedBefore
      supplierProductExportExperience {
        id
        name
      }
      street
      dateIncorporated
      createdOn
      modifiedOn
      country
      companyTaxId
      city
      website
    }
  }
`;

export const createProduct = /* GraphQL */ `
  mutation createProducts($data: ProductsCreateInput!) {
    createProducts(data: $data) {
      id
    }
  }
`;

export const createPerson = /* GraphQL */ `
  mutation createPerson($data: PersonCreateInput!) {
    createPerson(data: $data) {
      id
      entityId
      firstName
      lastName
      gender
      role
      country
      city
      street
      postCode
      dateOfBirth
      email
      phoneNumber
      source
      isFlunaUser
      isVerified
      flunaRegistrationDate
      isDirector
      isShareholder
      createdOn
      modifiedOn
    }
  }
`;

export const listPersons = /* GraphQL */ `
  query listPersons($where: PersonWhereInput, $orderBy: [PersonOrderByInput!], $skip: Int, $take: Int) {
    listPersons(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      entityId
      firstName
      lastName
      gender
      role
      country
      city
      street
      postCode
      dateOfBirth
      email
      phoneNumber
      source
      isFlunaUser
      isVerified
      flunaRegistrationDate
      isDirector
      isShareholder
      createdOn
      modifiedOn
    }
  }
`;

export const listPeople = /* GraphQL */ `
  query listPeople($where: PersonWhereInput, $orderBy: [PersonOrderByInput!], $skip: Int, $take: Int) {
    listPeople(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      entityId
      entity {
        id
        name
        keyContactId
      }
      firstName
      lastName
      gender
      role
      country
      city
      street
      postCode
      dateOfBirth
      email
      phoneNumber
      source
      isFlunaUser
      isVerified
      flunaRegistrationDate
      isDirector
      isShareholder
      documents {
        id
        entityId
        personId
        tradeId
        key
        name
        bucketName
        documentTemplateId
        documentTemplate {
          id
          name
          category
          documentFields
        }
        records
        createdOn
        modifiedOn
      }
    }
  }
`;

export const listTrades = /* GraphQL */ `
  query listTrades($where: TradeWhereInput, $orderBy: [TradeOrderByInput!], $skip: Int, $take: Int) {
    listTrades(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      entityId
      tradeName
      buyerName
      supplierName
      productDescription
      destinationCountry
      destinationPort
      originCountry
      originPort
      quantityPerContainer
      containerType
      marginInputted
      rawMaterialCountry
      rawMaterialCostAmount
      rawMaterialCostCurrency
      rawMaterialCostUnit
      shippingDurationDate
      rawMaterialPaymentTerms
      materialPreparingCostAmount
      materialPreparingCostCurrency
      materialPreparingCostUnit
      logisticsCostAmount
      logisticsCostCurrency
      logisticsCostUnit
      documentationCostAmount
      documentationCostCurrency
      documentationCostUnit
      customsCostAmount
      customsCostCurrency
      customsCostUnit
      onshoreHandlingCostAmount
      onshoreHandlingCostCurrency
      onshoreHandlingCostUnit
      testingCostAmount
      testingCostCurrency
      testingCostUnit
      supplierOverallCostAmount
      supplierOverallCostCurrency
      supplierOverallCostUnit
      supplierOverallCostIncomeTerms
      buyerPriceAmount
      buyerPriceCurrency
      buyerPriceUnit
      buyerQuantityTonnes
      buyerPaymentTerms
      buyerIncomeTerms
      freightCostAmount
      freightCostUnit
      freightCostCurrency
      freightForwarderId
      shippingLine
      shippingDurationDaysEstinmate
      shippingDurationDaysActual
      shippingArrivalDate
      shippingMode
      createdOn
      modifiedOn
    }
  }
`;

export const updateTrade = /* GraphQL */ `
  mutation updateTrade($where: TradeExtendedWhereUniqueInput!, $data: TradeUpdateInput) {
    updateTrade(where: $where, data: $data) {
      id
      entityId
      productDescription
      destinationCountry
      destinationPort
      originCountry
      originPort
      quantityPerContainer
      containerType
      marginInputted
      rawMaterialCountry
      rawMaterialCostAmount
      rawMaterialCostCurrency
      rawMaterialCostUnit
      rawMaterialPaymentTerms
      materialPreparingCostAmount
      materialPreparingCostCurrency
      materialPreparingCostUnit
      logisticsCostAmount
      logisticsCostCurrency
      logisticsCostUnit
      documentationCostAmount
      documentationCostCurrency
      documentationCostUnit
      customsCostAmount
      customsCostCurrency
      customsCostUnit
      onshoreHandlingCostAmount
      onshoreHandlingCostCurrency
      onshoreHandlingCostUnit
      testingCostAmount
      testingCostCurrency
      testingCostUnit
      supplierOverallCostAmount
      supplierOverallCostCurrency
      supplierOverallCostUnit
      supplierOverallCostIncomeTerms
      buyerPriceAmount
      buyerPriceCurrency
      buyerPriceUnit
      buyerQuantityTonnes
      buyerPaymentTerms
      buyerIncomeTerms
      freightCostAmount
      freightCostUnit
      freightCostCurrency
      freightForwarderId
      shippingLine
      shippingDurationDaysEstinmate
      shippingDurationDaysActual
      shippingArrivalDate
      shippingMode
      createdOn
      modifiedOn
    }
  }
`;

export const deleteTrade = /* GraphQL */ `
  mutation deleteTrade($where: TradeExtendedWhereUniqueInput!) {
    deleteTrade(where: $where) {
      id
      entityId
      productDescription
      destinationCountry
      destinationPort
      originCountry
      originPort
      quantityPerContainer
      containerType
      marginInputted
      rawMaterialCountry
      rawMaterialCostAmount
      rawMaterialCostCurrency
      rawMaterialCostUnit
      rawMaterialPaymentTerms
      materialPreparingCostAmount
      materialPreparingCostCurrency
      materialPreparingCostUnit
      logisticsCostAmount
      logisticsCostCurrency
      logisticsCostUnit
      documentationCostAmount
      documentationCostCurrency
      documentationCostUnit
      customsCostAmount
      customsCostCurrency
      customsCostUnit
      onshoreHandlingCostAmount
      onshoreHandlingCostCurrency
      onshoreHandlingCostUnit
      testingCostAmount
      testingCostCurrency
      testingCostUnit
      supplierOverallCostAmount
      supplierOverallCostCurrency
      supplierOverallCostUnit
      supplierOverallCostIncomeTerms
      buyerPriceAmount
      buyerPriceCurrency
      buyerPriceUnit
      buyerQuantityTonnes
      buyerPaymentTerms
      buyerIncomeTerms
      freightCostAmount
      freightCostUnit
      freightCostCurrency
      freightForwarderId
      shippingLine
      shippingDurationDaysEstinmate
      shippingDurationDaysActual
      shippingArrivalDate
      shippingMode
      createdOn
      modifiedOn
    }
  }
`;

export const listCampaigns = /* GraphQL */ `
  query listCampaigns($where: CampaignWhereInput, $orderBy: [CampaignOrderByInput!], $skip: Int, $take: Int) {
    listCampaigns(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      title
      status
      sender
      senderEmail
      mailSubject
      mailBody
      whatsappBody
      productDescription
      formLink
      deadLineDate
      entityType
      seachCompanyLocation
      searchProduct
      searchProductOrigin
      searchCertifications
      campaignMessageStatus {
        id
        campaignId
        personId
        openedMail
        clickedForm
        mailMessageStatus
        whatsappMessageStatus
      }
      persons {
        id
        entityId
        firstName
        lastName
        gender
        role
        country
        city
        street
        postCode
        dateOfBirth
        email
        phoneNumber
        source
        isFlunaUser
        isVerified
        flunaRegistrationDate
        isDirector
        isShareholder
        createdOn
        modifiedOn
        campaignMessageStatus {
          id
          campaignId
          personId
          openedMail
          clickedForm
          mailMessageStatus
          whatsappMessageStatus
        }
        documents {
          id
          entityId
          personId
          tradeId
          key
          name
          issueDate
          expiryDate
          bucketName
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
      }
      createdOn
      modifiedOn
    }
  }
`;

export const createCampaign = /* GraphQL */ `
  mutation createCampaign($data: CampaignCreateInput!) {
    createCampaign(data: $data) {
      id
      title
      entityType
      seachCompanyLocation
      searchProduct
      searchProductOrigin
      searchCertifications
      status
      sender
      senderEmail
      mailSubject
      mailBody
      whatsappBody
      productDescription
      formLink
      deadLineDate
      createdOn
      modifiedOn
      persons {
        id
        entityId
        firstName
        lastName
        gender
        role
        country
        city
        street
        postCode
        dateOfBirth
        email
        phoneNumber
        source
        isFlunaUser
        isVerified
        flunaRegistrationDate
        isDirector
        isShareholder
        createdOn
        modifiedOn
        documents {
          id
          entityId
          personId
          tradeId
          key
          name
          issueDate
          expiryDate
          bucketName
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
      }
    }
  }
`;

export const updateCampaign = /* GraphQL */ `
  mutation updateCampaign($where: CampaignExtendedWhereUniqueInput!, $data: CampaignUpdateInput) {
    updateCampaign(where: $where, data: $data) {
      id
      title
      entityType
      seachCompanyLocation
      searchProduct
      searchProductOrigin
      searchCertifications
      status
      sender
      senderEmail
      mailSubject
      mailBody
      whatsappBody
      productDescription
      formLink
      deadLineDate
      createdOn
      modifiedOn
      persons {
        id
        entityId
        firstName
        lastName
        gender
        role
        country
        city
        street
        postCode
        dateOfBirth
        email
        phoneNumber
        source
        isFlunaUser
        isVerified
        flunaRegistrationDate
        isDirector
        isShareholder
        createdOn
        modifiedOn
        documents {
          id
          entityId
          personId
          tradeId
          key
          name
          issueDate
          expiryDate
          bucketName
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
      }
    }
  }
`;

export const createCampaignMessageStatus = /* GraphQL */ `
  mutation createCampaignMessageStatus($data: CampaignMessageStatusCreateInput!) {
    createCampaignMessageStatus(data: $data) {
      id
      campaignId
      personId
      openedMail
      clickedForm
      token
      mailMessageStatus
      whatsappMessageStatus
    }
  }
`;

export const updateCampaignMessageStatus = /* GraphQL */ `
  mutation updateCampaignMessageStatus($data: CampaignMessageStatusUpdateInput!) {
    updateCampaignMessageStatus(data: $data) {
      id
      campaignId
      personId
      openedMail
      clickedForm
      token
      mailMessageStatus
      whatsappMessageStatus
    }
  }
`;

export const listCampaignsMessageStatus = /* GraphQL */ `
  query listCampaignsMessageStatus($where: CampaignMessageStatusWhereInput, $orderBy: [CampaignMessageStatusOrderByInput!], $skip: Int, $take: Int) {
    listCampaignsMessageStatus(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      campaignId
      personId
      openedMail
      clickedForm
      token
      mailMessageStatus
      whatsappMessageStatus
    }
  }
`;

export const encodeToken = /* GraphQL */ `
  mutation encodeToken($id: String!, $jwtKey: String!, $expiresIn: String!) {
    encodeToken(id: $id, jwtKey: $jwtKey, expiresIn: $expiresIn)
  }
`;
