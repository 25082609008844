import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button, { type ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, IconButton, Typography, SxProps, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HtmlTooltip from "components/HtmlTooltip";

export interface CustomButtonProps extends ButtonProps {
  text: string;
  disabled?: boolean;
  blockAction?: boolean;
  loading?: boolean;
  loadingText?: string;
  sx?: SxProps<Theme> | undefined;
  breakpoint?: Record<"sm" | "md" | "lg" | "xl", number>;
  hover?: boolean;
  variant?: "text" | "outlined" | "contained";
  focusSelected?: boolean;
  iconOnly?: boolean;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  route?: string;
  textColor?: string;
  externalStyle?: React.CSSProperties;
}

const FlunaButton = styled(
  ({
    text,
    disabled,
    blockAction,
    loading,
    sx,
    loadingText,
    hover = true,
    variant = "contained",
    focusSelected,
    iconOnly,
    icon,
    endIcon,
    route,
    textColor,
    breakpoint = { sm: 600, md: 960, lg: 1280, xl: 1920 },
    externalStyle,
    ...rest
  }: CustomButtonProps) => {
    const navigate = useNavigate();

    const [, setButtonState] = useState<"default" | "focused" | "disabled" | "hover">(disabled ? "disabled" : "default");

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      rest.onClick?.(event);

      if (route) {
        navigate(route);
      }
    };

    const backgroundColorMap = {
      default: {
        filled: "#FBA34B",
        text: "transparent",
        outline: "transparent",
      },
      hover: {
        filled: "#FFBC79",
        outline: "#F2F3F7",
        text: "#F5F5FA",
      },
      disabled: "rgba(251, 163, 75, 0.3)",
    };

    const colorMap = {
      disabled: "rgba(61, 30, 1, 0.3)",
      default: "#3D1E01",
    };

    const backgroundColor = variant === "contained" ? backgroundColorMap.default.filled : backgroundColorMap.default.text;

    const color = textColor ?? colorMap.default;

    const getPadding = (size: number) => {
      if (size >= breakpoint.xl) {
        return "1rem 2rem";
      } else if (size >= breakpoint.lg) {
        return "0.8rem 1.5rem";
      } else if (size >= breakpoint.md) {
        return "0.75rem 1rem";
      } else {
        return "0.5rem 0.75rem";
      }
    };

    const handleMouseEnter = () => {
      setButtonState("hover");
    };

    const handleMouseLeave = () => {
      setButtonState(disabled ? "disabled" : "default");
    };

    const handleFocus = () => {
      setButtonState("focused");
    };

    const handleBlur = () => {
      setButtonState(disabled ? "disabled" : "default");
    };
    return (
      <Box>
        {blockAction ? (
          <HtmlTooltip
            title={
              blockAction ? (
                <>
                  <Typography color="inherit">&quot;Not allowed to make this update&quot;</Typography>
                </>
              ) : (
                ""
              )
            }
          >
            <span>
              <Button
                {...rest}
                onClick={handleButtonClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={blockAction || disabled || loading}
                variant={variant}
                sx={{
                  borderRadius: "8px",
                  padding: getPadding(window.innerWidth),
                  fontSize: ".875rem",
                  fontWeight: 500,
                  letterSpacing: 0.5,
                  display: "flex",
                  alignItems: "center",
                  lineHeight: "20px",
                  textTransform: "capitalize",
                  fontFamily: "SF Pro Display Medium",
                  backgroundColor: iconOnly ? "transparent" : backgroundColor,
                  boxShadow: "none",
                  cursor: "pointer",
                  color,
                  border: variant === "outlined" ? "1px solid  #E9EAEF" : "none",
                  ...(hover && {
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: variant === "contained" ? backgroundColorMap.hover.filled : backgroundColorMap.hover.text,
                      ...(iconOnly && {
                        backgroundColor: backgroundColorMap.default.outline,
                        color: "#FFFFFF",
                      }),
                    },
                  }),
                  ...(focusSelected && {
                    "&.Mui-selected": {
                      backgroundColor: backgroundColorMap.hover.filled,
                      border: "2px solid #FDD6AF",
                      boxShadow: "none",
                    },
                  }),
                  ...externalStyle,
                  ...sx,
                }}
                startIcon={!iconOnly && icon}
              >
                {loading ? (
                  <>
                    <CircularProgress size={20} sx={{ marginRight: "0.5rem" }} />
                    <Typography>{loadingText ?? text}</Typography>
                  </>
                ) : iconOnly ? (
                  <IconButton>{icon}</IconButton>
                ) : (
                  text
                )}
              </Button>
            </span>
          </HtmlTooltip>
        ) : (
          <Button
            {...rest}
            onClick={handleButtonClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={blockAction || disabled || loading}
            variant={variant}
            sx={{
              borderRadius: "8px",
              padding: getPadding(window.innerWidth),
              fontSize: ".875rem",
              fontWeight: 500,
              letterSpacing: 0.5,
              display: "flex",
              alignItems: "center",
              lineHeight: "20px",
              textTransform: "capitalize",
              fontFamily: "SF Pro Display Medium",
              backgroundColor: iconOnly ? "transparent" : backgroundColor,
              boxShadow: "none",
              cursor: "pointer",
              color,
              border: variant === "outlined" ? "1px solid  #E9EAEF" : "none",
              ...(hover && {
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: variant === "contained" ? backgroundColorMap.hover.filled : backgroundColorMap.hover.text,
                  ...(iconOnly && {
                    backgroundColor: backgroundColorMap.default.outline,
                    color: "#FFFFFF",
                  }),
                },
              }),
              ...(focusSelected && {
                "&.Mui-selected": {
                  backgroundColor: backgroundColorMap.hover.filled,
                  border: "2px solid #FDD6AF",
                  boxShadow: "none",
                },
              }),
              ...externalStyle,
            }}
            startIcon={!iconOnly && icon}
            endIcon={endIcon}
          >
            {loading ? (
              <>
                <CircularProgress size={20} sx={{ marginRight: "0.5rem" }} />
                <Typography>{loadingText ?? text}</Typography>
              </>
            ) : iconOnly ? (
              <IconButton>{icon}</IconButton>
            ) : (
              text
            )}
          </Button>
        )}
      </Box>
    );
  },
)``;

export default FlunaButton;
