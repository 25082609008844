const countryList = [
  {
    country: "Afghanistan",
    states: [
      "Badakhshan",
      "Badghis",
      "Baghlan",
      "Balkh",
      "Bamyan",
      "Daykundi",
      "Farah",
      "Faryab",
      "Ghazni",
      "Ghōr",
      "Helmand",
      "Herat",
      "Jowzjan",
      "Kabul",
      "Kandahar",
      "Kapisa",
      "Khost",
      "Kunar",
      "Kunduz Province",
      "Laghman",
      "Logar",
      "Nangarhar",
      "Nimruz",
      "Nuristan",
      "Paktia",
      "Paktika",
      "Panjshir",
      "Parwan",
      "Samangan",
      "Sar-e Pol",
      "Takhar",
      "Urozgan",
      "Zabul",
    ],
  },
  {
    country: "Aland Islands",
    states: [],
  },
  {
    country: "Albania",
    states: [
      "Berat County",
      "Berat District",
      "Bulqizë District",
      "Delvinë District",
      "Devoll District",
      "Dibër County",
      "Dibër District",
      "Durrës County",
      "Durrës District",
      "Elbasan County",
      "Fier County",
      "Fier District",
      "Gjirokastër County",
      "Gjirokastër District",
      "Gramsh District",
      "Has District",
      "Kavajë District",
      "Kolonjë District",
      "Korçë County",
      "Korçë District",
      "Krujë District",
      "Kuçovë District",
      "Kukës County",
      "Kukës District",
      "Kurbin District",
      "Lezhë County",
      "Lezhë District",
      "Librazhd District",
      "Lushnjë District",
      "Malësi e Madhe District",
      "Mallakastër District",
      "Mat District",
      "Mirditë District",
      "Peqin District",
      "Përmet District",
      "Pogradec District",
      "Pukë District",
      "Sarandë District",
      "Shkodër County",
      "Shkodër District",
      "Skrapar District",
      "Tepelenë District",
      "Tirana County",
      "Tirana District",
      "Tropojë District",
      "Vlorë County",
      "Vlorë District",
    ],
  },
  {
    country: "Algeria",
    states: [
      "Adrar",
      "Aïn Defla",
      "Aïn Témouchent",
      "Algiers",
      "Annaba",
      "Batna",
      "Béchar",
      "Béjaïa",
      "Béni Abbès",
      "Biskra",
      "Blida",
      "Bordj Baji Mokhtar",
      "Bordj Bou Arréridj",
      "Bouïra",
      "Boumerdès",
      "Chlef",
      "Constantine",
      "Djanet",
      "Djelfa",
      "El Bayadh",
      "El M'ghair",
      "El Menia",
      "El Oued",
      "El Tarf",
      "Ghardaïa",
      "Guelma",
      "Illizi",
      "In Guezzam",
      "In Salah",
      "Jijel",
      "Khenchela",
      "Laghouat",
      "M'Sila",
      "Mascara",
      "Médéa",
      "Mila",
      "Mostaganem",
      "Naama",
      "Oran",
      "Ouargla",
      "Ouled Djellal",
      "Oum El Bouaghi",
      "Relizane",
      "Saïda",
      "Sétif",
      "Sidi Bel Abbès",
      "Skikda",
      "Souk Ahras",
      "Tamanghasset",
      "Tébessa",
      "Tiaret",
      "Timimoun",
      "Tindouf",
      "Tipasa",
      "Tissemsilt",
      "Tizi Ouzou",
      "Tlemcen",
      "Touggourt",
    ],
  },
  {
    country: "American Samoa",
    states: [],
  },
  {
    country: "Andorra",
    states: ["Andorra la Vella", "Canillo", "Encamp", "Escaldes-Engordany", "La Massana", "Ordino", "Sant Julià de Lòria"],
  },
  {
    country: "Angola",
    states: [
      "Bengo Province",
      "Benguela Province",
      "Bié Province",
      "Cabinda Province",
      "Cuando Cubango Province",
      "Cuanza Norte Province",
      "Cuanza Sul",
      "Cunene Province",
      "Huambo Province",
      "Huíla Province",
      "Luanda Province",
      "Lunda Norte Province",
      "Lunda Sul Province",
      "Malanje Province",
      "Moxico Province",
      "Uíge Province",
      "Zaire Province",
    ],
  },
  {
    country: "Anguilla",
    states: [],
  },
  {
    country: "Antarctica",
    states: [],
  },
  {
    country: "Antigua And Barbuda",
    states: [
      "Barbuda",
      "Redonda",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Mary Parish",
      "Saint Paul Parish",
      "Saint Peter Parish",
      "Saint Philip Parish",
    ],
  },
  {
    country: "Argentina",
    states: [
      "Buenos Aires",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Ciudad Autónoma de Buenos Aires",
      "Córdoba",
      "Corrientes",
      "Entre Ríos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquén",
      "Río Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucumán",
    ],
  },
  {
    country: "Armenia",
    states: [
      "Aragatsotn Region",
      "Ararat Province",
      "Armavir Region",
      "Gegharkunik Province",
      "Kotayk Region",
      "Lori Region",
      "Shirak Region",
      "Syunik Province",
      "Tavush Region",
      "Vayots Dzor Region",
      "Yerevan",
    ],
  },
  {
    country: "Aruba",
    states: [],
  },
  {
    country: "Australia",
    states: [
      "Australian Capital Territory",
      "New South Wales",
      "Northern Territory",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Victoria",
      "Western Australia",
    ],
  },
  {
    country: "Austria",
    states: ["Burgenland", "Carinthia", "Lower Austria", "Salzburg", "Styria", "Tyrol", "Upper Austria", "Vienna", "Vorarlberg"],
  },
  {
    country: "Azerbaijan",
    states: [
      "Absheron District",
      "Agdam District",
      "Agdash District",
      "Aghjabadi District",
      "Agstafa District",
      "Agsu District",
      "Astara District",
      "Babek District",
      "Baku",
      "Balakan District",
      "Barda District",
      "Beylagan District",
      "Bilasuvar District",
      "Dashkasan District",
      "Fizuli District",
      "Ganja",
      "Gədəbəy",
      "Gobustan District",
      "Goranboy District",
      "Goychay",
      "Goygol District",
      "Hajigabul District",
      "Imishli District",
      "Ismailli District",
      "Jabrayil District",
      "Jalilabad District",
      "Julfa District",
      "Kalbajar District",
      "Kangarli District",
      "Khachmaz District",
      "Khizi District",
      "Khojali District",
      "Kurdamir District",
      "Lachin District",
      "Lankaran",
      "Lankaran District",
      "Lerik District",
      "Martuni",
      "Masally District",
      "Mingachevir",
      "Nakhchivan Autonomous Republic",
      "Neftchala District",
      "Oghuz District",
      "Ordubad District",
      "Qabala District",
      "Qakh District",
      "Qazakh District",
      "Quba District",
      "Qubadli District",
      "Qusar District",
      "Saatly District",
      "Sabirabad District",
      "Sadarak District",
      "Salyan District",
      "Samukh District",
      "Shabran District",
      "Shahbuz District",
      "Shaki",
      "Shaki District",
      "Shamakhi District",
      "Shamkir District",
      "Sharur District",
      "Shirvan",
      "Shusha District",
      "Siazan District",
      "Sumqayit",
      "Tartar District",
      "Tovuz District",
      "Ujar District",
      "Yardymli District",
      "Yevlakh",
      "Yevlakh District",
      "Zangilan District",
      "Zaqatala District",
      "Zardab District",
    ],
  },
  {
    country: "Bahrain",
    states: ["Capital Governorate", "Central Governorate", "Muharraq Governorate", "Northern Governorate", "Southern Governorate"],
  },
  {
    country: "Bangladesh",
    states: [
      "Bagerhat District",
      "Bahadia",
      "Bandarban District",
      "Barguna District",
      "Barisal District",
      "Barisal Division",
      "Bhola District",
      "Bogra District",
      "Brahmanbaria District",
      "Chandpur District",
      "Chapai Nawabganj District",
      "Chittagong District",
      "Chittagong Division",
      "Chuadanga District",
      "Comilla District",
      "Cox's Bazar District",
      "Dhaka District",
      "Dhaka Division",
      "Dinajpur District",
      "Faridpur District",
      "Feni District",
      "Gaibandha District",
      "Gazipur District",
      "Gopalganj District",
      "Habiganj District",
      "Jamalpur District",
      "Jessore District",
      "Jhalokati District",
      "Jhenaidah District",
      "Joypurhat District",
      "Khagrachari District",
      "Khulna District",
      "Khulna Division",
      "Kishoreganj District",
      "Kurigram District",
      "Kushtia District",
      "Lakshmipur District",
      "Lalmonirhat District",
      "Madaripur District",
      "Meherpur District",
      "Moulvibazar District",
      "Munshiganj District",
      "Mymensingh District",
      "Mymensingh Division",
      "Naogaon District",
      "Narail District",
      "Narayanganj District",
      "Natore District",
      "Netrokona District",
      "Nilphamari District",
      "Noakhali District",
      "Pabna District",
      "Panchagarh District",
      "Patuakhali District",
      "Pirojpur District",
      "Rajbari District",
      "Rajshahi District",
      "Rajshahi Division",
      "Rangamati Hill District",
      "Rangpur District",
      "Rangpur Division",
      "Satkhira District",
      "Shariatpur District",
      "Sherpur District",
      "Sirajganj District",
      "Sunamganj District",
      "Sylhet District",
      "Sylhet Division",
      "Tangail District",
      "Thakurgaon District",
    ],
  },
  {
    country: "Barbados",
    states: [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas",
    ],
  },
  {
    country: "Belarus",
    states: ["Brest Region", "Gomel Region", "Grodno Region", "Minsk", "Minsk Region", "Mogilev Region", "Vitebsk Region"],
  },
  {
    country: "Belgium",
    states: [
      "Antwerp",
      "Brussels-Capital Region",
      "East Flanders",
      "Flanders",
      "Flemish Brabant",
      "Hainaut",
      "Liège",
      "Limburg",
      "Luxembourg",
      "Namur",
      "Wallonia",
      "Walloon Brabant",
      "West Flanders",
    ],
  },
  {
    country: "Belize",
    states: ["Belize District", "Cayo District", "Corozal District", "Orange Walk District", "Stann Creek District", "Toledo District"],
  },
  {
    country: "Benin",
    states: [
      "Alibori Department",
      "Atakora Department",
      "Atlantique Department",
      "Borgou Department",
      "Collines Department",
      "Donga Department",
      "Kouffo Department",
      "Littoral Department",
      "Mono Department",
      "Ouémé Department",
      "Plateau Department",
      "Zou Department",
    ],
  },
  {
    country: "Bermuda",
    states: [
      "Devonshire Parish",
      "Hamilton Parish",
      "Paget Parish",
      "Pembroke Parish",
      "Saint George's Parish",
      "Sandys Parish",
      "Smith's Parish,",
      "Southampton Parish",
      "Warwick Parish",
    ],
  },
  {
    country: "Bhutan",
    states: [
      "Bumthang District",
      "Chukha District",
      "Dagana District",
      "Gasa District",
      "Haa District",
      "Lhuntse District",
      "Mongar District",
      "Paro District",
      "Pemagatshel District",
      "Punakha District",
      "Samdrup Jongkhar District",
      "Samtse District",
      "Sarpang District",
      "Thimphu District",
      "Trashigang District",
      "Trongsa District",
      "Tsirang District",
      "Wangdue Phodrang District",
      "Zhemgang District",
    ],
  },
  {
    country: "Bolivia",
    states: [
      "Beni Department",
      "Chuquisaca Department",
      "Cochabamba Department",
      "La Paz Department",
      "Oruro Department",
      "Pando Department",
      "Potosí Department",
      "Santa Cruz Department",
      "Tarija Department",
    ],
  },
  {
    country: "Bonaire, Sint Eustatius and Saba",
    states: ["Bonaire", "Saba", "Sint Eustatius"],
  },
  {
    country: "Bosnia and Herzegovina",
    states: [
      "Bosnian Podrinje Canton",
      "Brčko District",
      "Canton 10",
      "Central Bosnia Canton",
      "Federation of Bosnia and Herzegovina",
      "Herzegovina-Neretva Canton",
      "Posavina Canton",
      "Republika Srpska",
      "Sarajevo Canton",
      "Tuzla Canton",
      "Una-Sana Canton",
      "West Herzegovina Canton",
      "Zenica-Doboj Canton",
    ],
  },
  {
    country: "Botswana",
    states: [
      "Central District",
      "Ghanzi District",
      "Kgalagadi District",
      "Kgatleng District",
      "Kweneng District",
      "Ngamiland",
      "North-East District",
      "North-West District",
      "South-East District",
      "Southern District",
    ],
  },
  {
    country: "Bouvet Island",
    states: [],
  },
  {
    country: "Brazil",
    states: [
      "Acre",
      "Alagoas",
      "Amapá",
      "Amazonas",
      "Bahia",
      "Ceará",
      "Distrito Federal",
      "Espírito Santo",
      "Goiás",
      "Maranhão",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Pará",
      "Paraíba",
      "Paraná",
      "Pernambuco",
      "Piauí",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondônia",
      "Roraima",
      "Santa Catarina",
      "São Paulo",
      "Sergipe",
      "Tocantins",
    ],
  },
  {
    country: "British Indian Ocean Territory",
    states: [],
  },
  {
    country: "Brunei",
    states: ["Belait District", "Brunei-Muara District", "Temburong District", "Tutong District"],
  },
  {
    country: "Bulgaria",
    states: [
      "Blagoevgrad Province",
      "Burgas Province",
      "Dobrich Province",
      "Gabrovo Province",
      "Haskovo Province",
      "Kardzhali Province",
      "Kyustendil Province",
      "Lovech Province",
      "Montana Province",
      "Pazardzhik Province",
      "Pernik Province",
      "Pleven Province",
      "Plovdiv Province",
      "Razgrad Province",
      "Ruse Province",
      "Shumen",
      "Silistra Province",
      "Sliven Province",
      "Smolyan Province",
      "Sofia City Province",
      "Sofia Province",
      "Stara Zagora Province",
      "Targovishte Province",
      "Varna Province",
      "Veliko Tarnovo Province",
      "Vidin Province",
      "Vratsa Province",
      "Yambol Province",
    ],
  },
  {
    country: "Burkina Faso",
    states: [
      "Balé Province",
      "Bam Province",
      "Banwa Province",
      "Bazèga Province",
      "Boucle du Mouhoun Region",
      "Bougouriba Province",
      "Boulgou",
      "Cascades Region",
      "Centre",
      "Centre-Est Region",
      "Centre-Nord Region",
      "Centre-Ouest Region",
      "Centre-Sud Region",
      "Comoé Province",
      "Est Region",
      "Ganzourgou Province",
      "Gnagna Province",
      "Gourma Province",
      "Hauts-Bassins Region",
      "Houet Province",
      "Ioba Province",
      "Kadiogo Province",
      "Kénédougou Province",
      "Komondjari Province",
      "Kompienga Province",
      "Kossi Province",
      "Koulpélogo Province",
      "Kouritenga Province",
      "Kourwéogo Province",
      "Léraba Province",
      "Loroum Province",
      "Mouhoun",
      "Nahouri Province",
      "Namentenga Province",
      "Nayala Province",
      "Nord Region, Burkina Faso",
      "Noumbiel Province",
      "Oubritenga Province",
      "Oudalan Province",
      "Passoré Province",
      "Plateau-Central Region",
      "Poni Province",
      "Sahel Region",
      "Sanguié Province",
      "Sanmatenga Province",
      "Séno Province",
      "Sissili Province",
      "Soum Province",
      "Sourou Province",
      "Sud-Ouest Region",
      "Tapoa Province",
      "Tuy Province",
      "Yagha Province",
      "Yatenga Province",
      "Ziro Province",
      "Zondoma Province",
      "Zoundwéogo Province",
    ],
  },
  {
    country: "Burundi",
    states: [
      "Bubanza Province",
      "Bujumbura Mairie Province",
      "Bujumbura Rural Province",
      "Bururi Province",
      "Cankuzo Province",
      "Cibitoke Province",
      "Gitega Province",
      "Karuzi Province",
      "Kayanza Province",
      "Kirundo Province",
      "Makamba Province",
      "Muramvya Province",
      "Muyinga Province",
      "Mwaro Province",
      "Ngozi Province",
      "Rumonge Province",
      "Rutana Province",
      "Ruyigi Province",
    ],
  },
  {
    country: "Cambodia",
    states: [
      "Banteay Meanchey",
      "Battambang",
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Speu",
      "Kampong Thom",
      "Kampot",
      "Kandal",
      "Kep",
      "Koh Kong",
      "Kratie",
      "Mondulkiri",
      "Oddar Meanchey",
      "Pailin",
      "Phnom Penh",
      "Preah Vihear",
      "Prey Veng",
      "Pursat",
      "Ratanakiri",
      "Siem Reap",
      "Sihanoukville",
      "Stung Treng",
      "Svay Rieng",
      "Takeo",
    ],
  },
  {
    country: "Cameroon",
    states: ["Adamawa", "Centre", "East", "Far North", "Littoral", "North", "Northwest", "South", "Southwest", "West"],
  },
  {
    country: "Canada",
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],
  },
  {
    country: "Cape Verde",
    states: [
      "Barlavento Islands",
      "Boa Vista",
      "Brava",
      "Maio Municipality",
      "Mosteiros",
      "Paul",
      "Porto Novo",
      "Praia",
      "Ribeira Brava Municipality",
      "Ribeira Grande",
      "Ribeira Grande de Santiago",
      "Sal",
      "Santa Catarina",
      "Santa Catarina do Fogo",
      "Santa Cruz",
      "São Domingos",
      "São Filipe",
      "São Lourenço dos Órgãos",
      "São Miguel",
      "São Vicente",
      "Sotavento Islands",
      "Tarrafal",
      "Tarrafal de São Nicolau",
    ],
  },
  {
    country: "Cayman Islands",
    states: [],
  },
  {
    country: "Central African Republic",
    states: [
      "Bamingui-Bangoran Prefecture",
      "Bangui",
      "Basse-Kotto Prefecture",
      "Haut-Mbomou Prefecture",
      "Haute-Kotto Prefecture",
      "Kémo Prefecture",
      "Lobaye Prefecture",
      "Mambéré-Kadéï",
      "Mbomou Prefecture",
      "Nana-Grébizi Economic Prefecture",
      "Nana-Mambéré Prefecture",
      "Ombella-M'Poko Prefecture",
      "Ouaka Prefecture",
      "Ouham Prefecture",
      "Ouham-Pendé Prefecture",
      "Sangha-Mbaéré",
      "Vakaga Prefecture",
    ],
  },
  {
    country: "Chad",
    states: [
      "Bahr el Gazel",
      "Batha Region",
      "Borkou",
      "Ennedi Region",
      "Ennedi-Est",
      "Ennedi-Ouest",
      "Guéra Region",
      "Hadjer-Lamis",
      "Kanem Region",
      "Lac Region",
      "Logone Occidental Region",
      "Logone Oriental Region",
      "Mandoul Region",
      "Mayo-Kebbi Est Region",
      "Mayo-Kebbi Ouest Region",
      "Moyen-Chari Region",
      "N'Djamena",
      "Ouaddaï Region",
      "Salamat Region",
      "Sila Region",
      "Tandjilé Region",
      "Tibesti Region",
      "Wadi Fira Region",
    ],
  },
  {
    country: "Chile",
    states: [
      "Aisén del General Carlos Ibañez del Campo",
      "Antofagasta",
      "Arica y Parinacota",
      "Atacama",
      "Biobío",
      "Coquimbo",
      "La Araucanía",
      "Libertador General Bernardo O'Higgins",
      "Los Lagos",
      "Los Ríos",
      "Magallanes y de la Antártica Chilena",
      "Maule",
      "Ñuble",
      "Región Metropolitana de Santiago",
      "Tarapacá",
      "Valparaíso",
    ],
  },
  {
    country: "China",
    states: [
      "Anhui",
      "Beijing",
      "Chongqing",
      "Fujian",
      "Gansu",
      "Guangdong",
      "Guangxi Zhuang",
      "Guizhou",
      "Hainan",
      "Hebei",
      "Heilongjiang",
      "Henan",
      "Hong Kong SAR",
      "Hubei",
      "Hunan",
      "Inner Mongolia",
      "Jiangsu",
      "Jiangxi",
      "Jilin",
      "Liaoning",
      "Macau SAR",
      "Ningxia Huizu",
      "Qinghai",
      "Shaanxi",
      "Shandong",
      "Shanghai",
      "Shanxi",
      "Sichuan",
      "Taiwan",
      "Tianjin",
      "Xinjiang",
      "Xizang",
      "Yunnan",
      "Zhejiang",
    ],
  },
  {
    country: "Christmas Island",
    states: [],
  },
  {
    country: "Cocos (Keeling) Islands",
    states: [],
  },
  {
    country: "Colombia",
    states: [
      "Amazonas",
      "Antioquia",
      "Arauca",
      "Archipiélago de San Andrés, Providencia y Santa Catalina",
      "Atlántico",
      "Bogotá D.C.",
      "Bolívar",
      "Boyacá",
      "Caldas",
      "Caquetá",
      "Casanare",
      "Cauca",
      "Cesar",
      "Chocó",
      "Córdoba",
      "Cundinamarca",
      "Guainía",
      "Guaviare",
      "Huila",
      "La Guajira",
      "Magdalena",
      "Meta",
      "Nariño",
      "Norte de Santander",
      "Putumayo",
      "Quindío",
      "Risaralda",
      "Santander",
      "Sucre",
      "Tolima",
      "Valle del Cauca",
      "Vaupés",
      "Vichada",
    ],
  },
  {
    country: "Comoros",
    states: ["Anjouan", "Grande Comore", "Mohéli"],
  },
  {
    country: "Congo",
    states: [
      "Bouenza Department",
      "Brazzaville",
      "Cuvette Department",
      "Cuvette-Ouest Department",
      "Kouilou Department",
      "Lékoumou Department",
      "Likouala Department",
      "Niari Department",
      "Plateaux Department",
      "Pointe-Noire",
      "Pool Department",
      "Sangha Department",
    ],
  },
  {
    country: "Cook Islands",
    states: [],
  },
  {
    country: "Costa Rica",
    states: [
      "Alajuela Province",
      "Guanacaste Province",
      "Heredia Province",
      "Limón Province",
      "Provincia de Cartago",
      "Puntarenas Province",
      "San José Province",
    ],
  },
  {
    country: "Cote D'Ivoire (Ivory Coast)",
    states: [
      "Abidjan",
      "Agnéby",
      "Bafing Region",
      "Bas-Sassandra District",
      "Bas-Sassandra Region",
      "Comoé District",
      "Denguélé District",
      "Denguélé Region",
      "Dix-Huit Montagnes",
      "Fromager",
      "Gôh-Djiboua District",
      "Haut-Sassandra",
      "Lacs District",
      "Lacs Region",
      "Lagunes District",
      "Lagunes region",
      "Marahoué Region",
      "Montagnes District",
      "Moyen-Cavally",
      "Moyen-Comoé",
      "N'zi-Comoé",
      "Sassandra-Marahoué District",
      "Savanes Region",
      "Sud-Bandama",
      "Sud-Comoé",
      "Vallée du Bandama District",
      "Vallée du Bandama Region",
      "Woroba District",
      "Worodougou",
      "Yamoussoukro",
      "Zanzan Region",
    ],
  },
  {
    country: "Croatia",
    states: [
      "Bjelovar-Bilogora",
      "Brod-Posavina",
      "Dubrovnik-Neretva",
      "Istria",
      "Karlovac",
      "Koprivnica-Križevci",
      "Krapina-Zagorje",
      "Lika-Senj",
      "Međimurje",
      "Osijek-Baranja",
      "Požega-Slavonia",
      "Primorje-Gorski Kotar",
      "Šibenik-Knin",
      "Sisak-Moslavina",
      "Split-Dalmatia",
      "Varaždin",
      "Virovitica-Podravina",
      "Vukovar-Syrmia",
      "Zadar",
      "Zagreb",
      "Zagreb",
    ],
  },
  {
    country: "Cuba",
    states: [
      "Artemisa Province",
      "Camagüey Province",
      "Ciego de Ávila Province",
      "Cienfuegos Province",
      "Granma Province",
      "Guantánamo Province",
      "Havana Province",
      "Holguín Province",
      "Isla de la Juventud",
      "Las Tunas Province",
      "Matanzas Province",
      "Mayabeque Province",
      "Pinar del Río Province",
      "Sancti Spíritus Province",
      "Santiago de Cuba Province",
      "Villa Clara Province",
    ],
  },
  {
    country: "Curaçao",
    states: [],
  },
  {
    country: "Cyprus",
    states: [
      "Famagusta District (Mağusa)",
      "Kyrenia District (Keryneia)",
      "Larnaca District (Larnaka)",
      "Limassol District (Leymasun)",
      "Nicosia District (Lefkoşa)",
      "Paphos District (Pafos)",
    ],
  },
  {
    country: "Czech Republic",
    states: [
      "Benešov",
      "Beroun",
      "Blansko",
      "Břeclav",
      "Brno-město",
      "Brno-venkov",
      "Bruntál",
      "Česká Lípa",
      "České Budějovice",
      "Český Krumlov",
      "Cheb",
      "Chomutov",
      "Chrudim",
      "Děčín",
      "Domažlice",
      "Frýdek-Místek",
      "Havlíčkův Brod",
      "Hodonín",
      "Hradec Králové",
      "Jablonec nad Nisou",
      "Jeseník",
      "Jičín",
      "Jihlava",
      "Jihočeský kraj",
      "Jihomoravský kraj",
      "Jindřichův Hradec",
      "Karlovarský kraj",
      "Karlovy Vary",
      "Karviná",
      "Kladno",
      "Klatovy",
      "Kolín",
      "Kraj Vysočina",
      "Královéhradecký kraj",
      "Kroměříž",
      "Kutná Hora",
      "Liberec",
      "Liberecký kraj",
      "Litoměřice",
      "Louny",
      "Mělník",
      "Mladá Boleslav",
      "Moravskoslezský kraj",
      "Most",
      "Náchod",
      "Nový Jičín",
      "Nymburk",
      "Olomouc",
      "Olomoucký kraj",
      "Opava",
      "Ostrava-město",
      "Pardubice",
      "Pardubický kraj",
      "Pelhřimov",
      "Písek",
      "Plzeň-jih",
      "Plzeň-město",
      "Plzeň-sever",
      "Plzeňský kraj",
      "Prachatice",
      "Praha-východ",
      "Praha-západ",
      "Praha, Hlavní město",
      "Přerov",
      "Příbram",
      "Prostějov",
      "Rakovník",
      "Rokycany",
      "Rychnov nad Kněžnou",
      "Semily",
      "Sokolov",
      "Strakonice",
      "Středočeský kraj",
      "Šumperk",
      "Svitavy",
      "Tábor",
      "Tachov",
      "Teplice",
      "Třebíč",
      "Trutnov",
      "Uherské Hradiště",
      "Ústecký kraj",
      "Ústí nad Labem",
      "Ústí nad Orlicí",
      "Vsetín",
      "Vyškov",
      "Žďár nad Sázavou",
      "Zlín",
      "Zlínský kraj",
      "Znojmo",
    ],
  },
  {
    country: "Democratic Republic of the Congo",
    states: [
      "Bas-Uélé",
      "Équateur",
      "Haut-Katanga",
      "Haut-Lomami",
      "Haut-Uélé",
      "Ituri",
      "Kasaï",
      "Kasaï Central",
      "Kasaï Oriental",
      "Kinshasa",
      "Kongo Central",
      "Kwango",
      "Kwilu",
      "Lomami",
      "Lualaba",
      "Mai-Ndombe",
      "Maniema",
      "Mongala",
      "Nord-Kivu",
      "Nord-Ubangi",
      "Sankuru",
      "Sud-Kivu",
      "Sud-Ubangi",
      "Tanganyika",
      "Tshopo",
      "Tshuapa",
    ],
  },
  {
    country: "Denmark",
    states: ["Capital Region of Denmark", "Central Denmark Region", "North Denmark Region", "Region of Southern Denmark", "Region Zealand"],
  },
  {
    country: "Djibouti",
    states: ["Ali Sabieh Region", "Arta Region", "Dikhil Region", "Djibouti", "Obock Region", "Tadjourah Region"],
  },
  {
    country: "Dominica",
    states: [
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Joseph Parish",
      "Saint Luke Parish",
      "Saint Mark Parish",
      "Saint Patrick Parish",
      "Saint Paul Parish",
      "Saint Peter Parish",
    ],
  },
  {
    country: "Dominican Republic",
    states: [
      "Azua Province",
      "Baoruco Province",
      "Barahona Province",
      "Dajabón Province",
      "Distrito Nacional",
      "Duarte Province",
      "El Seibo Province",
      "Espaillat Province",
      "Hato Mayor Province",
      "Hermanas Mirabal Province",
      "Independencia",
      "La Altagracia Province",
      "La Romana Province",
      "La Vega Province",
      "María Trinidad Sánchez Province",
      "Monseñor Nouel Province",
      "Monte Cristi Province",
      "Monte Plata Province",
      "Pedernales Province",
      "Peravia Province",
      "Puerto Plata Province",
      "Samaná Province",
      "San Cristóbal Province",
      "San José de Ocoa Province",
      "San Juan Province",
      "San Pedro de Macorís",
      "Sánchez Ramírez Province",
      "Santiago Province",
      "Santiago Rodríguez Province",
      "Santo Domingo Province",
      "Valverde Province",
    ],
  },
  {
    country: "East Timor",
    states: [
      "Aileu municipality",
      "Ainaro Municipality",
      "Baucau Municipality",
      "Bobonaro Municipality",
      "Cova Lima Municipality",
      "Dili municipality",
      "Ermera District",
      "Lautém Municipality",
      "Liquiçá Municipality",
      "Manatuto District",
      "Manufahi Municipality",
      "Viqueque Municipality",
    ],
  },
  {
    country: "Ecuador",
    states: [
      "Azuay",
      "Bolívar",
      "Cañar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Galápagos",
      "Guayas",
      "Imbabura",
      "Loja",
      "Los Ríos",
      "Manabí",
      "Morona-Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Santa Elena",
      "Santo Domingo de los Tsáchilas",
      "Sucumbíos",
      "Tungurahua",
      "Zamora Chinchipe",
    ],
  },
  {
    country: "Egypt",
    states: [
      "Alexandria",
      "Aswan",
      "Asyut",
      "Beheira",
      "Beni Suef",
      "Cairo",
      "Dakahlia",
      "Damietta",
      "Faiyum",
      "Gharbia",
      "Giza",
      "Ismailia",
      "Kafr el-Sheikh",
      "Luxor",
      "Matrouh",
      "Minya",
      "Monufia",
      "New Valley",
      "North Sinai",
      "Port Said",
      "Qalyubia",
      "Qena",
      "Red Sea",
      "Sharqia",
      "Sohag",
      "South Sinai",
      "Suez",
    ],
  },
  {
    country: "El Salvador",
    states: [
      "Ahuachapán Department",
      "Cabañas Department",
      "Chalatenango Department",
      "Cuscatlán Department",
      "La Libertad Department",
      "La Paz Department",
      "La Unión Department",
      "Morazán Department",
      "San Miguel Department",
      "San Salvador Department",
      "San Vicente Department",
      "Santa Ana Department",
      "Sonsonate Department",
      "Usulután Department",
    ],
  },
  {
    country: "Equatorial Guinea",
    states: [
      "Annobón Province",
      "Bioko Norte Province",
      "Bioko Sur Province",
      "Centro Sur Province",
      "Insular Region",
      "Kié-Ntem Province",
      "Litoral Province",
      "Río Muni",
      "Wele-Nzas Province",
    ],
  },
  {
    country: "Eritrea",
    states: ["Anseba Region", "Debub Region", "Gash-Barka Region", "Maekel Region", "Northern Red Sea Region", "Southern Red Sea Region"],
  },
  {
    country: "Estonia",
    states: [
      "Harju County",
      "Hiiu County",
      "Ida-Viru County",
      "Järva County",
      "Jõgeva County",
      "Lääne County",
      "Lääne-Viru County",
      "Pärnu County",
      "Põlva County",
      "Rapla County",
      "Saare County",
      "Tartu County",
      "Valga County",
      "Viljandi County",
      "Võru County",
    ],
  },
  {
    country: "Ethiopia",
    states: [
      "Addis Ababa",
      "Afar Region",
      "Amhara Region",
      "Benishangul-Gumuz Region",
      "Dire Dawa",
      "Gambela Region",
      "Harari Region",
      "Oromia Region",
      "Somali Region",
      "Southern Nations, Nationalities, and Peoples' Region",
      "Tigray Region",
    ],
  },
  {
    country: "Falkland Islands",
    states: [],
  },
  {
    country: "Faroe Islands",
    states: [],
  },
  {
    country: "Fiji Islands",
    states: [
      "Ba",
      "Bua",
      "Cakaudrove",
      "Central Division",
      "Eastern Division",
      "Kadavu",
      "Lau",
      "Lomaiviti",
      "Macuata",
      "Nadroga-Navosa",
      "Naitasiri",
      "Namosi",
      "Northern Division",
      "Ra",
      "Rewa",
      "Rotuma",
      "Serua",
      "Tailevu",
      "Western Division",
    ],
  },
  {
    country: "Finland",
    states: [
      "Åland Islands",
      "Central Finland",
      "Central Ostrobothnia",
      "Eastern Finland Province",
      "Finland Proper",
      "Kainuu",
      "Kymenlaakso",
      "Lapland",
      "North Karelia",
      "Northern Ostrobothnia",
      "Northern Savonia",
      "Ostrobothnia",
      "Oulu Province",
      "Päijänne Tavastia",
      "Pirkanmaa",
      "Satakunta",
      "South Karelia",
      "Southern Ostrobothnia",
      "Southern Savonia",
      "Tavastia Proper",
      "Uusimaa",
    ],
  },
  {
    country: "France",
    states: [
      "Ain",
      "Aisne",
      "Allier",
      "Alpes-de-Haute-Provence",
      "Alpes-Maritimes",
      "Alsace",
      "Ardèche",
      "Ardennes",
      "Ariège",
      "Aube",
      "Aude",
      "Auvergne-Rhône-Alpes",
      "Aveyron",
      "Bas-Rhin",
      "Bouches-du-Rhône",
      "Bourgogne-Franche-Comté",
      "Bretagne",
      "Calvados",
      "Cantal",
      "Centre-Val de Loire",
      "Charente",
      "Charente-Maritime",
      "Cher",
      "Clipperton",
      "Corrèze",
      "Corse",
      "Corse-du-Sud",
      "Côte-d'Or",
      "Côtes-d'Armor",
      "Creuse",
      "Deux-Sèvres",
      "Dordogne",
      "Doubs",
      "Drôme",
      "Essonne",
      "Eure",
      "Eure-et-Loir",
      "Finistère",
      "French Guiana",
      "French Polynesia",
      "French Southern and Antarctic Lands",
      "Gard",
      "Gers",
      "Gironde",
      "Grand-Est",
      "Guadeloupe",
      "Haut-Rhin",
      "Haute-Corse",
      "Haute-Garonne",
      "Haute-Loire",
      "Haute-Marne",
      "Haute-Saône",
      "Haute-Savoie",
      "Haute-Vienne",
      "Hautes-Alpes",
      "Hautes-Pyrénées",
      "Hauts-de-France",
      "Hauts-de-Seine",
      "Hérault",
      "Île-de-France",
      "Ille-et-Vilaine",
      "Indre",
      "Indre-et-Loire",
      "Isère",
      "Jura",
      "La Réunion",
      "Landes",
      "Loir-et-Cher",
      "Loire",
      "Loire-Atlantique",
      "Loiret",
      "Lot",
      "Lot-et-Garonne",
      "Lozère",
      "Maine-et-Loire",
      "Manche",
      "Marne",
      "Martinique",
      "Mayenne",
      "Mayotte",
      "Métropole de Lyon",
      "Meurthe-et-Moselle",
      "Meuse",
      "Morbihan",
      "Moselle",
      "Nièvre",
      "Nord",
      "Normandie",
      "Nouvelle-Aquitaine",
      "Occitanie",
      "Oise",
      "Orne",
      "Paris",
      "Pas-de-Calais",
      "Pays-de-la-Loire",
      "Provence-Alpes-Côte-d’Azur",
      "Puy-de-Dôme",
      "Pyrénées-Atlantiques",
      "Pyrénées-Orientales",
      "Rhône",
      "Saint Pierre and Miquelon",
      "Saint-Barthélemy",
      "Saint-Martin",
      "Saône-et-Loire",
      "Sarthe",
      "Savoie",
      "Seine-et-Marne",
      "Seine-Maritime",
      "Seine-Saint-Denis",
      "Somme",
      "Tarn",
      "Tarn-et-Garonne",
      "Territoire de Belfort",
      "Val-d'Oise",
      "Val-de-Marne",
      "Var",
      "Vaucluse",
      "Vendée",
      "Vienne",
      "Vosges",
      "Wallis and Futuna",
      "Yonne",
      "Yvelines",
    ],
  },
  {
    country: "French Guiana",
    states: [],
  },
  {
    country: "French Polynesia",
    states: [],
  },
  {
    country: "French Southern Territories",
    states: [],
  },
  {
    country: "Gabon",
    states: [
      "Estuaire Province",
      "Haut-Ogooué Province",
      "Moyen-Ogooué Province",
      "Ngounié Province",
      "Nyanga Province",
      "Ogooué-Ivindo Province",
      "Ogooué-Lolo Province",
      "Ogooué-Maritime Province",
      "Woleu-Ntem Province",
    ],
  },
  {
    country: "Gambia The",
    states: ["Banjul", "Central River Division", "Lower River Division", "North Bank Division", "Upper River Division", "West Coast Division"],
  },
  {
    country: "Georgia",
    states: [
      "Adjara",
      "Autonomous Republic of Abkhazia",
      "Guria",
      "Imereti",
      "Kakheti",
      "Khelvachauri Municipality",
      "Kvemo Kartli",
      "Mtskheta-Mtianeti",
      "Racha-Lechkhumi and Kvemo Svaneti",
      "Samegrelo-Zemo Svaneti",
      "Samtskhe-Javakheti",
      "Senaki Municipality",
      "Shida Kartli",
      "Tbilisi",
    ],
  },
  {
    country: "Germany",
    states: [
      "Baden-Württemberg",
      "Bavaria",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Hamburg",
      "Hesse",
      "Lower Saxony",
      "Mecklenburg-Vorpommern",
      "North Rhine-Westphalia",
      "Rhineland-Palatinate",
      "Saarland",
      "Saxony",
      "Saxony-Anhalt",
      "Schleswig-Holstein",
      "Thuringia",
    ],
  },
  {
    country: "Ghana",
    states: [
      "Ahafo",
      "Ashanti",
      "Bono",
      "Bono East",
      "Central",
      "Eastern",
      "Greater Accra",
      "North East",
      "Northern",
      "Oti",
      "Savannah",
      "Upper East",
      "Upper West",
      "Volta",
      "Western",
      "Western North",
    ],
  },
  {
    country: "Gibraltar",
    states: [],
  },
  {
    country: "Greece",
    states: [
      "Achaea Regional Unit",
      "Aetolia-Acarnania Regional Unit",
      "Arcadia Prefecture",
      "Argolis Regional Unit",
      "Attica Region",
      "Boeotia Regional Unit",
      "Central Greece Region",
      "Central Macedonia",
      "Chania Regional Unit",
      "Corfu Prefecture",
      "Corinthia Regional Unit",
      "Crete Region",
      "Drama Regional Unit",
      "East Attica Regional Unit",
      "East Macedonia and Thrace",
      "Epirus Region",
      "Euboea",
      "Grevena Prefecture",
      "Imathia Regional Unit",
      "Ioannina Regional Unit",
      "Ionian Islands Region",
      "Karditsa Regional Unit",
      "Kastoria Regional Unit",
      "Kefalonia Prefecture",
      "Kilkis Regional Unit",
      "Kozani Prefecture",
      "Laconia",
      "Larissa Prefecture",
      "Lefkada Regional Unit",
      "Pella Regional Unit",
      "Peloponnese Region",
      "Phthiotis Prefecture",
      "Preveza Prefecture",
      "Serres Prefecture",
      "South Aegean",
      "Thessaloniki Regional Unit",
      "West Greece Region",
      "West Macedonia Region",
    ],
  },
  {
    country: "Greenland",
    states: [],
  },
  {
    country: "Grenada",
    states: [
      "Carriacou and Petite Martinique",
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Mark Parish",
      "Saint Patrick Parish",
    ],
  },
  {
    country: "Guadeloupe",
    states: [],
  },
  {
    country: "Guam",
    states: [],
  },
  {
    country: "Guatemala",
    states: [
      "Alta Verapaz Department",
      "Baja Verapaz Department",
      "Chimaltenango Department",
      "Chiquimula Department",
      "El Progreso Department",
      "Escuintla Department",
      "Guatemala Department",
      "Huehuetenango Department",
      "Izabal Department",
      "Jalapa Department",
      "Jutiapa Department",
      "Petén Department",
      "Quetzaltenango Department",
      "Quiché Department",
      "Retalhuleu Department",
      "Sacatepéquez Department",
      "San Marcos Department",
      "Santa Rosa Department",
      "Sololá Department",
      "Suchitepéquez Department",
      "Totonicapán Department",
    ],
  },
  {
    country: "Guernsey and Alderney",
    states: [],
  },
  {
    country: "Guinea",
    states: [
      "Beyla Prefecture",
      "Boffa Prefecture",
      "Boké Prefecture",
      "Boké Region",
      "Conakry",
      "Coyah Prefecture",
      "Dabola Prefecture",
      "Dalaba Prefecture",
      "Dinguiraye Prefecture",
      "Dubréka Prefecture",
      "Faranah Prefecture",
      "Forécariah Prefecture",
      "Fria Prefecture",
      "Gaoual Prefecture",
      "Guéckédou Prefecture",
      "Kankan Prefecture",
      "Kankan Region",
      "Kérouané Prefecture",
      "Kindia Prefecture",
      "Kindia Region",
      "Kissidougou Prefecture",
      "Koubia Prefecture",
      "Koundara Prefecture",
      "Kouroussa Prefecture",
      "Labé Prefecture",
      "Labé Region",
      "Lélouma Prefecture",
      "Lola Prefecture",
      "Macenta Prefecture",
      "Mali Prefecture",
      "Mamou Prefecture",
      "Mamou Region",
      "Mandiana Prefecture",
      "Nzérékoré Prefecture",
      "Nzérékoré Region",
      "Pita Prefecture",
      "Siguiri Prefecture",
      "Télimélé Prefecture",
      "Tougué Prefecture",
      "Yomou Prefecture",
    ],
  },
  {
    country: "Guinea-Bissau",
    states: [
      "Bafatá",
      "Biombo Region",
      "Bolama Region",
      "Cacheu Region",
      "Gabú Region",
      "Leste Province",
      "Norte Province",
      "Oio Region",
      "Quinara Region",
      "Sul Province",
      "Tombali Region",
    ],
  },
  {
    country: "Guyana",
    states: [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo",
    ],
  },
  {
    country: "Haiti",
    states: ["Artibonite", "Centre", "Grand'Anse", "Nippes", "Nord", "Nord-Est", "Nord-Ouest", "Ouest", "Sud", "Sud-Est"],
  },
  {
    country: "Heard Island and McDonald Islands",
    states: [],
  },
  {
    country: "Honduras",
    states: [
      "Atlántida Department",
      "Bay Islands Department",
      "Choluteca Department",
      "Colón Department",
      "Comayagua Department",
      "Copán Department",
      "Cortés Department",
      "El Paraíso Department",
      "Francisco Morazán Department",
      "Gracias a Dios Department",
      "Intibucá Department",
      "La Paz Department",
      "Lempira Department",
      "Ocotepeque Department",
      "Olancho Department",
      "Santa Bárbara Department",
      "Valle Department",
      "Yoro Department",
    ],
  },
  {
    country: "Hong Kong S.A.R.",
    states: [
      "Central and Western District",
      "Eastern",
      "Islands District",
      "Kowloon City",
      "Kwai Tsing",
      "Kwun Tong",
      "North",
      "Sai Kung District",
      "Sha Tin",
      "Sham Shui Po",
      "Southern",
      "Tai Po District",
      "Tsuen Wan District",
      "Tuen Mun",
      "Wan Chai",
      "Wong Tai Sin",
      "Yau Tsim Mong",
      "Yuen Long District",
    ],
  },
  {
    country: "Hungary",
    states: [
      "Bács-Kiskun",
      "Baranya",
      "Békés",
      "Békéscsaba",
      "Borsod-Abaúj-Zemplén",
      "Budapest",
      "Csongrád County",
      "Debrecen",
      "Dunaújváros",
      "Eger",
      "Érd",
      "Fejér County",
      "Győr",
      "Győr-Moson-Sopron County",
      "Hajdú-Bihar County",
      "Heves County",
      "Hódmezővásárhely",
      "Jász-Nagykun-Szolnok County",
      "Kaposvár",
      "Kecskemét",
      "Komárom-Esztergom",
      "Miskolc",
      "Nagykanizsa",
      "Nógrád County",
      "Nyíregyháza",
      "Pécs",
      "Pest County",
      "Salgótarján",
      "Somogy County",
      "Sopron",
      "Szabolcs-Szatmár-Bereg County",
      "Szeged",
      "Székesfehérvár",
      "Szekszárd",
      "Szolnok",
      "Szombathely",
      "Tatabánya",
      "Tolna County",
      "Vas County",
      "Veszprém",
      "Veszprém County",
      "Zala County",
      "Zalaegerszeg",
    ],
  },
  {
    country: "Iceland",
    states: [
      "Capital Region",
      "Eastern Region",
      "Northeastern Region",
      "Northwestern Region",
      "Southern Peninsula Region",
      "Southern Region",
      "Western Region",
      "Westfjords",
    ],
  },
  {
    country: "India",
    states: [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli and Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ],
  },
  {
    country: "Indonesia",
    states: [
      "Aceh",
      "Bali",
      "Banten",
      "Bengkulu",
      "DI Yogyakarta",
      "DKI Jakarta",
      "Gorontalo",
      "Jambi",
      "Jawa Barat",
      "Jawa Tengah",
      "Jawa Timur",
      "Kalimantan Barat",
      "Kalimantan Selatan",
      "Kalimantan Tengah",
      "Kalimantan Timur",
      "Kalimantan Utara",
      "Kepulauan Bangka Belitung",
      "Kepulauan Riau",
      "Lampung",
      "Maluku",
      "Maluku Utara",
      "Nusa Tenggara Barat",
      "Nusa Tenggara Timur",
      "Papua",
      "Papua Barat",
      "Riau",
      "Sulawesi Barat",
      "Sulawesi Selatan",
      "Sulawesi Tengah",
      "Sulawesi Tenggara",
      "Sulawesi Utara",
      "Sumatera Barat",
      "Sumatera Selatan",
      "Sumatera Utara",
    ],
  },
  {
    country: "Iran",
    states: [
      "Alborz",
      "Ardabil",
      "Bushehr",
      "Chaharmahal and Bakhtiari",
      "East Azerbaijan",
      "Fars",
      "Gilan",
      "Golestan",
      "Hamadan",
      "Hormozgan",
      "Ilam",
      "Isfahan",
      "Kerman",
      "Kermanshah",
      "Khuzestan",
      "Kohgiluyeh and Boyer-Ahmad",
      "Kurdistan",
      "Lorestan",
      "Markazi",
      "Mazandaran",
      "North Khorasan",
      "Qazvin",
      "Qom",
      "Razavi Khorasan",
      "Semnan",
      "Sistan and Baluchestan",
      "South Khorasan",
      "Tehran",
      "West Azarbaijan",
      "Yazd",
      "Zanjan",
    ],
  },
  {
    country: "Iraq",
    states: [
      "Al Anbar Governorate",
      "Al Muthanna Governorate",
      "Al-Qādisiyyah Governorate",
      "Babylon Governorate",
      "Baghdad Governorate",
      "Basra Governorate",
      "Dhi Qar Governorate",
      "Diyala Governorate",
      "Dohuk Governorate",
      "Erbil Governorate",
      "Karbala Governorate",
      "Kirkuk Governorate",
      "Maysan Governorate",
      "Najaf Governorate",
      "Nineveh Governorate",
      "Saladin Governorate",
      "Sulaymaniyah Governorate",
      "Wasit Governorate",
    ],
  },
  {
    country: "Ireland",
    states: [
      "Carlow",
      "Cavan",
      "Clare",
      "Connacht",
      "Cork",
      "Donegal",
      "Dublin",
      "Galway",
      "Kerry",
      "Kildare",
      "Kilkenny",
      "Laois",
      "Leinster",
      "Limerick",
      "Longford",
      "Louth",
      "Mayo",
      "Meath",
      "Monaghan",
      "Munster",
      "Offaly",
      "Roscommon",
      "Sligo",
      "Tipperary",
      "Ulster",
      "Waterford",
      "Westmeath",
      "Wexford",
      "Wicklow",
    ],
  },
  {
    country: "Israel",
    states: ["Central District", "Haifa District", "Jerusalem District", "Northern District", "Southern District", "Tel Aviv District"],
  },
  {
    country: "Italy",
    states: [
      "Abruzzo",
      "Agrigento",
      "Alessandria",
      "Ancona",
      "Aosta Valley",
      "Apulia",
      "Ascoli Piceno",
      "Asti",
      "Avellino",
      "Bari",
      "Barletta-Andria-Trani",
      "Basilicata",
      "Belluno",
      "Benevento",
      "Bergamo",
      "Biella",
      "Bologna",
      "Brescia",
      "Brindisi",
      "Cagliari",
      "Calabria",
      "Caltanissetta",
      "Campania",
      "Campobasso",
      "Caserta",
      "Catania",
      "Catanzaro",
      "Chieti",
      "Como",
      "Cosenza",
      "Cremona",
      "Crotone",
      "Cuneo",
      "Emilia-Romagna",
      "Enna",
      "Fermo",
      "Ferrara",
      "Florence",
      "Foggia",
      "Forlì-Cesena",
      "Friuli–Venezia Giulia",
      "Frosinone",
      "Genoa",
      "Gorizia",
      "Grosseto",
      "Imperia",
      "Isernia",
      "L'Aquila",
      "La Spezia",
      "Latina",
      "Lazio",
      "Lecce",
      "Lecco",
      "Liguria",
      "Livorno",
      "Lodi",
      "Lombardy",
      "Lucca",
      "Macerata",
      "Mantua",
      "Marche",
      "Massa and Carrara",
      "Matera",
      "Medio Campidano",
      "Messina",
      "Milan",
      "Modena",
      "Molise",
      "Monza and Brianza",
      "Naples",
      "Novara",
      "Nuoro",
      "Oristano",
      "Padua",
      "Palermo",
      "Parma",
      "Pavia",
      "Perugia",
      "Pesaro and Urbino",
      "Pescara",
      "Piacenza",
      "Piedmont",
      "Pisa",
      "Pistoia",
      "Pordenone",
      "Potenza",
      "Prato",
      "Ragusa",
      "Ravenna",
      "Reggio Calabria",
      "Reggio Emilia",
      "Rieti",
      "Rimini",
      "Rome",
      "Rovigo",
      "Salerno",
      "Sardinia",
      "Sassari",
      "Savona",
      "Sicily",
      "Siena",
      "Siracusa",
      "Sondrio",
      "South Sardinia",
      "South Tyrol",
      "Taranto",
      "Teramo",
      "Terni",
      "Trapani",
      "Trentino",
      "Trentino-South Tyrol",
      "Treviso",
      "Trieste",
      "Turin",
      "Tuscany",
      "Udine",
      "Umbria",
      "Varese",
      "Veneto",
      "Venice",
      "Verbano-Cusio-Ossola",
      "Vercelli",
      "Verona",
      "Vibo Valentia",
      "Vicenza",
      "Viterbo",
    ],
  },
  {
    country: "Jamaica",
    states: [
      "Clarendon Parish",
      "Hanover Parish",
      "Kingston Parish",
      "Manchester Parish",
      "Portland Parish",
      "Saint Andrew",
      "Saint Ann Parish",
      "Saint Catherine Parish",
      "Saint Elizabeth Parish",
      "Saint James Parish",
      "Saint Mary Parish",
      "Saint Thomas Parish",
      "Trelawny Parish",
      "Westmoreland Parish",
    ],
  },
  {
    country: "Japan",
    states: [
      "Aichi Prefecture",
      "Akita Prefecture",
      "Aomori Prefecture",
      "Chiba Prefecture",
      "Ehime Prefecture",
      "Fukui Prefecture",
      "Fukuoka Prefecture",
      "Fukushima Prefecture",
      "Gifu Prefecture",
      "Gunma Prefecture",
      "Hiroshima Prefecture",
      "Hokkaidō Prefecture",
      "Hyōgo Prefecture",
      "Ibaraki Prefecture",
      "Ishikawa Prefecture",
      "Iwate Prefecture",
      "Kagawa Prefecture",
      "Kagoshima Prefecture",
      "Kanagawa Prefecture",
      "Kōchi Prefecture",
      "Kumamoto Prefecture",
      "Kyōto Prefecture",
      "Mie Prefecture",
      "Miyagi Prefecture",
      "Miyazaki Prefecture",
      "Nagano Prefecture",
      "Nagasaki Prefecture",
      "Nara Prefecture",
      "Niigata Prefecture",
      "Ōita Prefecture",
      "Okayama Prefecture",
      "Okinawa Prefecture",
      "Ōsaka Prefecture",
      "Saga Prefecture",
      "Saitama Prefecture",
      "Shiga Prefecture",
      "Shimane Prefecture",
      "Shizuoka Prefecture",
      "Tochigi Prefecture",
      "Tokushima Prefecture",
      "Tokyo",
      "Tottori Prefecture",
      "Toyama Prefecture",
      "Wakayama Prefecture",
      "Yamagata Prefecture",
      "Yamaguchi Prefecture",
      "Yamanashi Prefecture",
    ],
  },
  {
    country: "Jersey",
    states: [],
  },
  {
    country: "Jordan",
    states: [
      "Ajloun Governorate",
      "Amman Governorate",
      "Aqaba Governorate",
      "Balqa Governorate",
      "Irbid Governorate",
      "Jerash Governorate",
      "Karak Governorate",
      "Ma'an Governorate",
      "Madaba Governorate",
      "Mafraq Governorate",
      "Tafilah Governorate",
      "Zarqa Governorate",
    ],
  },
  {
    country: "Kazakhstan",
    states: [
      "Akmola Region",
      "Aktobe Region",
      "Almaty",
      "Almaty Region",
      "Atyrau Region",
      "Baikonur",
      "East Kazakhstan Region",
      "Jambyl Region",
      "Karaganda Region",
      "Kostanay Region",
      "Kyzylorda Region",
      "Mangystau Region",
      "North Kazakhstan Region",
      "Nur-Sultan",
      "Pavlodar Region",
      "Turkestan Region",
      "West Kazakhstan Province",
    ],
  },
  {
    country: "Kenya",
    states: [
      "Baringo",
      "Bomet",
      "Bungoma",
      "Busia",
      "Elgeyo-Marakwet",
      "Embu",
      "Garissa",
      "Homa Bay",
      "Isiolo",
      "Kajiado",
      "Kakamega",
      "Kericho",
      "Kiambu",
      "Kilifi",
      "Kirinyaga",
      "Kisii",
      "Kisumu",
      "Kitui",
      "Kwale",
      "Laikipia",
      "Lamu",
      "Machakos",
      "Makueni",
      "Mandera",
      "Marsabit",
      "Meru",
      "Migori",
      "Mombasa",
      "Murang'a",
      "Nairobi City",
      "Nakuru",
      "Nandi",
      "Narok",
      "Nyamira",
      "Nyandarua",
      "Nyeri",
      "Samburu",
      "Siaya",
      "Taita–Taveta",
      "Tana River",
      "Tharaka-Nithi",
      "Trans Nzoia",
      "Turkana",
      "Uasin Gishu",
      "Vihiga",
      "Wajir",
      "West Pokot",
    ],
  },
  {
    country: "Kiribati",
    states: ["Gilbert Islands", "Line Islands", "Phoenix Islands"],
  },
  {
    country: "Kosovo",
    states: [
      "Đakovica District (Gjakove)",
      "Gjilan District",
      "Kosovska Mitrovica District",
      "Peć District",
      "Pristina (Priştine)",
      "Prizren District",
      "Uroševac District (Ferizaj)",
    ],
  },
  {
    country: "Kuwait",
    states: [
      "Al Ahmadi Governorate",
      "Al Farwaniyah Governorate",
      "Al Jahra Governorate",
      "Capital Governorate",
      "Hawalli Governorate",
      "Mubarak Al-Kabeer Governorate",
    ],
  },
  {
    country: "Kyrgyzstan",
    states: ["Batken Region", "Bishkek", "Chuy Region", "Issyk-Kul Region", "Jalal-Abad Region", "Naryn Region", "Osh", "Osh Region", "Talas Region"],
  },
  {
    country: "Laos",
    states: [
      "Attapeu Province",
      "Bokeo Province",
      "Bolikhamsai Province",
      "Champasak Province",
      "Houaphanh Province",
      "Khammouane Province",
      "Luang Namtha Province",
      "Luang Prabang Province",
      "Oudomxay Province",
      "Phongsaly Province",
      "Sainyabuli Province",
      "Salavan Province",
      "Savannakhet Province",
      "Sekong Province",
      "Vientiane Prefecture",
      "Vientiane Province",
      "Xaisomboun",
      "Xaisomboun Province",
      "Xiangkhouang Province",
    ],
  },
  {
    country: "Latvia",
    states: [
      "Aglona Municipality",
      "Aizkraukle Municipality",
      "Aizpute Municipality",
      "Aknīste Municipality",
      "Aloja Municipality",
      "Alsunga Municipality",
      "Alūksne Municipality",
      "Amata Municipality",
      "Ape Municipality",
      "Auce Municipality",
      "Babīte Municipality",
      "Baldone Municipality",
      "Baltinava Municipality",
      "Balvi Municipality",
      "Bauska Municipality",
      "Beverīna Municipality",
      "Brocēni Municipality",
      "Burtnieki Municipality",
      "Carnikava Municipality",
      "Cēsis Municipality",
      "Cesvaine Municipality",
      "Cibla Municipality",
      "Dagda Municipality",
      "Daugavpils",
      "Daugavpils Municipality",
      "Dobele Municipality",
      "Dundaga Municipality",
      "Durbe Municipality",
      "Engure Municipality",
      "Ērgļi Municipality",
      "Garkalne Municipality",
      "Grobiņa Municipality",
      "Gulbene Municipality",
      "Iecava Municipality",
      "Ikšķile Municipality",
      "Ilūkste Municipality",
      "Inčukalns Municipality",
      "Jaunjelgava Municipality",
      "Jaunpiebalga Municipality",
      "Jaunpils Municipality",
      "Jēkabpils",
      "Jēkabpils Municipality",
      "Jelgava",
      "Jelgava Municipality",
      "Jūrmala",
      "Kandava Municipality",
      "Kārsava Municipality",
      "Ķegums Municipality",
      "Ķekava Municipality",
      "Kocēni Municipality",
      "Koknese Municipality",
      "Krāslava Municipality",
      "Krimulda Municipality",
      "Krustpils Municipality",
      "Kuldīga Municipality",
      "Lielvārde Municipality",
      "Liepāja",
      "Līgatne Municipality",
      "Limbaži Municipality",
      "Līvāni Municipality",
      "Lubāna Municipality",
      "Ludza Municipality",
      "Madona Municipality",
      "Mālpils Municipality",
      "Mārupe Municipality",
      "Mazsalaca Municipality",
      "Mērsrags Municipality",
      "Naukšēni Municipality",
      "Nereta Municipality",
      "Nīca Municipality",
      "Ogre Municipality",
      "Olaine Municipality",
      "Ozolnieki Municipality",
      "Pārgauja Municipality",
      "Pāvilosta Municipality",
      "Pļaviņas Municipality",
      "Preiļi Municipality",
      "Priekule Municipality",
      "Priekuļi Municipality",
      "Rauna Municipality",
      "Rēzekne",
      "Rēzekne Municipality",
      "Riebiņi Municipality",
      "Riga",
      "Roja Municipality",
      "Ropaži Municipality",
      "Rucava Municipality",
      "Rugāji Municipality",
      "Rūjiena Municipality",
      "Rundāle Municipality",
      "Sala Municipality",
      "Salacgrīva Municipality",
      "Salaspils Municipality",
      "Saldus Municipality",
      "Saulkrasti Municipality",
      "Sēja Municipality",
      "Sigulda Municipality",
      "Skrīveri Municipality",
      "Skrunda Municipality",
      "Smiltene Municipality",
      "Stopiņi Municipality",
      "Strenči Municipality",
      "Talsi Municipality",
      "Tērvete Municipality",
      "Tukums Municipality",
      "Vaiņode Municipality",
      "Valka Municipality",
      "Valmiera",
      "Varakļāni Municipality",
      "Vārkava Municipality",
      "Vecpiebalga Municipality",
      "Vecumnieki Municipality",
      "Ventspils",
      "Ventspils Municipality",
      "Viesīte Municipality",
      "Viļaka Municipality",
      "Viļāni Municipality",
      "Zilupe Municipality",
    ],
  },
  {
    country: "Lebanon",
    states: [
      "Akkar Governorate",
      "Baalbek-Hermel Governorate",
      "Beirut Governorate",
      "Beqaa Governorate",
      "Mount Lebanon Governorate",
      "Nabatieh Governorate",
      "North Governorate",
      "South Governorate",
    ],
  },
  {
    country: "Lesotho",
    states: [
      "Berea District",
      "Butha-Buthe District",
      "Leribe District",
      "Mafeteng District",
      "Maseru District",
      "Mohale's Hoek District",
      "Mokhotlong District",
      "Qacha's Nek District",
      "Quthing District",
      "Thaba-Tseka District",
    ],
  },
  {
    country: "Liberia",
    states: [
      "Bomi County",
      "Bong County",
      "Gbarpolu County",
      "Grand Bassa County",
      "Grand Cape Mount County",
      "Grand Gedeh County",
      "Grand Kru County",
      "Lofa County",
      "Margibi County",
      "Maryland County",
      "Montserrado County",
      "Nimba",
      "River Cess County",
      "River Gee County",
      "Sinoe County",
    ],
  },
  {
    country: "Libya",
    states: [
      "Al Wahat District",
      "Benghazi",
      "Derna District",
      "Ghat District",
      "Jabal al Akhdar",
      "Jabal al Gharbi District",
      "Jafara",
      "Jufra",
      "Kufra District",
      "Marj District",
      "Misrata District",
      "Murqub",
      "Murzuq District",
      "Nalut District",
      "Nuqat al Khams",
      "Sabha District",
      "Sirte District",
      "Tripoli District",
      "Wadi al Hayaa District",
      "Wadi al Shatii District",
      "Zawiya District",
    ],
  },
  {
    country: "Liechtenstein",
    states: ["Balzers", "Eschen", "Gamprin", "Mauren", "Planken", "Ruggell", "Schaan", "Schellenberg", "Triesen", "Triesenberg", "Vaduz"],
  },
  {
    country: "Lithuania",
    states: [
      "Akmenė District Municipality",
      "Alytus City Municipality",
      "Alytus County",
      "Alytus District Municipality",
      "Birštonas Municipality",
      "Biržai District Municipality",
      "Druskininkai municipality",
      "Elektrėnai municipality",
      "Ignalina District Municipality",
      "Jonava District Municipality",
      "Joniškis District Municipality",
      "Jurbarkas District Municipality",
      "Kaišiadorys District Municipality",
      "Kalvarija municipality",
      "Kaunas City Municipality",
      "Kaunas County",
      "Kaunas District Municipality",
      "Kazlų Rūda municipality",
      "Kėdainiai District Municipality",
      "Kelmė District Municipality",
      "Klaipeda City Municipality",
      "Klaipėda County",
      "Klaipėda District Municipality",
      "Kretinga District Municipality",
      "Kupiškis District Municipality",
      "Lazdijai District Municipality",
      "Marijampolė County",
      "Marijampolė Municipality",
      "Mažeikiai District Municipality",
      "Molėtai District Municipality",
      "Neringa Municipality",
      "Pagėgiai municipality",
      "Pakruojis District Municipality",
      "Palanga City Municipality",
      "Panevėžys City Municipality",
      "Panevėžys County",
      "Panevėžys District Municipality",
      "Pasvalys District Municipality",
      "Plungė District Municipality",
      "Prienai District Municipality",
      "Radviliškis District Municipality",
      "Raseiniai District Municipality",
      "Rietavas municipality",
      "Rokiškis District Municipality",
      "Šakiai District Municipality",
      "Šalčininkai District Municipality",
      "Šiauliai City Municipality",
      "Šiauliai County",
      "Šiauliai District Municipality",
      "Šilalė District Municipality",
      "Šilutė District Municipality",
      "Širvintos District Municipality",
      "Skuodas District Municipality",
      "Švenčionys District Municipality",
      "Tauragė County",
      "Tauragė District Municipality",
      "Telšiai County",
      "Telšiai District Municipality",
      "Trakai District Municipality",
      "Ukmergė District Municipality",
      "Utena County",
      "Utena District Municipality",
      "Varėna District Municipality",
      "Vilkaviškis District Municipality",
      "Vilnius City Municipality",
      "Vilnius County",
      "Vilnius District Municipality",
      "Visaginas Municipality",
      "Zarasai District Municipality",
    ],
  },
  {
    country: "Luxembourg",
    states: [
      "Canton of Capellen",
      "Canton of Clervaux",
      "Canton of Diekirch",
      "Canton of Echternach",
      "Canton of Esch-sur-Alzette",
      "Canton of Grevenmacher",
      "Canton of Luxembourg",
      "Canton of Mersch",
      "Canton of Redange",
      "Canton of Remich",
      "Canton of Vianden",
      "Canton of Wiltz",
      "Diekirch District",
      "Grevenmacher District",
      "Luxembourg District",
    ],
  },
  {
    country: "Macau S.A.R.",
    states: [],
  },
  {
    country: "Macedonia",
    states: [
      "Aerodrom Municipality",
      "Aračinovo Municipality",
      "Berovo Municipality",
      "Bitola Municipality",
      "Bogdanci Municipality",
      "Bogovinje Municipality",
      "Bosilovo Municipality",
      "Brvenica Municipality",
      "Butel Municipality",
      "Čair Municipality",
      "Čaška Municipality",
      "Centar Municipality",
      "Centar Župa Municipality",
      "Češinovo-Obleševo Municipality",
      "Čučer-Sandevo Municipality",
      "Debarca Municipality",
      "Delčevo Municipality",
      "Demir Hisar Municipality",
      "Demir Kapija Municipality",
      "Dojran Municipality",
      "Dolneni Municipality",
      "Drugovo Municipality",
      "Gazi Baba Municipality",
      "Gevgelija Municipality",
      "Gjorče Petrov Municipality",
      "Gostivar Municipality",
      "Gradsko Municipality",
      "Greater Skopje",
      "Ilinden Municipality",
      "Jegunovce Municipality",
      "Karbinci",
      "Karpoš Municipality",
      "Kavadarci Municipality",
      "Kičevo Municipality",
      "Kisela Voda Municipality",
      "Kočani Municipality",
      "Konče Municipality",
      "Kratovo Municipality",
      "Kriva Palanka Municipality",
      "Krivogaštani Municipality",
      "Kruševo Municipality",
      "Kumanovo Municipality",
      "Lipkovo Municipality",
      "Lozovo Municipality",
      "Makedonska Kamenica Municipality",
      "Makedonski Brod Municipality",
      "Mavrovo and Rostuša Municipality",
      "Mogila Municipality",
      "Negotino Municipality",
      "Novaci Municipality",
      "Novo Selo Municipality",
      "Ohrid Municipality",
      "Oslomej Municipality",
      "Pehčevo Municipality",
      "Petrovec Municipality",
      "Plasnica Municipality",
      "Prilep Municipality",
      "Probištip Municipality",
      "Radoviš Municipality",
      "Rankovce Municipality",
      "Resen Municipality",
      "Rosoman Municipality",
      "Saraj Municipality",
      "Sopište Municipality",
      "Staro Nagoričane Municipality",
      "Štip Municipality",
      "Struga Municipality",
      "Strumica Municipality",
      "Studeničani Municipality",
      "Šuto Orizari Municipality",
      "Sveti Nikole Municipality",
      "Tearce Municipality",
      "Tetovo Municipality",
      "Valandovo Municipality",
      "Vasilevo Municipality",
      "Veles Municipality",
      "Vevčani Municipality",
      "Vinica Municipality",
      "Vraneštica Municipality",
      "Vrapčište Municipality",
      "Zajas Municipality",
      "Zelenikovo Municipality",
      "Želino Municipality",
      "Zrnovci Municipality",
    ],
  },
  {
    country: "Madagascar",
    states: ["Antananarivo Province", "Antsiranana Province", "Fianarantsoa Province", "Mahajanga Province", "Toamasina Province", "Toliara Province"],
  },
  {
    country: "Malawi",
    states: [
      "Balaka District",
      "Blantyre District",
      "Central Region",
      "Chikwawa District",
      "Chiradzulu District",
      "Chitipa district",
      "Dedza District",
      "Dowa District",
      "Karonga District",
      "Kasungu District",
      "Likoma District",
      "Lilongwe District",
      "Machinga District",
      "Mangochi District",
      "Mchinji District",
      "Mulanje District",
      "Mwanza District",
      "Mzimba District",
      "Nkhata Bay District",
      "Nkhotakota District",
      "Northern Region",
      "Nsanje District",
      "Ntcheu District",
      "Ntchisi District",
      "Phalombe District",
      "Rumphi District",
      "Salima District",
      "Southern Region",
      "Thyolo District",
      "Zomba District",
    ],
  },
  {
    country: "Malaysia",
    states: [
      "Johor",
      "Kedah",
      "Kelantan",
      "Kuala Lumpur",
      "Labuan",
      "Malacca",
      "Negeri Sembilan",
      "Pahang",
      "Penang",
      "Perak",
      "Perlis",
      "Putrajaya",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Terengganu",
    ],
  },
  {
    country: "Maldives",
    states: [
      "Addu Atoll",
      "Alif Alif Atoll",
      "Alif Dhaal Atoll",
      "Central Province",
      "Dhaalu Atoll",
      "Faafu Atoll",
      "Gaafu Alif Atoll",
      "Gaafu Dhaalu Atoll",
      "Gnaviyani Atoll",
      "Haa Alif Atoll",
      "Haa Dhaalu Atoll",
      "Kaafu Atoll",
      "Laamu Atoll",
      "Lhaviyani Atoll",
      "Malé",
      "Meemu Atoll",
      "Noonu Atoll",
      "North Central Province",
      "North Province",
      "Raa Atoll",
      "Shaviyani Atoll",
      "South Central Province",
      "South Province",
      "Thaa Atoll",
      "Upper South Province",
      "Vaavu Atoll",
    ],
  },
  {
    country: "Mali",
    states: [
      "Bamako",
      "Gao Region",
      "Kayes Region",
      "Kidal Region",
      "Koulikoro Region",
      "Ménaka Region",
      "Mopti Region",
      "Ségou Region",
      "Sikasso Region",
      "Taoudénit Region",
      "Tombouctou Region",
    ],
  },
  {
    country: "Malta",
    states: [
      "Attard",
      "Balzan",
      "Birgu",
      "Birkirkara",
      "Birżebbuġa",
      "Cospicua",
      "Dingli",
      "Fgura",
      "Floriana",
      "Fontana",
      "Għajnsielem",
      "Għarb",
      "Għargħur",
      "Għasri",
      "Għaxaq",
      "Gudja",
      "Gżira",
      "Ħamrun",
      "Iklin",
      "Kalkara",
      "Kerċem",
      "Kirkop",
      "Lija",
      "Luqa",
      "Marsa",
      "Marsaskala",
      "Marsaxlokk",
      "Mdina",
      "Mellieħa",
      "Mġarr",
      "Mosta",
      "Mqabba",
      "Msida",
      "Mtarfa",
      "Munxar",
      "Nadur",
      "Naxxar",
      "Paola",
      "Pembroke",
      "Pietà",
      "Qala",
      "Qormi",
      "Qrendi",
      "Rabat",
      "Saint Lawrence",
      "San Ġwann",
      "Sannat",
      "Santa Luċija",
      "Santa Venera",
      "Senglea",
      "Siġġiewi",
      "Sliema",
      "St. Julian's",
      "St. Paul's Bay",
      "Swieqi",
      "Ta' Xbiex",
      "Tarxien",
      "Valletta",
      "Victoria",
      "Xagħra",
      "Xewkija",
      "Xgħajra",
      "Żabbar",
      "Żebbuġ Gozo",
      "Żebbuġ Malta",
      "Żejtun",
      "Żurrieq",
    ],
  },
  {
    country: "Man (Isle of)",
    states: [],
  },
  {
    country: "Marshall Islands",
    states: ["Ralik Chain", "Ratak Chain"],
  },
  {
    country: "Martinique",
    states: [],
  },
  {
    country: "Mauritania",
    states: [
      "Adrar",
      "Assaba",
      "Brakna",
      "Dakhlet Nouadhibou",
      "Gorgol",
      "Guidimaka",
      "Hodh Ech Chargui",
      "Hodh El Gharbi",
      "Inchiri",
      "Nouakchott-Nord",
      "Nouakchott-Ouest",
      "Nouakchott-Sud",
      "Tagant",
      "Tiris Zemmour",
      "Trarza",
    ],
  },
  {
    country: "Mauritius",
    states: [
      "Agaléga",
      "Beau Bassin-Rose Hill",
      "Cargados Carajos",
      "Curepipe",
      "Flacq District",
      "Grand Port District",
      "Moka District",
      "Pamplemousses District",
      "Plaines Wilhems District",
      "Port Louis",
      "Port Louis District",
      "Quatre Bornes",
      "Rivière du Rempart District",
      "Rivière Noire District",
      "Rodrigues",
      "Savanne District",
      "Vacoas-Phoenix",
    ],
  },
  {
    country: "Mayotte",
    states: [],
  },
  {
    country: "Mexico",
    states: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Ciudad de México",
      "Coahuila de Zaragoza",
      "Colima",
      "Durango",
      "Estado de México",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Michoacán de Ocampo",
      "Morelos",
      "Nayarit",
      "Nuevo León",
      "Oaxaca",
      "Puebla",
      "Querétaro",
      "Quintana Roo",
      "San Luis Potosí",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz de Ignacio de la Llave",
      "Yucatán",
      "Zacatecas",
    ],
  },
  {
    country: "Micronesia",
    states: ["Chuuk State", "Kosrae State", "Pohnpei State", "Yap State"],
  },
  {
    country: "Moldova",
    states: [
      "Anenii Noi District",
      "Bălți Municipality",
      "Basarabeasca District",
      "Bender Municipality",
      "Briceni District",
      "Cahul District",
      "Călărași District",
      "Cantemir District",
      "Căușeni District",
      "Chișinău Municipality",
      "Cimișlia District",
      "Criuleni District",
      "Dondușeni District",
      "Drochia District",
      "Dubăsari District",
      "Edineț District",
      "Fălești District",
      "Florești District",
      "Gagauzia",
      "Glodeni District",
      "Hîncești District",
      "Ialoveni District",
      "Nisporeni District",
      "Ocnița District",
      "Orhei District",
      "Rezina District",
      "Rîșcani District",
      "Sîngerei District",
      "Șoldănești District",
      "Soroca District",
      "Ștefan Vodă District",
      "Strășeni District",
      "Taraclia District",
      "Telenești District",
      "Transnistria autonomous territorial unit",
      "Ungheni District",
    ],
  },
  {
    country: "Monaco",
    states: ["La Colle", "La Condamine", "Moneghetti"],
  },
  {
    country: "Mongolia",
    states: [
      "Arkhangai Province",
      "Bayan-Ölgii Province",
      "Bayankhongor Province",
      "Bulgan Province",
      "Darkhan-Uul Province",
      "Dornod Province",
      "Dornogovi Province",
      "Dundgovi Province",
      "Govi-Altai Province",
      "Govisümber Province",
      "Khentii Province",
      "Khovd Province",
      "Khövsgöl Province",
      "Ömnögovi Province",
      "Orkhon Province",
      "Övörkhangai Province",
      "Selenge Province",
      "Sükhbaatar Province",
      "Töv Province",
      "Uvs Province",
      "Zavkhan Province",
    ],
  },
  {
    country: "Montenegro",
    states: [
      "Andrijevica Municipality",
      "Bar Municipality",
      "Berane Municipality",
      "Bijelo Polje Municipality",
      "Budva Municipality",
      "Danilovgrad Municipality",
      "Gusinje Municipality",
      "Kolašin Municipality",
      "Kotor Municipality",
      "Mojkovac Municipality",
      "Nikšić Municipality",
      "Old Royal Capital Cetinje",
      "Petnjica Municipality",
      "Plav Municipality",
      "Pljevlja Municipality",
      "Plužine Municipality",
      "Podgorica Municipality",
      "Rožaje Municipality",
      "Šavnik Municipality",
      "Tivat Municipality",
      "Ulcinj Municipality",
      "Žabljak Municipality",
    ],
  },
  {
    country: "Montserrat",
    states: [],
  },
  {
    country: "Morocco",
    states: [
      "Agadir-Ida-Ou-Tanane",
      "Al Haouz",
      "Al Hoceïma",
      "Aousserd (EH)",
      "Assa-Zag (EH-partial)",
      "Azilal",
      "Béni Mellal",
      "Béni Mellal-Khénifra",
      "Benslimane",
      "Berkane",
      "Berrechid",
      "Boujdour (EH)",
      "Boulemane",
      "Casablanca",
      "Casablanca-Settat",
      "Chefchaouen",
      "Chichaoua",
      "Chtouka-Ait Baha",
      "Dakhla-Oued Ed-Dahab (EH)",
      "Drâa-Tafilalet",
      "Driouch",
      "El Hajeb",
      "El Jadida",
      "El Kelâa des Sraghna",
      "Errachidia",
      "Es-Semara (EH-partial)",
      "Essaouira",
      "Fahs-Anjra",
      "Fès",
      "Fès-Meknès",
      "Figuig",
      "Fquih Ben Salah",
      "Guelmim",
      "Guelmim-Oued Noun (EH-partial)",
      "Guercif",
      "Ifrane",
      "Inezgane-Ait Melloul",
      "Jerada",
      "Kénitra",
      "Khémisset",
      "Khénifra",
      "Khouribga",
      "L'Oriental",
      "Laâyoune (EH)",
      "Laâyoune-Sakia El Hamra (EH-partial)",
      "Larache",
      "M’diq-Fnideq",
      "Marrakech",
      "Marrakesh-Safi",
      "Médiouna",
      "Meknès",
      "Midelt",
      "Mohammadia",
      "Moulay Yacoub",
      "Nador",
      "Nouaceur",
      "Ouarzazate",
      "Oued Ed-Dahab (EH)",
      "Ouezzane",
      "Oujda-Angad",
      "Rabat",
      "Rabat-Salé-Kénitra",
      "Rehamna",
      "Safi",
      "Salé",
      "Sefrou",
      "Settat",
      "Sidi Bennour",
      "Sidi Ifni",
      "Sidi Kacem",
      "Sidi Slimane",
      "Skhirate-Témara",
      "Souss-Massa",
      "Tan-Tan (EH-partial)",
      "Tanger-Assilah",
      "Tanger-Tétouan-Al Hoceïma",
      "Taounate",
      "Taourirt",
      "Tarfaya (EH-partial)",
      "Taroudannt",
      "Tata",
      "Taza",
      "Tétouan",
      "Tinghir",
      "Tiznit",
      "Youssoufia",
      "Zagora",
    ],
  },
  {
    country: "Mozambique",
    states: [
      "Cabo Delgado Province",
      "Gaza Province",
      "Inhambane Province",
      "Manica Province",
      "Maputo",
      "Maputo Province",
      "Nampula Province",
      "Niassa Province",
      "Sofala Province",
      "Tete Province",
      "Zambezia Province",
    ],
  },
  {
    country: "Myanmar",
    states: [
      "Ayeyarwady Region",
      "Bago",
      "Chin State",
      "Kachin State",
      "Kayah State",
      "Kayin State",
      "Magway Region",
      "Mandalay Region",
      "Mon State",
      "Naypyidaw Union Territory",
      "Rakhine State",
      "Sagaing Region",
      "Shan State",
      "Tanintharyi Region",
      "Yangon Region",
    ],
  },
  {
    country: "Namibia",
    states: [
      "Erongo Region",
      "Hardap Region",
      "Karas Region",
      "Kavango East Region",
      "Kavango West Region",
      "Khomas Region",
      "Kunene Region",
      "Ohangwena Region",
      "Omaheke Region",
      "Omusati Region",
      "Oshana Region",
      "Oshikoto Region",
      "Otjozondjupa Region",
      "Zambezi Region",
    ],
  },
  {
    country: "Nauru",
    states: [
      "Aiwo District",
      "Anabar District",
      "Anetan District",
      "Anibare District",
      "Baiti District",
      "Boe District",
      "Buada District",
      "Denigomodu District",
      "Ewa District",
      "Ijuw District",
      "Meneng District",
      "Nibok District",
      "Uaboe District",
      "Yaren District",
    ],
  },
  {
    country: "Nepal",
    states: [
      "Bagmati Zone",
      "Bheri Zone",
      "Central Region",
      "Dhaulagiri Zone",
      "Eastern Development Region",
      "Far-Western Development Region",
      "Gandaki Zone",
      "Janakpur Zone",
      "Karnali Zone",
      "Kosi Zone",
      "Lumbini Zone",
      "Mahakali Zone",
      "Mechi Zone",
      "Mid-Western Region",
      "Narayani Zone",
      "Rapti Zone",
      "Sagarmatha Zone",
      "Seti Zone",
      "Western Region",
    ],
  },
  {
    country: "Netherlands",
    states: [
      "Bonaire",
      "Drenthe",
      "Flevoland",
      "Friesland",
      "Gelderland",
      "Groningen",
      "Limburg",
      "North Brabant",
      "North Holland",
      "Overijssel",
      "Saba",
      "Sint Eustatius",
      "South Holland",
      "Utrecht",
      "Zeeland",
    ],
  },
  {
    country: "New Caledonia",
    states: [],
  },
  {
    country: "New Zealand",
    states: [
      "Auckland Region",
      "Bay of Plenty Region",
      "Canterbury Region",
      "Chatham Islands",
      "Gisborne District",
      "Hawke's Bay Region",
      "Manawatu-Wanganui Region",
      "Marlborough Region",
      "Nelson Region",
      "Northland Region",
      "Otago Region",
      "Southland Region",
      "Taranaki Region",
      "Tasman District",
      "Waikato Region",
      "Wellington Region",
      "West Coast Region",
    ],
  },
  {
    country: "Nicaragua",
    states: [
      "Boaco",
      "Carazo",
      "Chinandega",
      "Chontales",
      "Estelí",
      "Granada",
      "Jinotega",
      "León",
      "Madriz",
      "Managua",
      "Masaya",
      "Matagalpa",
      "North Caribbean Coast",
      "Nueva Segovia",
      "Río San Juan",
      "Rivas",
      "South Caribbean Coast",
    ],
  },
  {
    country: "Niger",
    states: ["Agadez Region", "Diffa Region", "Dosso Region", "Maradi Region", "Tahoua Region", "Tillabéri Region", "Zinder Region"],
  },
  {
    country: "Nigeria",
    states: [
      "Abia",
      "Abuja Federal Capital Territory",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nasarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
    ],
  },
  {
    country: "Niue",
    states: [],
  },
  {
    country: "Norfolk Island",
    states: [],
  },
  {
    country: "North Korea",
    states: [
      "Chagang Province",
      "Kangwon Province",
      "North Hamgyong Province",
      "North Hwanghae Province",
      "North Pyongan Province",
      "Pyongyang",
      "Rason",
      "Ryanggang Province",
      "South Hamgyong Province",
      "South Hwanghae Province",
      "South Pyongan Province",
    ],
  },
  {
    country: "Northern Mariana Islands",
    states: [],
  },
  {
    country: "Norway",
    states: [
      "Akershus",
      "Buskerud",
      "Finnmark",
      "Hedmark",
      "Hordaland",
      "Jan Mayen",
      "Møre og Romsdal",
      "Nord-Trøndelag",
      "Nordland",
      "Oppland",
      "Oslo",
      "Østfold",
      "Rogaland",
      "Sogn og Fjordane",
      "Sør-Trøndelag",
      "Svalbard",
      "Telemark",
      "Troms",
      "Trøndelag",
      "Vest-Agder",
      "Vestfold",
    ],
  },
  {
    country: "Oman",
    states: [
      "Ad Dakhiliyah Governorate",
      "Ad Dhahirah Governorate",
      "Al Batinah North Governorate",
      "Al Batinah Region",
      "Al Batinah South Governorate",
      "Al Buraimi Governorate",
      "Al Wusta Governorate",
      "Ash Sharqiyah North Governorate",
      "Ash Sharqiyah Region",
      "Ash Sharqiyah South Governorate",
      "Dhofar Governorate",
      "Musandam Governorate",
      "Muscat Governorate",
    ],
  },
  {
    country: "Pakistan",
    states: [
      "Azad Kashmir",
      "Balochistan",
      "Federally Administered Tribal Areas",
      "Gilgit-Baltistan",
      "Islamabad Capital Territory",
      "Khyber Pakhtunkhwa",
      "Punjab",
      "Sindh",
    ],
  },
  {
    country: "Palau",
    states: [
      "Aimeliik",
      "Airai",
      "Angaur",
      "Hatohobei",
      "Kayangel",
      "Koror",
      "Melekeok",
      "Ngaraard",
      "Ngarchelong",
      "Ngardmau",
      "Ngatpang",
      "Ngchesar",
      "Ngeremlengui",
      "Ngiwal",
      "Peleliu",
      "Sonsorol",
    ],
  },
  {
    country: "Palestinian Territory Occupied",
    states: [],
  },
  {
    country: "Panama",
    states: [
      "Bocas del Toro Province",
      "Chiriquí Province",
      "Coclé Province",
      "Colón Province",
      "Darién Province",
      "Emberá-Wounaan Comarca",
      "Guna Yala",
      "Herrera Province",
      "Los Santos Province",
      "Ngöbe-Buglé Comarca",
      "Panamá Oeste Province",
      "Panamá Province",
      "Veraguas Province",
    ],
  },
  {
    country: "Papua new Guinea",
    states: [
      "Bougainville",
      "Central Province",
      "Chimbu Province",
      "East New Britain",
      "Eastern Highlands Province",
      "Enga Province",
      "Gulf",
      "Hela",
      "Jiwaka Province",
      "Madang Province",
      "Manus Province",
      "Milne Bay Province",
      "Morobe Province",
      "New Ireland Province",
      "Oro Province",
      "Port Moresby",
      "Sandaun Province",
      "Southern Highlands Province",
      "West New Britain Province",
      "Western Highlands Province",
      "Western Province",
    ],
  },
  {
    country: "Paraguay",
    states: [
      "Alto Paraguay Department",
      "Alto Paraná Department",
      "Amambay Department",
      "Boquerón Department",
      "Caaguazú",
      "Caazapá",
      "Canindeyú",
      "Central Department",
      "Concepción Department",
      "Cordillera Department",
      "Guairá Department",
      "Itapúa",
      "Misiones Department",
      "Ñeembucú Department",
      "Paraguarí Department",
      "Presidente Hayes Department",
      "San Pedro Department",
    ],
  },
  {
    country: "Peru",
    states: [
      "Amazonas",
      "Áncash",
      "Apurímac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Callao",
      "Cusco",
      "Huancavelica",
      "Huanuco",
      "Ica",
      "Junín",
      "La Libertad",
      "Lambayeque",
      "Lima",
      "Loreto",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Martín",
      "Tacna",
      "Tumbes",
      "Ucayali",
    ],
  },
  {
    country: "Philippines",
    states: [
      "Abra",
      "Agusan del Norte",
      "Agusan del Sur",
      "Aklan",
      "Albay",
      "Antique",
      "Apayao",
      "Aurora",
      "Autonomous Region in Muslim Mindanao",
      "Basilan",
      "Bataan",
      "Batanes",
      "Batangas",
      "Benguet",
      "Bicol Region",
      "Biliran",
      "Bohol",
      "Bukidnon",
      "Bulacan",
      "Cagayan",
      "Cagayan Valley",
      "Calabarzon",
      "Camarines Norte",
      "Camarines Sur",
      "Camiguin",
      "Capiz",
      "Caraga",
      "Catanduanes",
      "Cavite",
      "Cebu",
      "Central Luzon",
      "Central Visayas",
      "Compostela Valley",
      "Cordillera Administrative Region",
      "Cotabato",
      "Davao del Norte",
      "Davao del Sur",
      "Davao Occidental",
      "Davao Oriental",
      "Davao Region",
      "Dinagat Islands",
      "Eastern Samar",
      "Eastern Visayas",
      "Guimaras",
      "Ifugao",
      "Ilocos Norte",
      "Ilocos Region",
      "Ilocos Sur",
      "Iloilo",
      "Isabela",
      "Kalinga",
      "La Union",
      "Laguna",
      "Lanao del Norte",
      "Lanao del Sur",
      "Leyte",
      "Maguindanao",
      "Marinduque",
      "Masbate",
      "Metro Manila",
      "Mimaropa",
      "Misamis Occidental",
      "Misamis Oriental",
      "Mountain Province",
      "Negros Occidental",
      "Negros Oriental",
      "Northern Mindanao",
      "Northern Samar",
      "Nueva Ecija",
      "Nueva Vizcaya",
      "Occidental Mindoro",
      "Oriental Mindoro",
      "Palawan",
      "Pampanga",
      "Pangasinan",
      "Quezon",
      "Quirino",
      "Rizal",
      "Romblon",
      "Sarangani",
      "Siquijor",
      "Soccsksargen",
      "Sorsogon",
      "South Cotabato",
      "Southern Leyte",
      "Sultan Kudarat",
      "Sulu",
      "Surigao del Norte",
      "Surigao del Sur",
      "Tarlac",
      "Tawi-Tawi",
      "Western Visayas",
      "Zambales",
      "Zamboanga del Norte",
      "Zamboanga del Sur",
      "Zamboanga Peninsula",
      "Zamboanga Sibugay",
    ],
  },
  {
    country: "Pitcairn Island",
    states: [],
  },
  {
    country: "Poland",
    states: [
      "Greater Poland Voivodeship",
      "Kuyavian-Pomeranian Voivodeship",
      "Lesser Poland Voivodeship",
      "Lower Silesian Voivodeship",
      "Lublin Voivodeship",
      "Lubusz Voivodeship",
      "Łódź Voivodeship",
      "Masovian Voivodeship",
      "Opole Voivodeship",
      "Podkarpackie Voivodeship",
      "Podlaskie Voivodeship",
      "Pomeranian Voivodeship",
      "Silesian Voivodeship",
      "Świętokrzyskie Voivodeship",
      "Warmian-Masurian Voivodeship",
      "West Pomeranian Voivodeship",
    ],
  },
  {
    country: "Portugal",
    states: [
      "Açores",
      "Aveiro",
      "Beja",
      "Braga",
      "Bragança",
      "Castelo Branco",
      "Coimbra",
      "Évora",
      "Faro",
      "Guarda",
      "Leiria",
      "Lisbon",
      "Madeira",
      "Portalegre",
      "Porto",
      "Santarém",
      "Setúbal",
      "Viana do Castelo",
      "Vila Real",
      "Viseu",
    ],
  },
  {
    country: "Puerto Rico",
    states: ["Arecibo", "Bayamon", "Caguas", "Carolina", "Guaynabo", "Mayagüez", "Ponce", "San Juan", "Toa Baja", "Trujillo Alto"],
  },
  {
    country: "Qatar",
    states: ["Al Daayen", "Al Khor", "Al Rayyan Municipality", "Al Wakrah", "Al-Shahaniya", "Doha", "Madinat ash Shamal", "Umm Salal Municipality"],
  },
  {
    country: "Reunion",
    states: [],
  },
  {
    country: "Romania",
    states: [
      "Alba",
      "Arad County",
      "Arges",
      "Bacău County",
      "Bihor County",
      "Bistrița-Năsăud County",
      "Botoșani County",
      "Braila",
      "Brașov County",
      "Bucharest",
      "Buzău County",
      "Călărași County",
      "Caraș-Severin County",
      "Cluj County",
      "Constanța County",
      "Covasna County",
      "Dâmbovița County",
      "Dolj County",
      "Galați County",
      "Giurgiu County",
      "Gorj County",
      "Harghita County",
      "Hunedoara County",
      "Ialomița County",
      "Iași County",
      "Ilfov County",
      "Maramureș County",
      "Mehedinți County",
      "Mureș County",
      "Neamț County",
      "Olt County",
      "Prahova County",
      "Sălaj County",
      "Satu Mare County",
      "Sibiu County",
      "Suceava County",
      "Teleorman County",
      "Timiș County",
      "Tulcea County",
      "Vâlcea County",
      "Vaslui County",
      "Vrancea County",
    ],
  },
  {
    country: "Russia",
    states: [
      "Altai Krai",
      "Altai Republic",
      "Amur Oblast",
      "Arkhangelsk",
      "Astrakhan Oblast",
      "Belgorod Oblast",
      "Bryansk Oblast",
      "Chechen Republic",
      "Chelyabinsk Oblast",
      "Chukotka Autonomous Okrug",
      "Chuvash Republic",
      "Irkutsk",
      "Ivanovo Oblast",
      "Jewish Autonomous Oblast",
      "Kabardino-Balkar Republic",
      "Kaliningrad",
      "Kaluga Oblast",
      "Kamchatka Krai",
      "Karachay-Cherkess Republic",
      "Kemerovo Oblast",
      "Khabarovsk Krai",
      "Khanty-Mansi Autonomous Okrug",
      "Kirov Oblast",
      "Komi Republic",
      "Kostroma Oblast",
      "Krasnodar Krai",
      "Krasnoyarsk Krai",
      "Kurgan Oblast",
      "Kursk Oblast",
      "Leningrad Oblast",
      "Lipetsk Oblast",
      "Magadan Oblast",
      "Mari El Republic",
      "Moscow",
      "Moscow Oblast",
      "Murmansk Oblast",
      "Nenets Autonomous Okrug",
      "Nizhny Novgorod Oblast",
      "Novgorod Oblast",
      "Novosibirsk",
      "Omsk Oblast",
      "Orenburg Oblast",
      "Oryol Oblast",
      "Penza Oblast",
      "Perm Krai",
      "Primorsky Krai",
      "Pskov Oblast",
      "Republic of Adygea",
      "Republic of Bashkortostan",
      "Republic of Buryatia",
      "Republic of Dagestan",
      "Republic of Ingushetia",
      "Republic of Kalmykia",
      "Republic of Karelia",
      "Republic of Khakassia",
      "Republic of Mordovia",
      "Republic of North Ossetia-Alania",
      "Republic of Tatarstan",
      "Rostov Oblast",
      "Ryazan Oblast",
      "Saint Petersburg",
      "Sakha Republic",
      "Sakhalin",
      "Samara Oblast",
      "Saratov Oblast",
      "Sevastopol",
      "Smolensk Oblast",
      "Stavropol Krai",
      "Sverdlovsk",
      "Tambov Oblast",
      "Tomsk Oblast",
      "Tula Oblast",
      "Tuva Republic",
      "Tver Oblast",
      "Tyumen Oblast",
      "Udmurt Republic",
      "Ulyanovsk Oblast",
      "Vladimir Oblast",
      "Volgograd Oblast",
      "Vologda Oblast",
      "Voronezh Oblast",
      "Yamalo-Nenets Autonomous Okrug",
      "Yaroslavl Oblast",
      "Zabaykalsky Krai",
    ],
  },
  {
    country: "Rwanda",
    states: ["Eastern Province", "Kigali district", "Northern Province", "Southern Province", "Western Province"],
  },
  {
    country: "Saint Helena",
    states: [],
  },
  {
    country: "Saint Kitts And Nevis",
    states: [
      "Christ Church Nichola Town Parish",
      "Nevis",
      "Saint Anne Sandy Point Parish",
      "Saint George Gingerland Parish",
      "Saint James Windward Parish",
      "Saint John Capisterre Parish",
      "Saint John Figtree Parish",
      "Saint Kitts",
      "Saint Mary Cayon Parish",
      "Saint Paul Capisterre Parish",
      "Saint Paul Charlestown Parish",
      "Saint Peter Basseterre Parish",
      "Saint Thomas Lowland Parish",
      "Saint Thomas Middle Island Parish",
      "Trinity Palmetto Point Parish",
    ],
  },
  {
    country: "Saint Lucia",
    states: [
      "Anse la Raye Quarter",
      "Canaries",
      "Castries Quarter",
      "Choiseul Quarter",
      "Dauphin Quarter",
      "Dennery Quarter",
      "Gros Islet Quarter",
      "Laborie Quarter",
      "Micoud Quarter",
      "Praslin Quarter",
      "Soufrière Quarter",
      "Vieux Fort Quarter",
    ],
  },
  {
    country: "Saint Pierre and Miquelon",
    states: [],
  },
  {
    country: "Saint Vincent And The Grenadines",
    states: ["Charlotte Parish", "Grenadines Parish", "Saint Andrew Parish", "Saint David Parish", "Saint George Parish", "Saint Patrick Parish"],
  },
  {
    country: "Saint-Barthelemy",
    states: [],
  },
  {
    country: "Saint-Martin (French part)",
    states: [],
  },
  {
    country: "Samoa",
    states: [
      "A'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa'asaleleaga",
      "Gaga'emauga",
      "Gaga'ifomauga",
      "Palauli",
      "Satupa'itea",
      "Tuamasaga",
      "Va'a-o-Fonoti",
      "Vaisigano",
    ],
  },
  {
    country: "San Marino",
    states: ["Acquaviva", "Borgo Maggiore", "Chiesanuova", "Domagnano", "Faetano", "Fiorentino", "Montegiardino", "San Marino", "Serravalle"],
  },
  {
    country: "Sao Tome and Principe",
    states: ["Príncipe Province", "São Tomé Province"],
  },
  {
    country: "Saudi Arabia",
    states: [
      "'Asir",
      "Al Bahah",
      "Al Jawf",
      "Al Madinah",
      "Al-Qassim",
      "Eastern Province",
      "Ha'il",
      "Jizan",
      "Makkah",
      "Najran",
      "Northern Borders",
      "Riyadh",
      "Tabuk",
    ],
  },
  {
    country: "Senegal",
    states: [
      "Dakar",
      "Diourbel Region",
      "Fatick",
      "Kaffrine",
      "Kaolack",
      "Kédougou",
      "Kolda",
      "Louga",
      "Matam",
      "Saint-Louis",
      "Sédhiou",
      "Tambacounda Region",
      "Thiès Region",
      "Ziguinchor",
    ],
  },
  {
    country: "Serbia",
    states: [
      "Belgrade",
      "Bor District",
      "Braničevo District",
      "Central Banat District",
      "Jablanica District",
      "Kolubara District",
      "Mačva District",
      "Moravica District",
      "Nišava District",
      "North Bačka District",
      "North Banat District",
      "Pčinja District",
      "Pirot District",
      "Podunavlje District",
      "Pomoravlje District",
      "Rasina District",
      "Raška District",
      "South Bačka District",
      "South Banat District",
      "Srem District",
      "Šumadija District",
      "Toplica District",
      "Vojvodina",
      "West Bačka District",
      "Zaječar District",
      "Zlatibor District",
    ],
  },
  {
    country: "Seychelles",
    states: [
      "Anse Boileau",
      "Anse Royale",
      "Anse-aux-Pins",
      "Au Cap",
      "Baie Lazare",
      "Baie Sainte Anne",
      "Beau Vallon",
      "Bel Air",
      "Bel Ombre",
      "Cascade",
      "Glacis",
      "Grand'Anse Mahé",
      "Grand'Anse Praslin",
      "La Digue",
      "La Rivière Anglaise",
      "Les Mamelles",
      "Mont Buxton",
      "Mont Fleuri",
      "Plaisance",
      "Pointe La Rue",
      "Port Glaud",
      "Roche Caiman",
      "Saint Louis",
      "Takamaka",
    ],
  },
  {
    country: "Sierra Leone",
    states: ["Eastern Province", "Northern Province", "Southern Province", "Western Area"],
  },
  {
    country: "Singapore",
    states: [
      "Central Singapore Community Development Council",
      "North East Community Development Council",
      "North West Community Development Council",
      "South East Community Development Council",
      "South West Community Development Council",
    ],
  },
  {
    country: "Sint Maarten (Dutch part)",
    states: [],
  },
  {
    country: "Slovakia",
    states: [
      "Banská Bystrica Region",
      "Bratislava Region",
      "Košice Region",
      "Nitra Region",
      "Prešov Region",
      "Trenčín Region",
      "Trnava Region",
      "Žilina Region",
    ],
  },
  {
    country: "Slovenia",
    states: [
      "Ajdovščina Municipality",
      "Ankaran Municipality",
      "Beltinci Municipality",
      "Benedikt Municipality",
      "Bistrica ob Sotli Municipality",
      "Bled Municipality",
      "Bloke Municipality",
      "Bohinj Municipality",
      "Borovnica Municipality",
      "Bovec Municipality",
      "Braslovče Municipality",
      "Brda Municipality",
      "Brežice Municipality",
      "Brezovica Municipality",
      "Cankova Municipality",
      "Cerklje na Gorenjskem Municipality",
      "Cerknica Municipality",
      "Cerkno Municipality",
      "Cerkvenjak Municipality",
      "City Municipality of Celje",
      "City Municipality of Novo Mesto",
      "Črenšovci Municipality",
      "Črna na Koroškem Municipality",
      "Črnomelj Municipality",
      "Destrnik Municipality",
      "Divača Municipality",
      "Dobje Municipality",
      "Dobrepolje Municipality",
      "Dobrna Municipality",
      "Dobrova–Polhov Gradec Municipality",
      "Dobrovnik Municipality",
      "Dol pri Ljubljani Municipality",
      "Dolenjske Toplice Municipality",
      "Domžale Municipality",
      "Dornava Municipality",
      "Dravograd Municipality",
      "Duplek Municipality",
      "Gorenja Vas–Poljane Municipality",
      "Gorišnica Municipality",
      "Gorje Municipality",
      "Gornja Radgona Municipality",
      "Gornji Grad Municipality",
      "Gornji Petrovci Municipality",
      "Grad Municipality",
      "Grosuplje Municipality",
      "Hajdina Municipality",
      "Hoče–Slivnica Municipality",
      "Hodoš Municipality",
      "Horjul Municipality",
      "Hrastnik Municipality",
      "Hrpelje–Kozina Municipality",
      "Idrija Municipality",
      "Ig Municipality",
      "Ivančna Gorica Municipality",
      "Izola Municipality",
      "Jesenice Municipality",
      "Jezersko Municipality",
      "Juršinci Municipality",
      "Kamnik Municipality",
      "Kanal ob Soči Municipality",
      "Kidričevo Municipality",
      "Kobarid Municipality",
      "Kobilje Municipality",
      "Kočevje Municipality",
      "Komen Municipality",
      "Komenda Municipality",
      "Koper City Municipality",
      "Kostanjevica na Krki Municipality",
      "Kostel Municipality",
      "Kozje Municipality",
      "Kranj City Municipality",
      "Kranjska Gora Municipality",
      "Križevci Municipality",
      "Kungota",
      "Kuzma Municipality",
      "Laško Municipality",
      "Lenart Municipality",
      "Lendava Municipality",
      "Litija Municipality",
      "Ljubljana City Municipality",
      "Ljubno Municipality",
      "Ljutomer Municipality",
      "Log–Dragomer Municipality",
      "Logatec Municipality",
      "Loška Dolina Municipality",
      "Loški Potok Municipality",
      "Lovrenc na Pohorju Municipality",
      "Luče Municipality",
      "Lukovica Municipality",
      "Majšperk Municipality",
      "Makole Municipality",
      "Maribor City Municipality",
      "Markovci Municipality",
      "Medvode Municipality",
      "Mengeš Municipality",
      "Metlika Municipality",
      "Mežica Municipality",
      "Miklavž na Dravskem Polju Municipality",
      "Miren–Kostanjevica Municipality",
      "Mirna Municipality",
      "Mirna Peč Municipality",
      "Mislinja Municipality",
      "Mokronog–Trebelno Municipality",
      "Moravče Municipality",
      "Moravske Toplice Municipality",
      "Mozirje Municipality",
      "Municipality of Apače",
      "Municipality of Cirkulane",
      "Municipality of Ilirska Bistrica",
      "Municipality of Krško",
      "Municipality of Škofljica",
      "Murska Sobota City Municipality",
      "Muta Municipality",
      "Naklo Municipality",
      "Nazarje Municipality",
      "Nova Gorica City Municipality",
      "Odranci Municipality",
      "Oplotnica",
      "Ormož Municipality",
      "Osilnica Municipality",
      "Pesnica Municipality",
      "Piran Municipality",
      "Pivka Municipality",
      "Podčetrtek Municipality",
      "Podlehnik Municipality",
      "Podvelka Municipality",
      "Poljčane Municipality",
      "Polzela Municipality",
      "Postojna Municipality",
      "Prebold Municipality",
      "Preddvor Municipality",
      "Prevalje Municipality",
      "Ptuj City Municipality",
      "Puconci Municipality",
      "Rače–Fram Municipality",
      "Radeče Municipality",
      "Radenci Municipality",
      "Radlje ob Dravi Municipality",
      "Radovljica Municipality",
      "Ravne na Koroškem Municipality",
      "Razkrižje Municipality",
      "Rečica ob Savinji Municipality",
      "Renče–Vogrsko Municipality",
      "Ribnica Municipality",
      "Ribnica na Pohorju Municipality",
      "Rogaška Slatina Municipality",
      "Rogašovci Municipality",
      "Rogatec Municipality",
      "Ruše Municipality",
      "Šalovci Municipality",
      "Selnica ob Dravi Municipality",
      "Semič Municipality",
      "Šempeter–Vrtojba Municipality",
      "Šenčur Municipality",
      "Šentilj Municipality",
      "Šentjernej Municipality",
      "Šentjur Municipality",
      "Šentrupert Municipality",
      "Sevnica Municipality",
      "Sežana Municipality",
      "Škocjan Municipality",
      "Škofja Loka Municipality",
      "Slovenj Gradec City Municipality",
      "Slovenska Bistrica Municipality",
      "Slovenske Konjice Municipality",
      "Šmarje pri Jelšah Municipality",
      "Šmarješke Toplice Municipality",
      "Šmartno ob Paki Municipality",
      "Šmartno pri Litiji Municipality",
      "Sodražica Municipality",
      "Solčava Municipality",
      "Šoštanj Municipality",
      "Središče ob Dravi",
      "Starše Municipality",
      "Štore Municipality",
      "Straža Municipality",
      "Sveta Ana Municipality",
      "Sveta Trojica v Slovenskih Goricah Municipality",
      "Sveti Andraž v Slovenskih Goricah Municipality",
      "Sveti Jurij ob Ščavnici Municipality",
      "Sveti Jurij v Slovenskih Goricah Municipality",
      "Sveti Tomaž Municipality",
      "Tabor Municipality",
      "Tišina Municipality",
      "Tolmin Municipality",
      "Trbovlje Municipality",
      "Trebnje Municipality",
      "Trnovska Vas Municipality",
      "Tržič Municipality",
      "Trzin Municipality",
      "Turnišče Municipality",
      "Velika Polana Municipality",
      "Velike Lašče Municipality",
      "Veržej Municipality",
      "Videm Municipality",
      "Vipava Municipality",
      "Vitanje Municipality",
      "Vodice Municipality",
      "Vojnik Municipality",
      "Vransko Municipality",
      "Vrhnika Municipality",
      "Vuzenica Municipality",
      "Zagorje ob Savi Municipality",
      "Žalec Municipality",
      "Zavrč Municipality",
      "Železniki Municipality",
      "Žetale Municipality",
      "Žiri Municipality",
      "Žirovnica Municipality",
      "Zreče Municipality",
      "Žužemberk Municipality",
    ],
  },
  {
    country: "Solomon Islands",
    states: [
      "Central Province",
      "Choiseul Province",
      "Guadalcanal Province",
      "Honiara",
      "Isabel Province",
      "Makira-Ulawa Province",
      "Malaita Province",
      "Rennell and Bellona Province",
      "Temotu Province",
      "Western Province",
    ],
  },
  {
    country: "Somalia",
    states: [
      "Awdal Region",
      "Bakool",
      "Banaadir",
      "Bari",
      "Bay",
      "Galguduud",
      "Gedo",
      "Hiran",
      "Lower Juba",
      "Lower Shebelle",
      "Middle Juba",
      "Middle Shebelle",
      "Mudug",
      "Nugal",
      "Sanaag Region",
      "Togdheer Region",
    ],
  },
  {
    country: "South Africa",
    states: ["Eastern Cape", "Free State", "Gauteng", "KwaZulu-Natal", "Limpopo", "Mpumalanga", "North West", "Northern Cape", "Western Cape"],
  },
  {
    country: "South Georgia",
    states: [],
  },
  {
    country: "South Korea",
    states: [
      "Busan",
      "Daegu",
      "Daejeon",
      "Gangwon Province",
      "Gwangju",
      "Gyeonggi Province",
      "Incheon",
      "Jeju",
      "North Chungcheong Province",
      "North Gyeongsang Province",
      "North Jeolla Province",
      "Sejong City",
      "Seoul",
      "South Chungcheong Province",
      "South Gyeongsang Province",
      "South Jeolla Province",
      "Ulsan",
    ],
  },
  {
    country: "South Sudan",
    states: [
      "Central Equatoria",
      "Eastern Equatoria",
      "Jonglei State",
      "Lakes",
      "Northern Bahr el Ghazal",
      "Unity",
      "Upper Nile",
      "Warrap",
      "Western Bahr el Ghazal",
      "Western Equatoria",
    ],
  },
  {
    country: "Spain",
    states: [
      "Andalusia",
      "Aragon",
      "Asturias",
      "Ávila",
      "Balearic Islands",
      "Basque Country",
      "Burgos Province",
      "Canary Islands",
      "Cantabria",
      "Castile and León",
      "Castilla La Mancha",
      "Catalonia",
      "Ceuta",
      "Extremadura",
      "Galicia",
      "La Rioja",
      "Léon",
      "Madrid",
      "Melilla",
      "Murcia",
      "Navarra",
      "Palencia Province",
      "Salamanca Province",
      "Segovia Province",
      "Soria Province",
      "Valencia",
      "Valladolid Province",
      "Zamora Province",
    ],
  },
  {
    country: "Sri Lanka",
    states: [
      "Ampara District",
      "Anuradhapura District",
      "Badulla District",
      "Batticaloa District",
      "Central Province",
      "Colombo District",
      "Eastern Province",
      "Galle District",
      "Gampaha District",
      "Hambantota District",
      "Jaffna District",
      "Kalutara District",
      "Kandy District",
      "Kegalle District",
      "Kilinochchi District",
      "Mannar District",
      "Matale District",
      "Matara District",
      "Monaragala District",
      "Mullaitivu District",
      "North Central Province",
      "North Western Province",
      "Northern Province",
      "Nuwara Eliya District",
      "Polonnaruwa District",
      "Puttalam District",
      "Ratnapura district",
      "Sabaragamuwa Province",
      "Southern Province",
      "Trincomalee District",
      "Uva Province",
      "Vavuniya District",
      "Western Province",
    ],
  },
  {
    country: "Sudan",
    states: [
      "Al Jazirah",
      "Al Qadarif",
      "Blue Nile",
      "Central Darfur",
      "East Darfur",
      "Kassala",
      "Khartoum",
      "North Darfur",
      "North Kordofan",
      "Northern",
      "Red Sea",
      "River Nile",
      "Sennar",
      "South Darfur",
      "South Kordofan",
      "West Darfur",
      "West Kordofan",
      "White Nile",
    ],
  },
  {
    country: "Suriname",
    states: [
      "Brokopondo District",
      "Commewijne District",
      "Coronie District",
      "Marowijne District",
      "Nickerie District",
      "Para District",
      "Paramaribo District",
      "Saramacca District",
      "Sipaliwini District",
      "Wanica District",
    ],
  },
  {
    country: "Svalbard And Jan Mayen Islands",
    states: [],
  },
  {
    country: "Swaziland",
    states: ["Hhohho District", "Lubombo District", "Manzini District", "Shiselweni District"],
  },
  {
    country: "Sweden",
    states: [
      "Blekinge",
      "Dalarna County",
      "Gävleborg County",
      "Gotland County",
      "Halland County",
      "Jönköping County",
      "Kalmar County",
      "Kronoberg County",
      "Norrbotten County",
      "Örebro County",
      "Östergötland County",
      "Skåne County",
      "Södermanland County",
      "Stockholm County",
      "Uppsala County",
      "Värmland County",
      "Västerbotten County",
      "Västernorrland County",
      "Västmanland County",
      "Västra Götaland County",
    ],
  },
  {
    country: "Switzerland",
    states: [
      "Aargau",
      "Appenzell Ausserrhoden",
      "Appenzell Innerrhoden",
      "Basel-Land",
      "Basel-Stadt",
      "Bern",
      "Fribourg",
      "Geneva",
      "Glarus",
      "Graubünden",
      "Jura",
      "Lucerne",
      "Neuchâtel",
      "Nidwalden",
      "Obwalden",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "St. Gallen",
      "Thurgau",
      "Ticino",
      "Uri",
      "Valais",
      "Vaud",
      "Zug",
      "Zürich",
    ],
  },
  {
    country: "Syria",
    states: [
      "Al-Hasakah Governorate",
      "Al-Raqqah Governorate",
      "Aleppo Governorate",
      "As-Suwayda Governorate",
      "Damascus Governorate",
      "Daraa Governorate",
      "Deir ez-Zor Governorate",
      "Hama Governorate",
      "Homs Governorate",
      "Idlib Governorate",
      "Latakia Governorate",
      "Quneitra Governorate",
      "Rif Dimashq Governorate",
      "Tartus Governorate",
    ],
  },
  {
    country: "Taiwan",
    states: [
      "Changhua",
      "Chiayi",
      "Chiayi",
      "Hsinchu",
      "Hsinchu",
      "Hualien",
      "Kaohsiung",
      "Keelung",
      "Kinmen",
      "Lienchiang",
      "Miaoli",
      "Nantou",
      "New Taipei",
      "Penghu",
      "Pingtung",
      "Taichung",
      "Tainan",
      "Taipei",
      "Taitung",
      "Taoyuan",
      "Yilan",
      "Yunlin",
    ],
  },
  {
    country: "Tajikistan",
    states: ["districts of Republican Subordination", "Gorno-Badakhshan Autonomous Province", "Khatlon Province", "Sughd Province"],
  },
  {
    country: "Tanzania",
    states: [
      "Arusha",
      "Dar es Salaam",
      "Dodoma",
      "Geita",
      "Iringa",
      "Kagera",
      "Katavi",
      "Kigoma",
      "Kilimanjaro",
      "Lindi",
      "Manyara",
      "Mara",
      "Mbeya",
      "Morogoro",
      "Mtwara",
      "Mwanza",
      "Njombe",
      "Pemba North",
      "Pemba South",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Shinyanga",
      "Simiyu",
      "Singida",
      "Songwe",
      "Tabora",
      "Tanga",
      "Zanzibar North",
      "Zanzibar South",
      "Zanzibar West",
    ],
  },
  {
    country: "Thailand",
    states: [
      "Amnat Charoen",
      "Ang Thong",
      "Bangkok",
      "Bueng Kan",
      "Buri Ram",
      "Chachoengsao",
      "Chai Nat",
      "Chaiyaphum",
      "Chanthaburi",
      "Chiang Mai",
      "Chiang Rai",
      "Chon Buri",
      "Chumphon",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Khon Kaen",
      "Krabi",
      "Lampang",
      "Lamphun",
      "Loei",
      "Lop Buri",
      "Mae Hong Son",
      "Maha Sarakham",
      "Mukdahan",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "Nong Bua Lam Phu",
      "Nong Khai",
      "Nonthaburi",
      "Pathum Thani",
      "Pattani",
      "Pattaya",
      "Phangnga",
      "Phatthalung",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Phra Nakhon Si Ayutthaya",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Ranong",
      "Ratchaburi",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhram",
      "Saraburi",
      "Satun",
      "Si Sa Ket",
      "Sing Buri",
      "Songkhla",
      "Sukhothai",
      "Suphan Buri",
      "Surat Thani",
      "Surin",
      "Tak",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Yala",
      "Yasothon",
    ],
  },
  {
    country: "The Bahamas",
    states: [
      "Acklins",
      "Acklins and Crooked Islands",
      "Berry Islands",
      "Bimini",
      "Black Point",
      "Cat Island",
      "Central Abaco",
      "Central Andros",
      "Central Eleuthera",
      "Crooked Island",
      "East Grand Bahama",
      "Exuma",
      "Freeport",
      "Fresh Creek",
      "Governor's Harbour",
      "Grand Cay",
      "Green Turtle Cay",
      "Harbour Island",
      "High Rock",
      "Hope Town",
      "Inagua",
      "Kemps Bay",
      "Long Island",
      "Mangrove Cay",
      "Marsh Harbour",
      "Mayaguana District",
      "New Providence",
      "Nichollstown and Berry Islands",
      "North Abaco",
      "North Andros",
      "North Eleuthera",
      "Ragged Island",
      "Rock Sound",
      "Rum Cay District",
      "San Salvador and Rum Cay",
      "San Salvador Island",
      "Sandy Point",
      "South Abaco",
      "South Andros",
      "South Eleuthera",
      "Spanish Wells",
      "West Grand Bahama",
    ],
  },
  {
    country: "Togo",
    states: ["Centrale Region", "Kara Region", "Maritime", "Plateaux Region", "Savanes Region"],
  },
  {
    country: "Tokelau",
    states: [],
  },
  {
    country: "Tonga",
    states: ["Haʻapai", "ʻEua", "Niuas", "Tongatapu", "Vavaʻu"],
  },
  {
    country: "Trinidad And Tobago",
    states: [
      "Arima",
      "Chaguanas",
      "Couva-Tabaquite-Talparo Regional Corporation",
      "Diego Martin Regional Corporation",
      "Eastern Tobago",
      "Penal-Debe Regional Corporation",
      "Point Fortin",
      "Port of Spain",
      "Princes Town Regional Corporation",
      "Rio Claro-Mayaro Regional Corporation",
      "San Fernando",
      "San Juan-Laventille Regional Corporation",
      "Sangre Grande Regional Corporation",
      "Siparia Regional Corporation",
      "Tunapuna-Piarco Regional Corporation",
      "Western Tobago",
    ],
  },
  {
    country: "Tunisia",
    states: [
      "Ariana Governorate",
      "Ben Arous Governorate",
      "Bizerte Governorate",
      "Gabès Governorate",
      "Gafsa Governorate",
      "Jendouba Governorate",
      "Kairouan Governorate",
      "Kasserine Governorate",
      "Kassrine",
      "Kebili Governorate",
      "Kef Governorate",
      "Mahdia Governorate",
      "Manouba Governorate",
      "Medenine Governorate",
      "Monastir Governorate",
      "Sfax Governorate",
      "Sidi Bouzid Governorate",
      "Siliana Governorate",
      "Sousse Governorate",
      "Tataouine Governorate",
      "Tozeur Governorate",
      "Tunis Governorate",
      "Zaghouan Governorate",
    ],
  },
  {
    country: "Turkey",
    states: [
      "Adana",
      "Adıyaman",
      "Afyonkarahisar",
      "Ağrı",
      "Aksaray",
      "Amasya",
      "Ankara",
      "Antalya",
      "Ardahan",
      "Artvin",
      "Aydın",
      "Balıkesir",
      "Bartın",
      "Batman",
      "Bayburt",
      "Bilecik",
      "Bingöl",
      "Bitlis",
      "Bolu",
      "Burdur",
      "Bursa",
      "Çanakkale",
      "Çankırı",
      "Çorum",
      "Denizli",
      "Diyarbakır",
      "Düzce",
      "Edirne",
      "Elazığ",
      "Erzincan",
      "Erzurum",
      "Eskişehir",
      "Gaziantep",
      "Giresun",
      "Gümüşhane",
      "Hakkâri",
      "Hatay",
      "Iğdır",
      "Isparta",
      "Istanbul",
      "İzmir",
      "Kahramanmaraş",
      "Karabük",
      "Karaman",
      "Kars",
      "Kastamonu",
      "Kayseri",
      "Kilis",
      "Kırıkkale",
      "Kırklareli",
      "Kırşehir",
      "Kocaeli",
      "Konya",
      "Kütahya",
      "Malatya",
      "Manisa",
      "Mardin",
      "Mersin",
      "Muğla",
      "Muş",
      "Nevşehir",
      "Niğde",
      "Ordu",
      "Osmaniye",
      "Rize",
      "Sakarya",
      "Samsun",
      "Şanlıurfa",
      "Siirt",
      "Sinop",
      "Sivas",
      "Şırnak",
      "Tekirdağ",
      "Tokat",
      "Trabzon",
      "Tunceli",
      "Uşak",
      "Van",
      "Yalova",
      "Yozgat",
      "Zonguldak",
    ],
  },
  {
    country: "Turkmenistan",
    states: ["Ahal Region", "Ashgabat", "Balkan Region", "Daşoguz Region", "Lebap Region", "Mary Region"],
  },
  {
    country: "Turks And Caicos Islands",
    states: [],
  },
  {
    country: "Tuvalu",
    states: ["Funafuti", "Nanumanga", "Nanumea", "Niutao Island Council", "Nui", "Nukufetau", "Nukulaelae", "Vaitupu"],
  },
  {
    country: "Uganda",
    states: [
      "Abim District",
      "Adjumani District",
      "Agago District",
      "Alebtong District",
      "Amolatar District",
      "Amudat District",
      "Amuria District",
      "Amuru District",
      "Apac District",
      "Arua District",
      "Budaka District",
      "Bududa District",
      "Bugiri District",
      "Buhweju District",
      "Buikwe District",
      "Bukedea District",
      "Bukomansimbi District",
      "Bukwo District",
      "Bulambuli District",
      "Buliisa District",
      "Bundibugyo District",
      "Bunyangabu District",
      "Bushenyi District",
      "Busia District",
      "Butaleja District",
      "Butambala District",
      "Butebo District",
      "Buvuma District",
      "Buyende District",
      "Central Region",
      "Dokolo District",
      "Eastern Region",
      "Gomba District",
      "Gulu District",
      "Ibanda District",
      "Iganga District",
      "Isingiro District",
      "Jinja District",
      "Kaabong District",
      "Kabale District",
      "Kabarole District",
      "Kaberamaido District",
      "Kagadi District",
      "Kakumiro District",
      "Kalangala District",
      "Kaliro District",
      "Kalungu District",
      "Kampala District",
      "Kamuli District",
      "Kamwenge District",
      "Kanungu District",
      "Kapchorwa District",
      "Kasese District",
      "Katakwi District",
      "Kayunga District",
      "Kibaale District",
      "Kiboga District",
      "Kibuku District",
      "Kiruhura District",
      "Kiryandongo District",
      "Kisoro District",
      "Kitgum District",
      "Koboko District",
      "Kole District",
      "Kotido District",
      "Kumi District",
      "Kween District",
      "Kyankwanzi District",
      "Kyegegwa District",
      "Kyenjojo District",
      "Kyotera District",
      "Lamwo District",
      "Lira District",
      "Luuka District",
      "Luwero District",
      "Lwengo District",
      "Lyantonde District",
      "Manafwa District",
      "Maracha District",
      "Masaka District",
      "Masindi District",
      "Mayuge District",
      "Mbale District",
      "Mbarara District",
      "Mitooma District",
      "Mityana District",
      "Moroto District",
      "Moyo District",
      "Mpigi District",
      "Mubende District",
      "Mukono District",
      "Nakapiripirit District",
      "Nakaseke District",
      "Nakasongola District",
      "Namayingo District",
      "Namisindwa District",
      "Namutumba District",
      "Napak District",
      "Nebbi District",
      "Ngora District",
      "Northern Region",
      "Ntoroko District",
      "Ntungamo District",
      "Nwoya District",
      "Omoro District",
      "Otuke District",
      "Oyam District",
      "Pader District",
      "Pakwach District",
      "Pallisa District",
      "Rakai District",
      "Rubanda District",
      "Rubirizi District",
      "Rukiga District",
      "Rukungiri District",
      "Sembabule District",
      "Serere District",
      "Sheema District",
      "Sironko District",
      "Soroti District",
      "Tororo District",
      "Wakiso District",
      "Western Region",
      "Yumbe District",
      "Zombo District",
    ],
  },
  {
    country: "Ukraine",
    states: [
      "Autonomous Republic of Crimea",
      "Cherkaska oblast",
      "Chernihivska oblast",
      "Chernivetska oblast",
      "Dnipropetrovska oblast",
      "Donetska oblast",
      "Ivano-Frankivska oblast",
      "Kharkivska oblast",
      "Khersonska oblast",
      "Khmelnytska oblast",
      "Kirovohradska oblast",
      "Kyiv",
      "Kyivska oblast",
      "Luhanska oblast",
      "Lvivska oblast",
      "Mykolaivska oblast",
      "Odeska oblast",
      "Poltavska oblast",
      "Rivnenska oblast",
      "Sumska oblast",
      "Ternopilska oblast",
      "Vinnytska oblast",
      "Volynska oblast",
      "Zakarpatska Oblast",
      "Zaporizka oblast",
      "Zhytomyrska oblast",
    ],
  },
  {
    country: "United Arab Emirates",
    states: ["Abu Dhabi Emirate", "Ajman Emirate", "Dubai", "Fujairah", "Ras al-Khaimah", "Sharjah Emirate", "Umm al-Quwain"],
  },
  {
    country: "United Kingdom",
    states: [
      "Aberdeen",
      "Aberdeenshire",
      "Angus",
      "Antrim",
      "Antrim and Newtownabbey",
      "Ards",
      "Ards and North Down",
      "Argyll and Bute",
      "Armagh City and District Council",
      "Armagh, Banbridge and Craigavon",
      "Ascension Island",
      "Ballymena Borough",
      "Ballymoney",
      "Banbridge",
      "Barnsley",
      "Bath and North East Somerset",
      "Bedford",
      "Belfast district",
      "Birmingham",
      "Blackburn with Darwen",
      "Blackpool",
      "Blaenau Gwent County Borough",
      "Bolton",
      "Bournemouth",
      "Bracknell Forest",
      "Bradford",
      "Bridgend County Borough",
      "Brighton and Hove",
      "Buckinghamshire",
      "Bury",
      "Caerphilly County Borough",
      "Calderdale",
      "Cambridgeshire",
      "Carmarthenshire",
      "Carrickfergus Borough Council",
      "Castlereagh",
      "Causeway Coast and Glens",
      "Central Bedfordshire",
      "Ceredigion",
      "Cheshire East",
      "Cheshire West and Chester",
      "City and County of Cardiff",
      "City and County of Swansea",
      "City of Bristol",
      "City of Derby",
      "City of Kingston upon Hull",
      "City of Leicester",
      "City of London",
      "City of Nottingham",
      "City of Peterborough",
      "City of Plymouth",
      "City of Portsmouth",
      "City of Southampton",
      "City of Stoke-on-Trent",
      "City of Sunderland",
      "City of Westminster",
      "City of Wolverhampton",
      "City of York",
      "Clackmannanshire",
      "Coleraine Borough Council",
      "Conwy County Borough",
      "Cookstown District Council",
      "Cornwall",
      "County Durham",
      "Coventry",
      "Craigavon Borough Council",
      "Cumbria",
      "Darlington",
      "Denbighshire",
      "Derbyshire",
      "Derry City and Strabane",
      "Derry City Council",
      "Devon",
      "Doncaster",
      "Dorset",
      "Down District Council",
      "Dudley",
      "Dumfries and Galloway",
      "Dundee",
      "Dungannon and South Tyrone Borough Council",
      "East Ayrshire",
      "East Dunbartonshire",
      "East Lothian",
      "East Renfrewshire",
      "East Riding of Yorkshire",
      "East Sussex",
      "Edinburgh",
      "England",
      "Essex",
      "Falkirk",
      "Fermanagh and Omagh",
      "Fermanagh District Council",
      "Fife",
      "Flintshire",
      "Gateshead",
      "Glasgow",
      "Gloucestershire",
      "Gwynedd",
      "Halton",
      "Hampshire",
      "Hartlepool",
      "Herefordshire",
      "Hertfordshire",
      "Highland",
      "Inverclyde",
      "Isle of Wight",
      "Isles of Scilly",
      "Kent",
      "Kirklees",
      "Knowsley",
      "Lancashire",
      "Larne Borough Council",
      "Leeds",
      "Leicestershire",
      "Limavady Borough Council",
      "Lincolnshire",
      "Lisburn and Castlereagh",
      "Lisburn City Council",
      "Liverpool",
      "London Borough of Barking and Dagenham",
      "London Borough of Barnet",
      "London Borough of Bexley",
      "London Borough of Brent",
      "London Borough of Bromley",
      "London Borough of Camden",
      "London Borough of Croydon",
      "London Borough of Ealing",
      "London Borough of Enfield",
      "London Borough of Hackney",
      "London Borough of Hammersmith and Fulham",
      "London Borough of Haringey",
      "London Borough of Harrow",
      "London Borough of Havering",
      "London Borough of Hillingdon",
      "London Borough of Hounslow",
      "London Borough of Islington",
      "London Borough of Lambeth",
      "London Borough of Lewisham",
      "London Borough of Merton",
      "London Borough of Newham",
      "London Borough of Redbridge",
      "London Borough of Richmond upon Thames",
      "London Borough of Southwark",
      "London Borough of Sutton",
      "London Borough of Tower Hamlets",
      "London Borough of Waltham Forest",
      "London Borough of Wandsworth",
      "Magherafelt District Council",
      "Manchester",
      "Medway",
      "Merthyr Tydfil County Borough",
      "Metropolitan Borough of Wigan",
      "Mid and East Antrim",
      "Mid Ulster",
      "Middlesbrough",
      "Midlothian",
      "Milton Keynes",
      "Monmouthshire",
      "Moray",
      "Moyle District Council",
      "Neath Port Talbot County Borough",
      "Newcastle upon Tyne",
      "Newport",
      "Newry and Mourne District Council",
      "Newry, Mourne and Down",
      "Newtownabbey Borough Council",
      "Norfolk",
      "North Ayrshire",
      "North Down Borough Council",
      "North East Lincolnshire",
      "North Lanarkshire",
      "North Lincolnshire",
      "North Somerset",
      "North Tyneside",
      "North Yorkshire",
      "Northamptonshire",
      "Northern Ireland",
      "Northumberland",
      "Nottinghamshire",
      "Oldham",
      "Omagh District Council",
      "Orkney Islands",
      "Outer Hebrides",
      "Oxfordshire",
      "Pembrokeshire",
      "Perth and Kinross",
      "Poole",
      "Powys",
      "Reading",
      "Redcar and Cleveland",
      "Renfrewshire",
      "Rhondda Cynon Taf",
      "Rochdale",
      "Rotherham",
      "Royal Borough of Greenwich",
      "Royal Borough of Kensington and Chelsea",
      "Royal Borough of Kingston upon Thames",
      "Rutland",
      "Saint Helena",
      "Salford",
      "Sandwell",
      "Scotland",
      "Scottish Borders",
      "Sefton",
      "Sheffield",
      "Shetland Islands",
      "Shropshire",
      "Slough",
      "Solihull",
      "Somerset",
      "South Ayrshire",
      "South Gloucestershire",
      "South Lanarkshire",
      "South Tyneside",
      "Southend-on-Sea",
      "St Helens",
      "Staffordshire",
      "Stirling",
      "Stockport",
      "Stockton-on-Tees",
      "Strabane District Council",
      "Suffolk",
      "Surrey",
      "Swindon",
      "Tameside",
      "Telford and Wrekin",
      "Thurrock",
      "Torbay",
      "Torfaen",
      "Trafford",
      "United Kingdom",
      "Vale of Glamorgan",
      "Wakefield",
      "Wales",
      "Walsall",
      "Warrington",
      "Warwickshire",
      "West Berkshire",
      "West Dunbartonshire",
      "West Lothian",
      "West Sussex",
      "Wiltshire",
      "Windsor and Maidenhead",
      "Wirral",
      "Wokingham",
      "Worcestershire",
      "Wrexham County Borough",
    ],
  },
  {
    country: "United States",
    states: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "Baker Island",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Howland Island",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Jarvis Island",
      "Johnston Atoll",
      "Kansas",
      "Kentucky",
      "Kingman Reef",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Midway Atoll",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Navassa Island",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palmyra Atoll",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "United States Minor Outlying Islands",
      "United States Virgin Islands",
      "Utah",
      "Vermont",
      "Virginia",
      "Wake Island",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
  {
    country: "United States Minor Outlying Islands",
    states: [],
  },
  {
    country: "Uruguay",
    states: [
      "Artigas Department",
      "Canelones Department",
      "Cerro Largo Department",
      "Colonia Department",
      "Durazno Department",
      "Flores Department",
      "Florida Department",
      "Lavalleja Department",
      "Maldonado Department",
      "Montevideo Department",
      "Paysandú Department",
      "Río Negro Department",
      "Rivera Department",
      "Rocha Department",
      "Salto Department",
      "San José Department",
      "Soriano Department",
      "Tacuarembó Department",
      "Treinta y Tres Department",
    ],
  },
  {
    country: "Uzbekistan",
    states: [
      "Andijan Region",
      "Bukhara Region",
      "Fergana Region",
      "Jizzakh Region",
      "Karakalpakstan",
      "Namangan Region",
      "Navoiy Region",
      "Qashqadaryo Region",
      "Samarqand Region",
      "Sirdaryo Region",
      "Surxondaryo Region",
      "Tashkent",
      "Tashkent Region",
      "Xorazm Region",
    ],
  },
  {
    country: "Vanuatu",
    states: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],
  },
  {
    country: "Vatican City State (Holy See)",
    states: [],
  },
  {
    country: "Venezuela",
    states: [
      "Amazonas",
      "Anzoátegui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolívar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Distrito Capital",
      "Falcón",
      "Federal Dependencies of Venezuela",
      "Guárico",
      "La Guaira",
      "Lara",
      "Mérida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Táchira",
      "Trujillo",
      "Yaracuy",
      "Zulia",
    ],
  },
  {
    country: "Vietnam",
    states: [
      "An Giang",
      "Bà Rịa-Vũng Tàu",
      "Bắc Giang",
      "Bắc Kạn",
      "Bạc Liêu",
      "Bắc Ninh",
      "Bến Tre",
      "Bình Dương",
      "Bình Định",
      "Bình Phước",
      "Bình Thuận",
      "Cà Mau",
      "Cần Thơ",
      "Cao Bằng",
      "Đà Nẵng",
      "Đắk Lắk",
      "Đắk Nông",
      "Điện Biên",
      "Đồng Nai",
      "Đồng Tháp",
      "Gia Lai",
      "Hà Giang",
      "Hà Nam",
      "Hà Nội",
      "Hà Tĩnh",
      "Hải Dương",
      "Hải Phòng",
      "Hậu Giang",
      "Hồ Chí Minh",
      "Hòa Bình",
      "Hưng Yên",
      "Khánh Hòa",
      "Kiên Giang",
      "Kon Tum",
      "Lai Châu",
      "Lâm Đồng",
      "Lạng Sơn",
      "Lào Cai",
      "Long An",
      "Nam Định",
      "Nghệ An",
      "Ninh Bình",
      "Ninh Thuận",
      "Phú Thọ",
      "Phú Yên",
      "Quảng Bình",
      "Quảng Nam",
      "Quảng Ngãi",
      "Quảng Ninh",
      "Quảng Trị",
      "Sóc Trăng",
      "Sơn La",
      "Tây Ninh",
      "Thái Bình",
      "Thái Nguyên",
      "Thanh Hóa",
      "Thừa Thiên-Huế",
      "Tiền Giang",
      "Trà Vinh",
      "Tuyên Quang",
      "Vĩnh Long",
      "Vĩnh Phúc",
      "Yên Bái",
    ],
  },
  {
    country: "Virgin Islands (British)",
    states: [],
  },
  {
    country: "Virgin Islands (US)",
    states: ["Saint Croix", "Saint John", "Saint Thomas"],
  },
  {
    country: "Wallis And Futuna Islands",
    states: [],
  },
  {
    country: "Western Sahara",
    states: [],
  },
  {
    country: "Yemen",
    states: [
      "'Adan Governorate",
      "'Amran Governorate",
      "Abyan Governorate",
      "Al Bayda' Governorate",
      "Al Hudaydah Governorate",
      "Al Jawf Governorate",
      "Al Mahrah Governorate",
      "Al Mahwit Governorate",
      "Dhamar Governorate",
      "Hadhramaut Governorate",
      "Hajjah Governorate",
      "Ibb Governorate",
      "Lahij Governorate",
      "Ma'rib Governorate",
      "Raymah Governorate",
      "Saada Governorate",
      "Sana'a",
      "Sana'a Governorate",
      "Shabwah Governorate",
      "Socotra Governorate",
      "Ta'izz Governorate",
    ],
  },
  {
    country: "Zambia",
    states: [
      "Central Province",
      "Copperbelt Province",
      "Eastern Province",
      "Luapula Province",
      "Lusaka Province",
      "Muchinga Province",
      "Northern Province",
      "Northwestern Province",
      "Southern Province",
      "Western Province",
    ],
  },
  {
    country: "Zimbabwe",
    states: [
      "Bulawayo Province",
      "Harare Province",
      "Manicaland",
      "Mashonaland Central Province",
      "Mashonaland East Province",
      "Mashonaland West Province",
      "Masvingo Province",
      "Matabeleland North Province",
      "Matabeleland South Province",
      "Midlands Province",
    ],
  },
];
export default countryList;
