import { type GraphQLResult } from "@aws-amplify/api-graphql";
import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ListFlunaMembersQuery, MemberModulesInput, UserByEmailQuery } from "API";
import { API } from "aws-amplify";
import { customListFlunaMembers, customUserByEmail } from "graphql/custom-queries";
import { ErrorHandler } from "helper/Handlers";
import type { IPermissionsData, IUsersData } from "interfaces/permissions";

interface PermissionState {
  users: IPermissionsData[];
  loggedInUser: IUsersData;
  loggedInUserModules: MemberModulesInput[];
  loading: boolean;
  userPermissionsLoaded: boolean;
}

const initialState: PermissionState = {
  users: [],
  loggedInUser: {},
  loggedInUserModules: [],
  loading: false,
  userPermissionsLoaded: false,
};

export const fetchLoggedInUserModules = createAsyncThunk("permissions/fetchLoggedInUserModules", async (email: string) => {
  try {
    const res = (await API.graphql({
      query: customUserByEmail,
      variables: { email },
    })) as GraphQLResult<UserByEmailQuery>;

    return res.data?.userByEmail?.items[0] as IUsersData;
  } catch (error) {
    console.log("ModulesFetchErr", error);
    // ErrorHandler({ message: "Unable to fetch user modules" });
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as IUsersData;
  }
});

export const fetchUsers = createAsyncThunk("permissions/fetchUsers", async () => {
  try {
    const listFlunaMembersResponse = (await API.graphql({
      query: customListFlunaMembers,
    })) as GraphQLResult<ListFlunaMembersQuery>;

    return listFlunaMembersResponse.data?.listFlunaMembers?.items as IPermissionsData[];
  } catch (err) {
    console.log("UserFetchErr", err);
    ErrorHandler({ message: "Unable to fetch users data" });
    return [] as IPermissionsData[];
  }
});

const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setUsers: (
      state,
      action: PayloadAction<{
        data: IPermissionsData[];
      }>,
    ) => {
      state.users = action.payload.data;
    },
    setModules: (
      state,
      action: PayloadAction<{
        data: MemberModulesInput[];
      }>,
    ) => {
      state.loggedInUserModules = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.users = action.payload as IPermissionsData[];
      state.loading = false;
    });
    builder.addCase(fetchLoggedInUserModules.pending, (state) => {
      state.userPermissionsLoaded = false;
    });
    builder.addCase(fetchLoggedInUserModules.fulfilled, (state, action) => {
      state.loggedInUserModules = action?.payload?.modules as MemberModulesInput[];
      state.loggedInUser = action?.payload;
      state.userPermissionsLoaded = true;
    });
    builder.addCase(fetchLoggedInUserModules.rejected, (state, action) => {
      state.loggedInUserModules = (action?.payload as IUsersData).modules as MemberModulesInput[];
      state.loggedInUser = action?.payload as IUsersData;
      state.userPermissionsLoaded = true;
    });
  },
});

export const { setUsers, setModules } = permissionSlice.actions;

export default permissionSlice.reducer;
