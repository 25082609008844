/* eslint-disable @typescript-eslint/promise-function-async */
import NewCompany from "pages/atlas/new";
import React, { lazy } from "react";

const AtlasHome = lazy(() => import("pages/atlas"));
const CampaignBuilderLog = lazy(() => import("pages/trade/campaignBuilder/log"));
const CreateCampaign = lazy(() => import("pages/trade/campaignBuilder/createCampaign"));
const AtlasCompanyInstance = lazy(() => import("pages/atlas/company/instance"));

export const AtlasChildren = [
  {
    path: "atlas",
    element: <AtlasHome />,
  },
  {
    path: "campaign-builder/log",
    element: <CampaignBuilderLog />,
  },
  {
    path: "campaign-builder/new-campaign",
    element: <CreateCampaign />,
  },
  {
    path: "atlas/company/:companyId",
    element: <AtlasCompanyInstance />,
  },
  {
    path: "atlas/company/new",
    element: <NewCompany />,
  },
];
