import React from "react";
import { useMatch, useResolvedPath } from "react-router-dom";
import CustomLink from "components/CustomLink";
import { Box, Tooltip } from "@mui/material";
import { type NavigationItem, type Children } from "interfaces/Route";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { type FlunaModules } from "API";

export interface Navs {
  name: string;
  navigation: string;
  subNavigation?: string[];
  module?: FlunaModules;
  element?: any;
  children?: Children[];
  secondarySidebar?: boolean;
}

interface MenuLinkProps {
  module: Navs;
  allowComing?: boolean;
  allowNotif?: boolean;
  opened: boolean;
  setShowing: React.Dispatch<React.SetStateAction<string>>;
  noAccessModules: NavigationItem[];
}

function MenuLink({ noAccessModules, module, opened, setShowing }: MenuLinkProps) {
  const updatedPath = module.navigation + "/*";

  const resolved = useResolvedPath(updatedPath);
  const resolvedSubPaths = module?.subNavigation?.map((subNav) => {
    return useResolvedPath(`${subNav}/*`);
  });
  const subPathMatch = resolvedSubPaths?.map((path) => useMatch({ path: path.pathname, end: true }))?.filter((path) => path !== null);

  const match = useMatch({ path: resolved.pathname, end: true }) || subPathMatch?.length;

  const isNoAccess = noAccessModules.some((noAccessModule) => noAccessModule.name === module.name);

  return (
    <>
      <Box
        key={module.name}
        sx={{
          color: "#171721",
          cursor: "pointer",
          padding: "8px 18px",
          margin: "4px 0px",
          fontSize: "1rem",
          fontWeight: "400",
          display: "flex",
          alignitems: "center",
          justifyContent: opened ? "space-between" : "center",
          borderLeft: match ? "4px solid #F26D49" : "4px solid #212437",
        }}
      >
        <CustomLink
          to={isNoAccess ? "/request-access" : module.navigation}
          subPaths={module.subNavigation}
          onClick={() => {
            if (!isNoAccess) {
              setShowing(module.name);
            }
          }}
        >
          <Box alignItems="center" mr="1rem" fontSize={opened ? "1.2em" : "1.4em"} display={"inline-block"}>
            {opened ? (
              module.element
            ) : (
              <Tooltip title={`${module.name}`} placement="right-start">
                {module.element}
              </Tooltip>
            )}
          </Box>
          {opened ? module.name : ""}
          {/* {allowComing && opened && module.coming ? (
            <Box
              sx={{
                backgroundColor: "#3E7EFF",
                color: "#fff",
                borderRadius: "4px",
                margin: "1px 2px",
                padding: "1px 4px",
                fontSize: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Coming soon
            </Box>
          ) : (
            ""
          )} */}
        </CustomLink>

        {/* {allowNotif && opened && module.notif ? (
          <Box
            sx={{
              backgroundColor: "#3E7EFF",
              color: "#fff",
              borderRadius: "50%",
              padding: "1px 6px",
              fontSize: "0.6rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {module.notif}
          </Box>
        ) : (
          ""
        )} */}
        {isNoAccess && (
          <Box display="flex" alignItems="center">
            <LockPersonIcon sx={{ color: "#fff" }} fontSize="small" />
          </Box>
        )}
      </Box>
    </>
  );
}

export default MenuLink;
