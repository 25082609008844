import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TradeTrackerState {
  orders: any[];
  selectedOrder: any | null;
  selectedShipment: any | null;
}

const initialState: TradeTrackerState = {
  orders: [],
  selectedOrder: null,
  selectedShipment: null,
};

const TradeTrackerSlice = createSlice({
  name: "tradeTracker",
  initialState,
  reducers: {
    setAllOrders: (state, action) => {
      state.orders = action.payload.data;
    },
    setSelectedOrder: (
      state,
      action: PayloadAction<{
        data: any;
      }>,
    ) => {
      state.selectedOrder = action.payload.data;
    },
    setSelectedShipent: (
      state,
      action: PayloadAction<{
        data: any;
      }>,
    ) => {
      state.selectedShipment = action.payload.data;
    },
  },
});

export const { setAllOrders, setSelectedOrder, setSelectedShipent } = TradeTrackerSlice.actions;

export default TradeTrackerSlice.reducer;
